import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 breadcrumb-1">
              <h1 className="title">Contract Logistics </h1>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="bread-tag">
                <Link to="/Home">Home</Link>
                <span> / </span>
                <Link to="#" className="active">
                  Contract Logistics
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rts-blog-list-area rts-section-gap">
        <div className="container">
          <div className="row g-5">
            {/* rts blo post area */}
            <div className="col-xl-8 col-md-12 col-sm-12 col-12">
              {/* single post */}
              <div className="blog-single-post-listing details mb--0">
                <div className="thumbnail">
                  <img
                    src="assets/images/Contract Logistics.jpg"
                    alt="Business-Blog"
                  />
                </div>
                <div className="blog-listing-content">
                  <h3 className="title">Contract Logistics</h3>
                  <p>
                    As a highly regarded authority in 4PL and 3PL logistics,
                    {companyname} specializes in delivering
                    comprehensive contract logistics services. Our proficiency
                    extends to end-to-end supply chain solutions, made possible
                    by our expansive network and versatile capabilities. Through
                    a blend of technology and innovation, we present optimized
                    and dependable solutions meticulously tailored to meet your
                    distinct requisites. Irrespective of the intricacy and
                    geographical expanse of your operations, our dedication lies
                    in conceptualizing, executing, and vigilantly overseeing
                    your supply chain strategy. This ensures your capacity to
                    fulfill commitments to your clientele and address your needs
                    with efficacy.
                  </p>

                  <p>
                    Our service portfolio envelops a broad spectrum encompassing
                    supply chain design, engineering, and sourcing. Our strength
                    lies in orchestrating harmonized 4PL and 3PL services, a
                    process involving the seamless integration and
                    administration of multiple 3PL providers. This agility
                    empowers us to mold our approach to your unique business
                    prerequisites, operating under flexible commercial and
                    operational models.
                  </p>

                  <p>
                    <strong>At {companyname}</strong>, we
                    grasp the pivotal significance of a well-functioning supply
                    chain in meeting customer expectations and propelling
                    business triumph. Fortified by our expertise and
                    capabilities, we are resolute in furnishing you with
                    personalized and efficient contract logistics solutions,
                    enriching your operational efficiency, optimizing expenses,
                    and assuring the punctual conveyance of your products and
                    services.
                  </p>

                  <p>
                    Through an alliance with {companyname}
                    for your contract logistics needs, you are poised to harness
                    our profound industry insights, extensive network, and
                    dedication to delivering extraordinary service. We
                    collaborate closely with you to fathom your business
                    objectives, challenges, and distinct supply chain
                    prerequisites. Grounded in this comprehension, we craft and
                    enact strategies engineered to streamline your operations,
                    enhance inventory management, amplify visibility, and
                    cultivate value across your supply chain.
                  </p>

                  <p>
                    With {companyname} as your trusted
                    contract logistics partner, you can channel your energies
                    towards your core business as we navigate the intricate
                    logistics landscape. Our aspiration is to bolster your
                    expansion, elevate your competitive edge, and offer a
                    seamless and efficient supply chain that contributes to your
                    comprehensive triumph.
                  </p>
                </div>
              </div>
              {/* single post End*/}
            </div>
            {/* rts-blog post end area */}
            {/*rts blog wizered area */}
            <div className="col-xl-4 col-md-12 col-sm-12 col-12">
              <div className="rts-single-wized Recent-post">
                <div className="wized-header">
                  <h5 className="title">Other Services</h5>
                </div>
                <div className="wized-body">
                  {/* recent-post */}
                  <div className="recent-post-single">
                    <div className="thumbnail">
                      <Link to="/Road">
                        <img
                          src="assets/images/Road Freight.jpg"
                          alt="Blog_post"
                          style={{ width: "90px" }}
                        />
                      </Link>
                    </div>
                    <div className="content-area">
                      <Link className="post-title" to="/Road">
                        <h6 className="">Road Freight</h6>
                      </Link>
                    </div>
                  </div>
                  <div className="recent-post-single">
                    <div className="thumbnail">
                      <Link to="/Ocean">
                        <img
                          src="assets/images/Ocean Freight.jpg"
                          alt="Blog_post"
                          style={{ width: "90px" }}
                        />
                      </Link>
                    </div>
                    <div className="content-area">
                      <Link className="post-title" to="/Ocean">
                        <h6 className="">Ocean Freight</h6>
                      </Link>
                    </div>
                  </div>
                  <div className="recent-post-single">
                    <div className="thumbnail">
                      <Link to="/Air">
                        <img
                          src="assets/images/Air Freight.jpg"
                          alt="Blog_post"
                          style={{ width: "90px" }}
                        />
                      </Link>
                    </div>
                    <div className="content-area">
                      <Link className="post-title" to="/Air">
                        <h6 className="">Air Freight</h6>
                      </Link>
                    </div>
                  </div>
                  <div className="recent-post-single">
                    <div className="thumbnail">
                      <Link to="/Customs">
                        <img
                          src="assets/images/Customs Compliance.jpg"
                          alt="Blog_post"
                          style={{ width: "90px" }}
                        />
                      </Link>
                    </div>
                    <div className="content-area">
                      <Link className="post-title" to="/Customs">
                        <h6 className="">Customs Compliance</h6>
                      </Link>
                    </div>
                  </div>
                  <div className="recent-post-single">
                    <div className="thumbnail">
                      <Link to="/Contract">
                        <img
                          src="assets/images/Contract Logistics.jpg"
                          alt="Blog_post"
                          style={{ width: "90px" }}
                        />
                      </Link>
                    </div>
                    <div className="content-area">
                      <Link className="post-title" to="/Contract">
                        <h6 className="">Contract Logistics</h6>
                      </Link>
                    </div>
                  </div>
                  <div className="recent-post-single">
                    <div className="thumbnail">
                      <Link to="/Material">
                        <img
                          src="assets/images/Material Handling.jpg"
                          alt="Blog_post"
                          style={{ width: "90px" }}
                        />
                      </Link>
                    </div>
                    <div className="content-area">
                      <Link className="post-title" to="/Material">
                        <h6 className="">Material Handling</h6>
                      </Link>
                    </div>
                  </div>
                  <div className="recent-post-single">
                    <div className="thumbnail">
                      <Link to="/Multimodal">
                        <img
                          src="assets/images/Multimodal Logistics.jpg"
                          alt="Blog_post"
                          style={{ width: "90px" }}
                        />
                      </Link>
                    </div>
                    <div className="content-area">
                      <Link className="post-title" to="/Multimodal">
                        <h6 className="">Multimodal Logistics</h6>
                      </Link>
                    </div>
                  </div>
                  <div className="recent-post-single">
                    <div className="thumbnail">
                      <Link to="/Project">
                        <img
                          src="assets/images/Project Logistics.jpg"
                          alt="Blog_post"
                          style={{ width: "90px" }}
                        />
                      </Link>
                    </div>
                    <div className="content-area">
                      <Link className="post-title" to="/Project">
                        <h6 className="">Project Logistics</h6>
                      </Link>
                    </div>
                  </div>
                  <div className="recent-post-single">
                    <div className="thumbnail">
                      <Link to="/Warehousing">
                        <img
                          src="assets/images/Warehousing & Distribution.jpg"
                          alt="Blog_post"
                          style={{width:"90px"}}
                        />
                      </Link>
                    </div>
                    <div className="content-area">
                    
                      <Link className="post-title" to="/Warehousing">
                        <h6 className="">
                        Warehousing & Distribution
                        </h6>
                      </Link>
                    </div>
                  </div>

                  {/* recent-post End */}
                </div>
              </div>
              <div className="rts-single-wized Categories">
                <div className="wized-header">
                  <h5 className="title">Industries </h5>
                </div>
                <div className="wized-body">
                  {/* single categoris */}
                  <ul className="single-categories">
                    <li>
                      <Link to="/Chemical ">
                        Chemical
                        <i className="far fa-long-arrow-right" />
                      </Link>
                    </li>
                  </ul>

                  <ul className="single-categories">
                    <li>
                      <Link to="/Consumer">
                        Consumer Electronics
                        <i className="far fa-long-arrow-right" />
                      </Link>
                    </li>
                  </ul>
                  <ul className="single-categories">
                    <li>
                      <Link to="/Fashion">
                        Fashion
                        <i className="far fa-long-arrow-right" />
                      </Link>
                    </li>
                  </ul>
                  <ul className="single-categories">
                    <li>
                      <Link to="/Food">
                        Food
                        <i className="far fa-long-arrow-right" />
                      </Link>
                    </li>
                  </ul>
                  <ul className="single-categories">
                    <li>
                      <Link to="/Furniture">
                        Furniture
                        <i className="far fa-long-arrow-right" />
                      </Link>
                    </li>
                  </ul>
                  <ul className="single-categories">
                    <li>
                      <Link to="/Mining">
                        Mining
                        <i className="far fa-long-arrow-right" />
                      </Link>
                    </li>
                  </ul>
                  <ul className="single-categories">
                    <li>
                      <Link to="/Energy">
                        Renewable Energy
                        <i className="far fa-long-arrow-right" />
                      </Link>
                    </li>
                  </ul>
                  <ul className="single-categories">
                    <li>
                      <Link to="/Retail">
                        Retail
                        <i className="far fa-long-arrow-right" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* single wizered End */}
              {/* single wizered start */}
            </div>
            {/* rts- blog wizered end area */}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
