import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Energy
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Energy
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-12">
            <div className="mb-5">
              <div className="text-center" style={{ padding: "10px 80px" }}>
                <h1 className="mb-4">Energy</h1>
                <img
                  className="img-fluid w-100 mb-5 "
                  src="img/Energy.jpg"
                  alt=""
                />
              </div>

              <p>
                If you're in search of a logistics partner that embodies both
                reliability and innovation, our organization stands out as the
                ultimate choice. Commodities such as solar panels, wind
                turbines, and biofuels, which are susceptible to temperature and
                climatic variables, require a logistics provider that is not
                only cutting-edge but also highly proficient. For the
                transportation of such delicate yet crucial products,{" "}
                {companyname} emerges as the perfect solution – India's
                fastest-growing tech-driven logistics service.
              </p>
              <p>
                Our customized energy logistics solutions go beyond
                transportation; they encompass a holistic approach aimed at
                enhancing your supply chain efficiency. From meticulous product
                testing and spare part storage to efficient warehousing and
                packaging, we provide comprehensive solutions to elevate your
                operations.
              </p>
              <p>
                In the thriving renewable energy market, where precision and
                cost-effectiveness are paramount, manufacturers of solar panels
                and similar resources strive to optimize logistics expenses
                without compromising delivery accuracy. Among the myriad
                challenges faced, customs clearance at port terminals is a
                notable hurdle that often impacts shipment success. Our adept
                team is well-equipped to navigate these challenges, ensuring the
                smooth transit of your valuable consignments.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
