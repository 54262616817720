import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Retail
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Retail
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-12">
            <div className="mb-5">
              <div className="text-center" style={{ padding: "10px 80px" }}>
                <h1 className="mb-4">Retail</h1>
                <img
                  className="img-fluid w-100 mb-5 "
                  src="img/Retail.jpg"
                  alt=""
                />
              </div>

              <p>
                In the realm of fashion and lifestyle logistics, {companyname} emerges as the beacon of versatility, offering
                an expansive array of options. Our commitment is clear: to
                provide comprehensive supply chain management and logistics
                solutions that cater to established enterprises and emerging
                ventures alike. For well-known brands and those on the rise, our
                promise remains unwavering – efficient, technologically-driven
                solutions that translate into enhanced profitability.
              </p>
              <p>
                Retail firms, the beating heart of the fashion industry, find in
                {companyname} a steadfast partner. Our arsenal
                includes specialized, cutting-edge, and tech-centric solutions
                that resonate with the dynamic needs of the sector. As we
                navigate the landscape of apparel shipping, we tailor our
                services to each assignment, ensuring that the best delivery
                options align with the unique requirements of our business
                partners.
              </p>
              <p>
                Furthermore, we extend our partnership to assist clients in
                streamlining costs. By optimizing garment transportation
                expenses and meticulously selecting shipping strategies, lines,
                and modes, we contribute to the refinement of cost-cutting
                strategies. At {companyname}, we're not just moving
                cargo; we're enhancing the fashion and lifestyle industry by
                providing solutions that bolster growth, efficiency, and
                strategic advancement.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
