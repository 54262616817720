import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import jsPDF from "jspdf";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import axios from "axios";
import "jspdf-autotable";
import { useLocation } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const contid = searchParams.get("contid");
  const [items, setItems] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const generatePDF = (id, contid) => {
    const doc = new jsPDF();

    // Set font and other text styling as needed

    // Calculate the text width and page width
    const pageTitle = "walkerslogistics.com.sg/Track";
    const textWidth = doc.getTextWidth(pageTitle);
    const pageWidth = doc.internal.pageSize.getWidth();

    // Calculate x position to right-align the text
    const xPos = pageWidth - textWidth;

    doc.setFont("Arial", "", 8);
    // Set logo size to 40px
    const logoWidth = 45; // Adjust the width as needed
    const logoHeight = 20; // Maintain aspect ratio

    doc.text("WALKERS LOGISTICS PTE LTD SHIPMENT DETAILS", 105, 45, {
      align: "center",
    });
    doc.setFontSize(14);
    const dynamicText = `Container No. ${contid} & BL No. is ${id}`;
    doc.text(dynamicText, 105, 55, {
      align: "center",
    });
    // Use the adjusted logo size
    doc.addImage("img/logo.png", "PNG", 20, 7, logoWidth, logoHeight);

    doc.setFont("Arial", "B", 18);

    // Position the title text at the top-right corner
    doc.setFontSize(10); // Set font size to 10px
    doc.text(pageTitle, 137, 20);

    doc.setFont("Arial", "B", 9);

    // ... (header and image code)
    // Define table headers and data for the first table
    const headers1 = [
      "TYPE",
      "PORT OF DESTINATION",
      "ESTIMATED ARRIVAL DATE",
      "SHIPPED DESTINATION",
      "ACTUAL ARRIVAL DATE",
    ];
    const filteredItems1 = items.filter(
      (item) => item.equipmentType && item.portOfDischarge && item.exTimeArrival
    );
    const data1 = filteredItems1.map((item) => [
      item.equipmentType,
      item.portOfDischarge,
      item.exTimeArrival,
      item.portOfDischarge,
      item.exTimeArrival,
    ]);

    // Define table headers and data for the second table
    const headers2 = ["Description", "Location", "Date", "Vessel", "Voyage"];
    const filteredItems2 = items.filter(
      (item) =>
        item.containerStatus &&
        item.location &&
        item.containerDate &&
        item.Vessel &&
        item.Voyage
    );

    const data2 = filteredItems2.map((item) => [
      item.containerStatus,
      item.location,
      item.containerDate,
      item.Vessel,
      item.Voyage,
    ]);

    const tableOptions = {
      startY: 60,
      headStyles: {
        fillColor: [15, 23, 135], // Header background color
        textColor: [255, 255, 255], // Header font color
      },
      styles: { textColor: [0, 0, 0], fontSize: 10 }, // Text color and font size
      margin: { top: 10 },
      didDrawPage: function (data) {
        // Customize the colors of the first and second rows
        const rows = data.table.body;
        if (rows.length > 1) {
          // Style the first row
          rows[0].forEach((cell) => {
            cell.styles.fillColor = [204, 252, 222]; // Red color
          });
          // Style the second row
          rows[1].forEach((cell) => {
            cell.styles.fillColor = [255, 255, 255]; // White color
          });
        }
      },
      addPageContent: function (data) {
        // Add page numbers to the bottom-left corner
        const pageCount = doc.internal.getNumberOfPages();
        for (let i = 0; i < pageCount; i++) {
          doc.setPage(i);
          doc.text("Page " + (i + 1), 10, doc.internal.pageSize.height - 10);
        }

        // Get the current date and time
        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleDateString();
        const formattedTime = currentDate.toLocaleTimeString();

        // Calculate the position for the date and time
        const dateXPos = doc.internal.pageSize.width - 80; // X position for the text
        const dateYPos = doc.internal.pageSize.height - 10; // Y position for the text

        // Add the date and time to the bottom-right corner
        doc.text(`Date: ${formattedDate}`, dateXPos, dateYPos);
        doc.text(`Time: ${formattedTime}`, dateXPos + 25, dateYPos); // Adjust Y position for time
      },
    };

    // Generate the first table using autoTable function
    doc.autoTable(headers1, data1, tableOptions);

    // Adjust startY for the second table to start below the first table
    tableOptions.startY = doc.autoTable.previous.finalY + 10;

    // Generate the second table using autoTable function
    doc.autoTable(headers2, data2, tableOptions);

    // Save the PDF
    doc.save("shipment-details.pdf");
  };

  const handleDownloadClick = () => {
    const id = searchParams.get("id");
    const contid = searchParams.get("contid");
    generatePDF(id, contid);
  };

  useEffect(() => {
    axios
      .get(
        `https://sgserver.net/shipmenttracking/index2.php?database=walkerslogistics&id=${id}&contid=${contid}`
      )
      .then((response) => {
        if (response.data.length > 0) {
          setItems(response.data);
          setNotFound(false);
        } else {
          setItems([]);
          setNotFound(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id, contid]);

  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
          Transshipment Details
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>
              
              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Transshipment Details
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* Contact Start */}
      <div className="container-xxl py-5">
        <div className="container">
        <div className="row g-5">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.5s">
              <div className="border-start border-5 border-primary ps-4 mb-5">
                <h6 className="text-body text-uppercase mb-2">
                Transshipment Details
                </h6>
                <h1 className="display-6 mb-0">
                  Track and Trace Made Easy - Follow Your Shipment's Journey!
                </h1>
              </div>

            </div>
          </div>
          <div className="table-wrapper">
          {items.length > 0 && (
                  <div className="row">
                    <div className="mt-4 pt-4 text-center table-wrapper">
                      <h5>
                        {" "}
                        Transshipment Details For Entered Container No.:{" "}
                        <span style={{ color: "#0f1787" }}>{contid}</span>
                      </h5>
                      <br />
                      {notFound ? (
                        <p>No items found with the given parameters.</p>
                      ) : (
                        <table className="responsive-table">
                          <thead>
                            <tr>
                              <th scope="col">TYPE</th>
                              <th scope="col">PORT OF DESTINATION</th>
                              <th scope="col">ESTIMATED ARRIVAL DATE </th>
                              <th scope="col">SHIPPED DESTINATION</th>
                              <th scope="col"> ACTUAL ARRIVAL DATE</th>
                            </tr>
                          </thead>
                          <tbody>
                            {items.map(
                              (item) =>
                                // Check if the necessary data is available before rendering the row
                                item.equipmentType &&
                                item.portOfDischarge &&
                                item.exTimeArrival ? (
                                  <tr key={item.id}>
                                    <td data-title="TYPE">
                                      {item.equipmentType}
                                    </td>
                                    <td data-title="PORT OF DESTINATION">
                                      {item.portOfDischarge}
                                    </td>
                                    <td data-title="ESTIMATED ARRIVAL DATE">
                                      {item.exTimeArrival}
                                    </td>
                                    <td data-title="SHIPPED DESTINATION">
                                      {item.portOfDischarge}
                                    </td>
                                    <td data-title="ACTUAL ARRIVAL DATE">
                                      {item.exTimeArrival}
                                    </td>
                                  </tr>
                                ) : null // Exclude the row if necessary data is not available
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>

                    <div className="mt-4 pt-4 text-center table-wrapper">
                      <h5>
                      Transshipment Details of Container No:
                        <span style={{ color: "#0f1787" }}>{contid}</span>{" "}
                        <br />Transshipment Details of BL No:
                        <span style={{ color: "#0f1787" }}>{id}</span>
                      </h5>{" "}
                      <br />
                      {notFound ? (
                        <p>No items found with the given parameters.</p>
                      ) : (
                        <table className="responsive-table">
                          <thead>
                            <tr>
                              <th scope="col">Description</th>
                              <th scope="col">Location</th>

                              <th scope="col">Date</th>
                              <th scope="col">Vessel</th>
                              <th scope="col"> Voyage </th>
                            </tr>
                          </thead>
                          <tbody>
                            {items.map(
                              (item) =>
                                // Check if the necessary data is available before rendering the row
                                item.containerStatus &&
                                item.location &&
                                item.containerDate &&
                                item.Vessel &&
                                item.Voyage ? (
                                  <tr key={item.id}>
                                    <td data-title="Description">
                                      {item.containerStatus}
                                    </td>
                                    <td data-title="Location">
                                      {item.location}
                                    </td>
                                    <td data-title="Date">
                                      {item.containerDate}
                                    </td>
                                    <td data-title="Vessel">{item.Vessel}</td>
                                    <td data-title="Voyage">{item.Voyage}</td>
                                    {/* Add more table cells based on the item properties */}
                                  </tr>
                                ) : null // Exclude the row if necessary data is not available
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                    <div className="text-center p-2">
                      <button id="downloadButton" onClick={handleDownloadClick}>
                        <img src="assets/images/pdf.png" height="20px" />
                      </button>
                    </div>
                  </div>
                )}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
