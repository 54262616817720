import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid "
        style={{
          backgroundImage: 'url("img/banner.jpg")',
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          padding: "70px",
        }}
      >
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="display-4 text-white">Road Freight</h1>
            <Link to="/Home">Home</Link>
            <i className="far fa-square text-primary px-2" />
            <Link href>Road Freight</Link>
          </div>
        </div>
      </div>
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-8">
            {/* Blog Detail Start */}
            <div className="mb-5">
              <img className="img-fluid w-100 mb-5" src="img/Road Freight.jpg" alt="" />
              <h1 className="mb-4">Road Freight</h1>
              <p>When it comes to handling oversized or fragile loads that might pose logistical challenges, rest assured, we've got you covered. Our comprehensive expertise extends to managing cargo that is either too large to fit conventional transport methods or too delicate to endure rough handling. We are intricately connected through and through the logistics network, allowing us to find innovative and effective solutions to move such specialized loads with precision and care.</p><p>Whether you require Full Truckload (FTL) or Less than Truckload (LTL) services, consider it done! Our specialization in road freight encompasses a wide spectrum of offerings, catering to various cargo requirements. From standard shipments that demand reliable delivery to temperature-controlled transports requiring precise environmental conditions, we have the capabilities and infrastructure to ensure your cargo reaches its destination intact and on time.</p><p>Our commitment to excellence in road freight services extends beyond the ordinary. We understand that certain cargo demands extra attention and protection, which is why our team is trained to handle even the most sensitive shipments. Whether it's securing delicate machinery, transporting valuable artworks, or ensuring the safe delivery of high-value goods, we take pride in our ability to provide tailored, secure, and efficient transportation solutions. When you partner with us, you can trust that your cargo is in the hands of experts who prioritize both the safety of your load and the timeliness of its delivery.</p>
            </div>
           
          </div>
          {/* Sidebar Start */}
          <div className="col-lg-4">
           
           
            <div className="mb-5">
              <h2 className="mb-4">Other Services</h2>
              <div className="d-flex flex-column justify-content-start bg-secondary p-4">
                <Link className="h5 mb-3" to="/Fabric"><i className="bi bi-arrow-right text-primary me-2" />Fabric and Garment Trading</Link>
                <Link className="h5 mb-3" to="/Ocean"><i className="bi bi-arrow-right text-primary me-2" />Ocean Freight Services</Link>
                <Link className="h5 mb-3" to="/Air"><i className="bi bi-arrow-right text-primary me-2" />Air Freight</Link>
                <Link className="h5 mb-3" to="/Cargo"><i className="bi bi-arrow-right text-primary me-2" />Cargo</Link>
                <Link className="h5 mb-3" to="/Road"><i className="bi bi-arrow-right text-primary me-2" />Road Freight</Link>
                <Link className="h5 mb-3" to="/Packaging"><i className="bi bi-arrow-right text-primary me-2" />Packaging</Link>
                <Link className="h5 mb-3" to="/Warehousing"><i className="bi bi-arrow-right text-primary me-2" />Warehousing</Link>
                
              </div>
            </div>
           
            {/* Plain Text End */}
          </div>
          {/* Sidebar End */}
        </div>
      </div>

      <Footer />
    </>
  );
}
