import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
          Electronics
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Electronics
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-12">
            <div className="mb-5">
              <div className="text-center" style={{ padding: "10px 80px" }}>
                <h1 className="mb-4">Electronics</h1>
                <img
                  className="img-fluid w-100 mb-5 "
                  src="img/Electronics.jpg"
                  alt=""
                />
              </div>

              <p>The significance of handling expensive and delicate electronic equipment cannot be overstated, given the specialized measures it demands throughout handling, transit, and brokerage processes. At the core of our operations lies a deep reverence for company confidentiality and national security. This ethos is intricately woven into our services, catering to the intricate needs of internal cargo build-up, meticulous screening, customs brokerage, and even on-site surveillance and security protocols to safeguard your interests.</p>
<p>In a fiercely competitive market landscape, we employ strategic tactics to ensure optimal service. Our preferred carrier program, bolstered by blocked space agreements (BSAs), secures access to coveted destinations. This, coupled with our deployment of chartered aircraft, expedited marine freight, and a spectrum of multi-modal alternatives, forms our arsenal against cost escalation and extended lead times.</p>
<p>Within the electronics sector, we don't just offer a service – we emerge as the definitive partner. Whether it's navigating the intricacies of raw material logistics or facilitating the seamless transit of finished products, we stand as the go-to solution. Our commitment is singular – ensuring the immaculate transport of electronic essentials while fostering growth and success within the industry.</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
