import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Custom Clearance
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Custom Clearance
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-4">
            <div className="mb-5">
              <h2 className="mb-4">Other Services</h2>
              <div className="d-flex flex-column justify-content-start bg-secondary p-4">
                <Link className="h5 mb-3" to="/Sea">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Sea Freight
                </Link>
                <Link className="h5 mb-3" to="/Air">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Air Freight
                </Link>
                <Link className="h5 mb-3" to="/LCL">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  LCL Consolidation
                </Link>
                <Link className="h5 mb-3" to="/Custom">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Custom Clearance
                </Link>
                <Link className="h5 mb-3" to="/Local">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Local Transportation
                </Link>
                <Link className="h5 mb-3" to="/Special">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Special Equipments
                </Link>
                <Link className="h5 mb-3" to="/Hazardous">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Hazardous Materials
                </Link>
                <Link className="h5 mb-3" to="/Warehousing">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Warehousing and Distribution
                </Link>
                <Link className="h5 mb-3" to="/Bonded">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Bonded & Warehouse Storage
                </Link>
              </div>
            </div>

            {/* Plain Text End */}
          </div>
          <div className="col-lg-8">
            {/* Blog Detail Start */}
            <div className="mb-5">
              <img
                className="img-fluid w-100 mb-5"
                src="img/Custom Clearance.jpg"
                alt=""
              />
              <h1 className="mb-4">Custom Clearance</h1>
              <p>
                Walkers Logistics utilizes an advanced system directly linked to
                Singapore Customs, enabling us to make direct declarations for
                import/export custom permits.
              </p>

              <p>Our services also include:</p>

              <ul>
                <li>
                  Preparation of assorted international trade documents such as
                  Form D, Form E, COO, and more. These documents help importers
                  benefit from various tax and duty rebates or exemptions.
                </li>
                <li>
                  Application of GST relief on behalf of individuals residing in
                  Singapore.
                </li>
                <li>Permit declaration for major export schemes (ME).</li>
                <li>
                  Utilization of the Portnet System, which is directly linked to
                  the Port of Authority, ensuring accurate shipping information
                  retrieval.
                </li>
                <li>
                  Liaison with custom officers and chambers for customs-related
                  matters.
                </li>
              </ul>
            </div>
          </div>
          {/* Sidebar Start */}

          {/* Sidebar End */}
        </div>
      </div>

      <Footer />
    </>
  );
}
