import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid "
        style={{
          backgroundImage: 'url("img/banner.jpg")',
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          padding: "70px",
        }}
      >
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="display-4 text-white">Oil and Gas</h1>
            <Link to="/Home">Home</Link>
            <i className="far fa-square text-primary px-2" />
            <Link href>Oil and Gas</Link>
          </div>
        </div>
      </div>
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-12">
            <div className="mb-5">
              <div className="text-center" style={{ padding: "10px 80px" }}>
                <h1 className="mb-4">Oil and Gas</h1>
                <img
                  className="img-fluid w-100 mb-5 "
                  src="img/Oil and Gas.jpg"
                  alt=""
                />
              </div>

              <p>
                Safety holds paramount significance in the Oil & Gas supply
                chains, and at {companyname}, we recognize its utmost importance. We
                are fully equipped to assist you at every step of the process
                with our specialized services, ensuring the safety and
                efficiency of your operations. Our services encompass everything
                from the secure and efficient transportation of oil & gas
                products to international project coordination, regardless of
                your location around the globe.
              </p>

              <p>
                Our team comprises experts with technical prowess who are
                readily available to support you at all times. We are dedicated
                to helping you manage the entire supply chain, catering to your
                unique needs. Our overarching business objective is to fulfill
                your individual requirements, offering unwavering support in
                every facet of your operations. This spans from upstream supply
                for exploration endeavors to facilitating Offshore Oil and Gas
                (OOS) upgrades with operational on-site services.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
