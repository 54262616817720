import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
          Services
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Services
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-end mb-5">
            <div className="col-lg-8   wow fadeInUp" data-wow-delay="0.1s">
              <div className="border-start border-5 border-primary ps-4">
                <h6 className="text-body text-uppercase mb-2">Our Services</h6>
                <h1 className="display-6 mb-0">
                  Unlock Your Potential in Freight Forwarding
                </h1>
              </div>
            </div>
            <div
              className="col-lg-4 text-lg-end wow fadeInUp"
              data-wow-delay="0.3s"
            >
              
            </div>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-light overflow-hidden h-100">
                <img className="img-fluid" src="img/Sea Freight.jpg" alt="" />
                <div className="service-text position-relative text-center h-100 p-4">
                  <h5 className="mb-3">Sea Freight</h5>
                  <p>Whether you are moving places or trading with international
                companies, you would require the services of a freight forwarder..</p>
                  <Link className="small" to="/Sea">
                    READ MORE
                    <i className="fa fa-arrow-right ms-3" />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-light overflow-hidden h-100">
                <img className="img-fluid" src="img/Air Freight.jpg" alt="" />
                <div className="service-text position-relative text-center h-100 p-4">
                  <h5 className="mb-3">Air Freight</h5>
                  <p>Exporting by Air Freight may be the solution if you need to get
                your products to the destination within a tight time frame, as..</p>
                  <Link className="small" to="/Air">
                    READ MORE
                    <i className="fa fa-arrow-right ms-3" />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-light overflow-hidden h-100">
                <img
                  className="img-fluid"
                  src="img/LCL Consolidation.jpg"
                  alt=""
                />
                <div className="service-text position-relative text-center h-100 p-4">
                  <h5 className="mb-3">LCL Consolidation</h5>
                  <p>Walkers Logistics offers you the most affordable LCL shipment
                charges, and if you are wondering how this is possible, it's..</p>
                  <Link className="small" to="/LCL">
                    READ MORE
                    <i className="fa fa-arrow-right ms-3" />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-light overflow-hidden h-100">
                <img
                  className="img-fluid"
                  src="img/Custom Clearance.jpg"
                  alt=""
                />
                <div className="service-text position-relative text-center h-100 p-4">
                  <h5 className="mb-3">Custom Clearance</h5>
                  <p>Walkers Logistics utilizes an advanced system directly linked to
                Singapore Customs, enabling us to make direct declarations for..</p>
                  <Link className="small" to="/Custom">
                    READ MORE
                    <i className="fa fa-arrow-right ms-3" />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-light overflow-hidden h-100">
                <img
                  className="img-fluid"
                  src="img/Local Transportation.jpg"
                  alt=""
                />
                <div className="service-text position-relative text-center h-100 p-4">
                  <h5 className="mb-3">Local Transportation</h5>
                  <p>When you talk about Transport and Logistics Company Services,
                they have different sets of operations, but these two work..</p>
                  <Link className="small" to="/Local">
                    READ MORE
                    <i className="fa fa-arrow-right ms-3" />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-light overflow-hidden h-100">
                <img
                  className="img-fluid"
                  src="img/Special Equipments.jpg"
                  alt=""
                />
                <div className="service-text position-relative text-center h-100 p-4">
                  <h5 className="mb-3">Special Equipments</h5>
                  <p>For cargo that cannot fit into a normal container, Walkers
                Logistics can handle special handling and transportation to meet..</p>
                  <Link className="small" to="/Special">
                    READ MORE
                    <i className="fa fa-arrow-right ms-3" />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-light overflow-hidden h-100">
                <img
                  className="img-fluid"
                  src="img/Hazardous Materials.jpg"
                  alt=""
                />
                <div className="service-text position-relative text-center h-100 p-4">
                  <h5 className="mb-3">Hazardous Materials</h5>
                  <p>Handling dangerous cargo can be a complex task. At Walkers
                Logistics, we have a dedicated team of experienced staff with..</p>
                  <Link className="small" to="/Hazardous">
                    READ MORE
                    <i className="fa fa-arrow-right ms-3" />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-light overflow-hidden h-100">
                <img
                  className="img-fluid"
                  src="img/Warehousing and Distribution.jpg"
                  alt=""
                />
                <div className="service-text position-relative text-center h-100 p-4">
                  <h5 className="mb-3">Warehousing and Distribution</h5>
                  <p>If you are searching for a space to store your goods and
                products, you might as well look for warehouse space. Warehouses..</p>
                  <Link className="small" to="/Warehousing">
                    READ MORE
                    <i className="fa fa-arrow-right ms-3" />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-light overflow-hidden h-100">
                <img
                  className="img-fluid"
                  src="img/Bonded & Warehouse Storage.jpg"
                  alt=""
                />
                <div className="service-text position-relative text-center h-100 p-4">
                  <h5 className="mb-3">Bonded & Warehouse Storage</h5>
                  <p>To become a competitive advantage in the market, Walkers
                Logistics warehouse holds a bonded license, allowing us to..</p>
                  <Link className="small" to="/Bonded">
                    READ MORE
                    <i className="fa fa-arrow-right ms-3" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
