import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />{" "}
      <div
        className="container-fluid p-0 mb-5 wow fadeIn w3-banner jarallax"
        data-wow-delay="0.1s"
      >
 <video
          className="img-fluid"
          autoPlay
          muted
          loop
          style={{ zIndex: "-1" }}
        >
          <source src="img/video.mp4" type="video/mp4" />
        </video>
      </div>
      {/* Carousel End */}
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div
                className="position-relative overflow-hidden ps-5 pt-5 h-100"
                style={{ minHeight: "400px" }}
              >
                <img
                  className="position-absolute w-100 h-100"
                  src="img/About1.jpg"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
                <div
                  className="position-absolute top-0 start-0 bg-white pe-3 pb-3"
                  style={{ width: "200px", height: "200px" }}
                >
                  <div className="d-flex flex-column justify-content-center text-center bg-primary h-100 p-3">
                    <h5 className="text-white mb-0">{companyname}</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <div className="border-start border-5 border-primary ps-4 mb-5">
                  <h6 className="text-body text-uppercase mb-2">About Us</h6>
                  <h1 className="display-6 mb-0">
                    Logistics Company In Singapore!
                  </h1>
                </div>
                <p>
                  Walkers Logistics Pte Ltd provides the most seamless and
                  smooth logistics solutions. It includes all the single
                  operations to multiple supply chain operations, requiring
                  technology and a global network for flawless services. Being
                  the most experienced logistics company in Singapore, we have
                  served customers in various industries and markets. We know
                  how to tailor our services as per the demands and needs of
                  each customer. Amongst all the logistics companies, you will
                  find our practices and services reliable and prompt.
                  <br />
                  Contact now for excellent logistics coordination!
                </p>
                <div className="border-top mt-4 pt-4">
                  <div className="row g-4">
                    <div
                      className="col-sm-4 d-flex wow fadeIn"
                      data-wow-delay="0.1s"
                    >
                      <i className="fa fa-check fa-2x text-primary flex-shrink-0 me-3" />
                      <h6 className="mb-0">Quality</h6>
                    </div>
                    <div
                      className="col-sm-4 d-flex wow fadeIn"
                      data-wow-delay="0.3s"
                    >
                      <i className="fa fa-check fa-2x text-primary flex-shrink-0 me-3" />
                      <h6 className="mb-0">Safety And Security</h6>
                    </div>
                    <div
                      className="col-sm-4 d-flex wow fadeIn"
                      data-wow-delay="0.5s"
                    >
                      <i className="fa fa-check fa-2x text-primary flex-shrink-0 me-3" />
                      <h6 className="mb-0">Reliability</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
            {/* Service Start */}
            <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-end mb-5">
            <div className="col-lg-8   wow fadeInUp" data-wow-delay="0.1s">
              <div className="border-start border-5 border-primary ps-4">
                <h6 className="text-body text-uppercase mb-2">Our Services</h6>
                <h1 className="display-6 mb-0">
                  Unlock Your Potential in Freight Forwarding
                </h1>
              </div>
            </div>
            <div
              className="col-lg-4 text-lg-end wow fadeInUp"
              data-wow-delay="0.3s"
            >
              
            </div>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-light overflow-hidden h-100">
                <img className="img-fluid" src="img/Sea Freight.jpg" alt="" />
                <div className="service-text position-relative text-center h-100 p-4">
                  <h5 className="mb-3">Sea Freight</h5>
                  <p>Whether you are moving places or trading with international
                companies, you would require the services of a freight forwarder..</p>
                  <Link className="small" to="/Sea">
                    READ MORE
                    <i className="fa fa-arrow-right ms-3" />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-light overflow-hidden h-100">
                <img className="img-fluid" src="img/Air Freight.jpg" alt="" />
                <div className="service-text position-relative text-center h-100 p-4">
                  <h5 className="mb-3">Air Freight</h5>
                  <p>Exporting by Air Freight may be the solution if you need to get
                your products to the destination within a tight time frame, as..</p>
                  <Link className="small" to="/Air">
                    READ MORE
                    <i className="fa fa-arrow-right ms-3" />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-light overflow-hidden h-100">
                <img
                  className="img-fluid"
                  src="img/LCL Consolidation.jpg"
                  alt=""
                />
                <div className="service-text position-relative text-center h-100 p-4">
                  <h5 className="mb-3">LCL Consolidation</h5>
                  <p>Walkers Logistics offers you the most affordable LCL shipment
                charges, and if you are wondering how this is possible, it's..</p>
                  <Link className="small" to="/LCL">
                    READ MORE
                    <i className="fa fa-arrow-right ms-3" />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-light overflow-hidden h-100">
                <img
                  className="img-fluid"
                  src="img/Custom Clearance.jpg"
                  alt=""
                />
                <div className="service-text position-relative text-center h-100 p-4">
                  <h5 className="mb-3">Custom Clearance</h5>
                  <p>Walkers Logistics utilizes an advanced system directly linked to
                Singapore Customs, enabling us to make direct declarations for..</p>
                  <Link className="small" to="/Custom">
                    READ MORE
                    <i className="fa fa-arrow-right ms-3" />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-light overflow-hidden h-100">
                <img
                  className="img-fluid"
                  src="img/Local Transportation.jpg"
                  alt=""
                />
                <div className="service-text position-relative text-center h-100 p-4">
                  <h5 className="mb-3">Local Transportation</h5>
                  <p>When you talk about Transport and Logistics Company Services,
                they have different sets of operations, but these two work..</p>
                  <Link className="small" to="/Local">
                    READ MORE
                    <i className="fa fa-arrow-right ms-3" />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-light overflow-hidden h-100">
                <img
                  className="img-fluid"
                  src="img/Special Equipments.jpg"
                  alt=""
                />
                <div className="service-text position-relative text-center h-100 p-4">
                  <h5 className="mb-3">Special Equipments</h5>
                  <p>For cargo that cannot fit into a normal container, Walkers
                Logistics can handle special handling and transportation to meet..</p>
                  <Link className="small" to="/Special">
                    READ MORE
                    <i className="fa fa-arrow-right ms-3" />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-light overflow-hidden h-100">
                <img
                  className="img-fluid"
                  src="img/Hazardous Materials.jpg"
                  alt=""
                />
                <div className="service-text position-relative text-center h-100 p-4">
                  <h5 className="mb-3">Hazardous Materials</h5>
                  <p>Handling dangerous cargo can be a complex task. At Walkers
                Logistics, we have a dedicated team of experienced staff with..</p>
                  <Link className="small" to="/Hazardous">
                    READ MORE
                    <i className="fa fa-arrow-right ms-3" />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-light overflow-hidden h-100">
                <img
                  className="img-fluid"
                  src="img/Warehousing and Distribution.jpg"
                  alt=""
                />
                <div className="service-text position-relative text-center h-100 p-4">
                  <h5 className="mb-3">Warehousing and Distribution</h5>
                  <p>If you are searching for a space to store your goods and
                products, you might as well look for warehouse space. Warehouses..</p>
                  <Link className="small" to="/Warehousing">
                    READ MORE
                    <i className="fa fa-arrow-right ms-3" />
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item bg-light overflow-hidden h-100">
                <img
                  className="img-fluid"
                  src="img/Bonded & Warehouse Storage.jpg"
                  alt=""
                />
                <div className="service-text position-relative text-center h-100 p-4">
                  <h5 className="mb-3">Bonded & Warehouse Storage</h5>
                  <p>To become a competitive advantage in the market, Walkers
                Logistics warehouse holds a bonded license, allowing us to..</p>
                  <Link className="small" to="/Bonded">
                    READ MORE
                    <i className="fa fa-arrow-right ms-3" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Service End */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="border-start border-5 border-primary ps-4 mb-5">
                <h6 className="text-body text-uppercase mb-2">
                  Why Choose Us!
                </h6>
                <h1 className="display-6 mb-0">
                  Warehouse Company in Singapore
                </h1>
              </div>
              <p className="mb-5">
                At Walkers Logistics Pte Ltd, you will get the best warehousing
                solutions and services as per your need. With our warehouse in
                Singapore, we try to facilitate all our customers by storing
                their products safely and helping them reach the final
                consumers. As a warehouse company in Singapore, we assist the
                manufacturers, suppliers, and business owners in getting a
                storage facility at the most affordable rates and giving them
                access to transportation and goods distribution. Contact us for
                effective and efficient solutions and storage services!
              </p>
              <div className="row gy-5 gx-4">
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                  <div className="d-flex align-items-center mb-3">
                    <i className="fa fa-check fa-2x text-primary flex-shrink-0 me-3" />
                    <h6 className="mb-0">Network</h6>
                  </div>
                </div>
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                  <div className="d-flex align-items-center mb-3">
                    <i className="fa fa-check fa-2x text-primary flex-shrink-0 me-3" />
                    <h6 className="mb-0">Warehouse Storage Facility</h6>
                  </div>
                </div>
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                  <div className="d-flex align-items-center mb-3">
                    <i className="fa fa-check fa-2x text-primary flex-shrink-0 me-3" />
                    <h6 className="mb-0">Various Freight Options</h6>
                  </div>
                </div>
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                  <div className="d-flex align-items-center mb-3">
                    <i className="fa fa-check fa-2x text-primary flex-shrink-0 me-3" />
                    <h6 className="mb-0">Customer Satisfaction</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div
                className="position-relative overflow-hidden ps-5 pt-5 h-100"
                style={{ minHeight: "400px" }}
              >
                <img
                  className="position-absolute w-100 h-100"
                  src="img/About2.jpg"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
                <div
                  className="position-absolute top-0 start-0 bg-white pe-3 pb-3"
                  style={{ width: "200px", height: "200px" }}
                >
                  <div className="d-flex flex-column justify-content-center text-center bg-primary h-100 p-3">
                    <h5 className="text-white mb-0">{companyname}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Features End */}

      {/* Appointment Start */}
      <div
        className="container-fluid appointment my-5 py-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-5 col-md-6 wow fadeIn" data-wow-delay="0.3s">
              <div className="border-start border-5 border-primary ps-4 mb-5">
                <h6 className="text-white text-uppercase mb-2">Contact Us</h6>
                <h1 className="display-6 text-white mb-0">
                  Let's Move Your Business Forward – Start with Our Contact
                  Form!
                </h1>
              </div>
              <p className="text-white mb-0">
                Our team of experts is committed to providing you with top-notch
                freight solutions tailored to your unique needs. With years of
                industry experience and a global network of partners, we have
                the expertise and resources to handle your cargo with care and
                precision.
              </p>
            </div>
            <div className="col-lg-7 col-md-6 wow fadeIn" data-wow-delay="0.5s">
            <form
              id="contactForm"
              action="/php/thankyou-contact.php "
              method="post"
              onSubmit={(event) => handleSubmit(event)}
            >
              <div className="row g-3">
                <div className="col-6">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      onChange={(event) => handleFnameChange(event)}
                      type="text"
                      id="fname"
                      name="fname"
                      value={fname}
                      placeholder="Enter your first name"
                      required
                    />
                    <label htmlFor="form-floating-1">First Name</label>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      onChange={(event) => handlelnameChange(event)}
                      type="text"
                      id="lname"
                      name="lname"
                      value={lname}
                      placeholder="Enter your last name"
                      required
                    />
                    <label htmlFor="form-floating-2">Last Name</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      onChange={(event) => handleemailChange(event)}
                      type="email"
                      id="email"
                      name="email"
                      value={email}
                      placeholder="Enter your Email"
                      required
                    />
                    <label htmlFor="form-floating-3">Email Address</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-floating">
                    <input
                      className="form-control"
                      onChange={(event) => handlesubjectChange(event)}
                      type="text"
                      id="subject"
                      name="subject"
                      value={subject}
                      placeholder="Enter subject"
                      required
                    />
                    <label htmlFor="form-floating-3">Enter subject</label>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-floating">
                    <textarea
                      className="form-control"
                      id="message"
                      name="message"
                      rows={1}
                      placeholder="Enter Message"
                      defaultValue={""}
                      value={message}
                      onChange={(event) => handlemessageChange(event)}
                    />
                    <label htmlFor="form-floating-4">Message</label>
                  </div>
                </div>
                <div className="col-12">
                  <button
                    className="btn btn-primary w-100 py-3"
                    type="submit"
                    defaultValue=""
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
            <h5 style={{ color: "red" }}>{result}</h5>
            </div>
          </div>
        </div>
      </div>
      {/* Appointment End */}
      {/* Team Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-end mb-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="border-start border-5 border-primary ps-4">
                <h6 className="text-body text-uppercase mb-2">Our Industry Solutions</h6>
                <h1 className="display-6 mb-0">On Time, On Track, On Target.</h1>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <p className="mb-0">
                Our company is committed to meeting deadlines, maintaining shipment integrity, and achieving clients' objectives with precision. It's a concise and powerful slogan that can inspire trust and confidence in your services.
              </p>
            </div>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            ><Link to="/Apparel">
              <div className="team-item position-relative">
                <img className="img-fluid" src="img/Apparel.jpg" alt="" />
                <div className="team-text bg-white p-4">
                  <h5>Apparel</h5>
                  <span>Industries</span>
                </div>
              </div></Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            ><Link to="/Healthcare">
              <div className="team-item position-relative">
                <img className="img-fluid" src="img/Healthcare.jpg" alt="" />
                <div className="team-text bg-white p-4">
                  <h5>Healthcare</h5>
                  <span>Industries</span>
                </div>
              </div></Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            ><Link to="/Food">
              <div className="team-item position-relative">
                <img className="img-fluid" src="img/Food & Beverages.jpg" alt="" />
                <div className="team-text bg-white p-4">
                  <h5>Food & Beverages</h5>
                  <span>Industries</span>
                </div>
              </div></Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            ><Link to="/Energy">
              <div className="team-item position-relative">
                <img className="img-fluid" src="img/Energy.jpg" alt="" />
                <div className="team-text bg-white p-4">
                  <h5>Energy</h5>
                  <span>Industries</span>
                </div>
              </div></Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            ><Link to="/Automotive">
              <div className="team-item position-relative">
                <img className="img-fluid" src="img/Automotive.jpg" alt="" />
                <div className="team-text bg-white p-4">
                  <h5>Automotive</h5>
                  <span>Industries</span>
                </div>
              </div></Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            ><Link to="/Electronics">
              <div className="team-item position-relative">
                <img className="img-fluid" src="img/Electronics.jpg" alt="" />
                <div className="team-text bg-white p-4">
                  <h5>Electronics</h5>
                  <span>Industries</span>
                </div>
              </div></Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            ><Link to="/Retail">
              <div className="team-item position-relative">
                <img className="img-fluid" src="img/Retail.jpg" alt="" />
                <div className="team-text bg-white p-4">
                  <h5>Retail</h5>
                  <span>Industries</span>
                </div>
              </div></Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            ><Link to="/High">
              <div className="team-item position-relative">
                <img className="img-fluid" src="img/High Tech.jpg" alt="" />
                <div className="team-text bg-white p-4">
                  <h5>High Tech</h5>
                  <span>Industries</span>
                </div>
              </div></Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            ><Link to="/Industrial">
              <div className="team-item position-relative">
                <img className="img-fluid" src="img/Industrial.jpg" alt="" />
                <div className="team-text bg-white p-4">
                  <h5>Industrial</h5>
                  <span>Industries</span>
                </div>
              </div></Link>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
