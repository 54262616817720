import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid "
        style={{
          backgroundImage: 'url("img/banner.jpg")',
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          padding: "70px",
        }}
      >
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="display-4 text-white">Mission-Vision</h1>
            <Link to="/Home">Home</Link>
            <i className="far fa-square text-primary px-2" />
            <Link href>Industries</Link>
          </div>
        </div>
      </div>
      <div className="container-fluid pt-6 px-5">
        <div className="text-center mx-auto mb-5" style={{ maxWidth: "600px" }}>
          <hr className="w-25 mx-auto bg-primary" />
        </div>
        <div className="row g-5">
          <div className="col-lg-6 col-md-6">
            <div className="service-item bg-secondary text-center px-5">
              <div
                className="d-flex align-items-center justify-content-center bg-primary text-white rounded-circle mx-auto mb-4"
                style={{ width: "90px", height: "90px" }}
              >
                <i className="fa fa-bullseye fa-2x" />
              </div>
              <h3 className="mb-3">Our Mission</h3>
              <p className="mb-0">
              ALPS Trade & Logistics Ltd. aims to become a shipping and freight forwarding specialist, providing excellent and professional shipping and logistics solutions to customers. And continuously innovating to make a remarkable difference in service
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="service-item bg-secondary text-center px-5">
              <div
                className="d-flex align-items-center justify-content-center bg-primary text-white rounded-circle mx-auto mb-4"
                style={{ width: "90px", height: "90px" }}
              >
                <i className="fa fa-eye fa-2x" />
              </div>
              <h3 className="mb-3">Our Vision</h3>
              <p className="mb-0">
              To deliver, through both off and online, Quality Instrumentation and Tech Products and the best of After Market Resolutions, and be the leader.
              </p>
            </div>
          </div>

        </div>
      </div>
      <div className="container-fluid py-6 px-5">
        <div className="text-center mx-auto mb-5" style={{ maxWidth: "600px" }}>
          <h1 className="display-5 mb-0">Solutions We Provide</h1>
          <hr className="w-25 mx-auto bg-primary" />
        </div>
        <div className="row g-5">
          <div className="col-lg-4">
            <div className="blog-item">
              <div className="position-relative overflow-hidden">
                <img
                  className="img-fluid"
                  src="img/Fabric and Garment Trading.jpg"
                  alt=""
                />
              </div>
              <div className="bg-secondary d-flex">
                <div className="flex-shrink-0 d-flex flex-column justify-content-center text-center bg-primary text-white px-4">
                  <h5 className="text-uppercase m-0">Services</h5>
                </div>
                <div className="d-flex flex-column justify-content-center py-3 px-4">
                  <div className="d-flex mb-2"></div>
                  <Link className="h4" to="/Fabric">
                    Fabric and Garment Trading
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="blog-item">
              <div className="position-relative overflow-hidden">
                <img
                  className="img-fluid"
                  src="img/Ocean Freight Services.jpg"
                  alt=""
                />
              </div>
              <div className="bg-secondary d-flex">
                <div className="flex-shrink-0 d-flex flex-column justify-content-center text-center bg-primary text-white px-4">
                  <h5 className="text-uppercase m-0">Services</h5>
                </div>
                <div className="d-flex flex-column justify-content-center py-3 px-4">
                  <div className="d-flex mb-2"></div>
                  <Link className="h4" to="/Ocean">
                    Ocean Freight Services
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="blog-item">
              <div className="position-relative overflow-hidden">
                <img className="img-fluid" src="img/Air Freight.jpg" alt="" />
              </div>
              <div className="bg-secondary d-flex">
                <div className="flex-shrink-0 d-flex flex-column justify-content-center text-center bg-primary text-white px-4">
                  <h5 className="text-uppercase m-0">Services</h5>
                </div>
                <div className="d-flex flex-column justify-content-center py-3 px-4">
                  <div className="d-flex mb-2"></div>
                  <Link className="h4" to="/Air">
                    Air Freight
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="blog-item">
              <div className="position-relative overflow-hidden">
                <img className="img-fluid" src="img/Cargo.jpg" alt="" />
              </div>
              <div className="bg-secondary d-flex">
                <div className="flex-shrink-0 d-flex flex-column justify-content-center text-center bg-primary text-white px-4">
                  <h5 className="text-uppercase m-0">Services</h5>
                </div>
                <div className="d-flex flex-column justify-content-center py-3 px-4">
                  <div className="d-flex mb-2"></div>
                  <Link className="h4" to="/Cargo">
                    Cargo
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="blog-item">
              <div className="position-relative overflow-hidden">
                <img className="img-fluid" src="img/Road Freight.jpg" alt="" />
              </div>
              <div className="bg-secondary d-flex">
                <div className="flex-shrink-0 d-flex flex-column justify-content-center text-center bg-primary text-white px-4">
                  <h5 className="text-uppercase m-0">Services</h5>
                </div>
                <div className="d-flex flex-column justify-content-center py-3 px-4">
                  <div className="d-flex mb-2"></div>
                  <Link className="h4" to="/Road">
                    Road Freight
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="blog-item">
              <div className="position-relative overflow-hidden">
                <img className="img-fluid" src="img/Warehousing.jpg" alt="" />
              </div>
              <div className="bg-secondary d-flex">
                <div className="flex-shrink-0 d-flex flex-column justify-content-center text-center bg-primary text-white px-4">
                  <h5 className="text-uppercase m-0">Services</h5>
                </div>
                <div className="d-flex flex-column justify-content-center py-3 px-4">
                  <div className="d-flex mb-2"></div>
                  <Link className="h4" to="/Warehousing">
                    Warehousing
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <Footer />
    </>
  );
}
