import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            LCL Consolidation
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                LCL Consolidation
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-4">
            <div className="mb-5">
              <h2 className="mb-4">Other Services</h2>
              <div className="d-flex flex-column justify-content-start bg-secondary p-4">
                <Link className="h5 mb-3" to="/Sea">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Sea Freight
                </Link>
                <Link className="h5 mb-3" to="/Air">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Air Freight
                </Link>
                <Link className="h5 mb-3" to="/LCL">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  LCL Consolidation
                </Link>
                <Link className="h5 mb-3" to="/Custom">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Custom Clearance
                </Link>
                <Link className="h5 mb-3" to="/Local">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Local Transportation
                </Link>
                <Link className="h5 mb-3" to="/Special">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Special Equipments
                </Link>
                <Link className="h5 mb-3" to="/Hazardous">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Hazardous Materials
                </Link>
                <Link className="h5 mb-3" to="/Warehousing">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Warehousing and Distribution
                </Link>
                <Link className="h5 mb-3" to="/Bonded">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Bonded & Warehouse Storage
                </Link>
              </div>
            </div>

            {/* Plain Text End */}
          </div>
          <div className="col-lg-8">
            {/* Blog Detail Start */}
            <div className="mb-5">
              <img
                className="img-fluid w-100 mb-5"
                src="img/LCL Consolidation.jpg"
                alt=""
              />
              <h1 className="mb-4">LCL Consolidation</h1>
              <p>
                Walkers Logistics offers you the most affordable LCL shipment
                charges, and if you are wondering how this is possible, it's
                because of our increasing tie-ups with major vessel operators
                and our global partners. Most importantly, we provide you with
                LCL Shipment or LCL consolidation services in our self-operated
                CFS Warehouse, which significantly reduces costs. This way,
                shipping costs can be shared with other shippers.
              </p>

              <p>
                <strong>LCL SHIPMENT CHARGES</strong>
              </p>

              <p>
                Additionally, we have a strong presence in Oceania, Dili, and
                Darwin services. We've successfully reduced shipping costs to a
                remarkable extent. We are committed to earning your trust and
                strive day and night to provide you with excellent consolidation
                services. We look forward to continually improving our services
                to assist our clients even better in the future.
              </p>
            </div>
          </div>
          {/* Sidebar Start */}

          {/* Sidebar End */}
        </div>
      </div>

      <Footer />
    </>
  );
}
