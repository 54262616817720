import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Food & Beverages
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Food & Beverages
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-12">
            <div className="mb-5">
              <div className="text-center" style={{ padding: "10px 80px" }}>
                <h1 className="mb-4">Food & Beverages</h1>
                <img
                  className="img-fluid w-100 mb-5 "
                  src="img/Food & Beverages.jpg"
                  alt=""
                />
              </div>

              <p>
                Driven by a dynamic team of professionals, our enterprise is
                wholly dedicated to delivering robust solutions in the realm of
                food and beverage logistics. We specialize in providing a
                seamless experience, harnessing temperature-controlled
                environments and cold chain storage to ensure the integrity of
                your products. Our commitment is rooted in extending the
                longevity of food items while preserving their utmost freshness.
              </p>
              <p>
                Our service spectrum encompasses a wide range of packaging and
                storage alternatives, catering to diverse food products. Through
                our tailored solutions, we enhance shelf life and uphold optimal
                freshness standards. Leveraging cutting-edge cross-docking
                systems, we expedite the transit of perishable and
                temperature-sensitive food items to their intended destinations,
                ensuring both speed and reliability.
              </p>
              <p>
                Our dedication to excellence is further reflected in our
                comprehensive supply chain visibility. This transparency
                empowers you to realize the benefits of increased production,
                reduced inventories, and, significantly, improved order
                fulfillment. Moreover, our proficiency extends to secure and
                dependable transportation, ensuring the safe movement of meals
                and beverages. When you partner with us, you're embracing a
                solution that is not only efficient but also trustworthy,
                delivering your goods with the utmost care and reliability.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
