import { useState } from "react";
import { useHistory } from "react-router-dom";
import Getquote from "../pages/Getquote";

import $ from "jquery";
import Header from "../components/Header";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  const [subemail, setSubemail] = useState("");
  const [result, setResult] = useState("");

  const handleSubemailChange = (e) => {
    setSubemail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
  <div className="container-fluid bg-dark footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container py-5">
          <div className="row g-5">
            
            <div className="col-lg-3 col-md-6">
              <h4 className="text-light mb-4">Address</h4>
              <p><i className="fa fa-map-marker-alt me-3" />{companyaddress}</p>
              <p><i className="fa fa-globe me-3" />{companyurl}</p>
              <p><i className="fa fa-envelope me-3" />{companyemail}</p>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-light mb-4">Quick Links</h4>
              <Link className="btn btn-link" to="/Home">Home</Link>
              <Link className="btn btn-link" to="/About">About Us </Link>
              <Link className="btn btn-link" to="/Whyus">Why Us</Link>
              <Link className="btn btn-link" to="/Services">Services</Link>
              <Link className="btn btn-link" to="/Industries">Industries</Link>
              <Link className="btn btn-link" to="/Faqs">FAQ's</Link>
              <Link className="btn btn-link" to="/Contact">Contact</Link>
              <Link className="btn btn-link" to="/Careers">Careers</Link>
              <Link className="btn btn-link" to="/Getquote">Request a Quote</Link>
              <Link className="btn btn-link" to="/Track">Tracking</Link>

             
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-light mb-4">Our Services</h4>
              <Link to="/Sea" className="btn btn-link">Sea Freight</Link>
                  <Link to="/Air" className="btn btn-link">Air Freight</Link>
                  <Link to="/LCL" className="btn btn-link">LCL Consolidation</Link>
                  <Link to="/Custom" className="btn btn-link">Custom Clearance</Link>
                  <Link to="/Local" className="btn btn-link">Local Transportation</Link>
                  <Link to="/Special" className="btn btn-link">Special Equipments</Link>
                  <Link to="/Hazardous" className="btn btn-link">Hazardous Materials</Link>
                  <Link to="/Warehousing" className="btn btn-link">Warehousing and Distribution</Link>
                  <Link to="/Bonded" className="btn btn-link">Bonded & Warehouse Storage</Link>

             
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-light mb-4">Newsletter</h4>
              <p>Subscribe to our Newsletter to get Latest Updates..</p>
              <div className="position-relative mx-auto" style={{maxWidth: '400px'}}>
              <form
                        className="cta-input-arae"
                        action="/php/thankyou-subscribe.php "
                        method="POST"
                        onSubmit={(event) => handleSubmit(event)}
                      >
                        <input
                          onChange={(event) => handleSubemailChange(event)}
                          id="subemail"
                          value={subemail}
                          type="email"
                          name="subemail"
                          placeholder="Your email address"
                          required
                          className="form-control bg-transparent w-100 py-3 ps-4 pe-5"
                        />
                        <button
                          className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
                          type="submit"
                          name="submit"
                        >
                          Subscribe Now
                        </button>
                      </form>
                      <h1>{result}</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                © <Link to="/">{companyname}</Link>, All Right Reserved.
              </div>
              <div className="col-md-6 text-center text-md-end">
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
