import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            High Tech
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                High Tech
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-12">
            <div className="mb-5">
              <div className="text-center" style={{ padding: "10px 80px" }}>
                <h1 className="mb-4">High Tech</h1>
                <img
                  className="img-fluid w-100 mb-5 "
                  src="img/High Tech.jpg"
                  alt=""
                />
              </div>

              <p>
              {companyname} stands as a pioneer in devising and
                implementing distinctive, customized Hi Tech logistics solutions
                meticulously tailored to meet the intricate and demanding
                shipping and transportation requirements you encounter. Our
                profound understanding of the challenges presented by the
                high-tech freight and logistics landscape positions us as the
                ideal partner for addressing your needs.
              </p>
              <p>
                The high-tech industry is a realm characterized by its exacting
                demands, innovative spirit, and the presence of high-value
                commodities. Within this dynamic arena, {companyname} emerges as a responsive ally, equipped to navigate the
                intricacies of high-tech logistics. We provide adaptable and
                secure solutions that traverse the entirety of your high-tech
                logistical endeavors. From component provisioning through
                Vendor-Managed Inventory (VMI) to end-of-life recycling, our
                support spans the entire spectrum of the product value chain.
              </p>
              <p>
                Our commitment extends beyond mere logistics – we facilitate
                direct deliveries to resellers and end users, ensuring seamless
                order management across diverse sales channels. The essence of
                our approach in high-tech logistics is expediting
                speed-to-market and ensuring comprehensive track & trace
                capabilities that align with our customers' unique demands.
                Through each phase of the product life cycle, we remain
                steadfast in our goal to optimize efficiency and deliver
                unparalleled track & trace solutions within the high-tech
                logistics domain.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
