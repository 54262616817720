import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid "
        style={{
          backgroundImage: 'url("img/banner.jpg")',
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          padding: "70px",
        }}
      >
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="display-4 text-white">Mining</h1>
            <Link to="/Home">Home</Link>
            <i className="far fa-square text-primary px-2" />
            <Link href>Mining</Link>
          </div>
        </div>
      </div>
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-12">
            <div className="mb-5">
              <div className="text-center" style={{ padding: "10px 80px" }}>
                <h1 className="mb-4">Mining</h1>
                <img
                  className="img-fluid w-100 mb-5 "
                  src="img/Mining.jpg"
                  alt=""
                />
              </div>

              <p>
                Our extensive expertise in mining operations and projects has
                endowed us with a profound understanding of our customers'
                needs. This understanding has cultivated a sense of urgency and
                precision within our team, enabling us to consistently meet the
                exacting demands of the industry.
              </p>

              <p>
                We prioritize several key factors in our approach to mining
                logistics:
              </p>

              <ul>
                <li>
                  <p>
                    High Quality and Safety Standards: Ensuring the highest
                    quality and safety standards are integral to our operations.
                    We are committed to upholding the strictest quality and
                    safety measures, safeguarding both the well-being of our
                    personnel and the integrity of your mining operations.
                  </p>
                </li>

                <li>
                  <p>
                    Innovation for Supply Chain Efficiency: Innovation is at the
                    core of our efforts to enhance the efficiency of your supply
                    chain. We continually explore innovative solutions and
                    technologies that streamline logistics, minimize downtime,
                    and optimize resource allocation.
                  </p>
                </li>

                <li>
                  <p>
                    Environmental, Social, and Legal Compliance: We recognize
                    the paramount importance of compliance with environmental,
                    social, and legal standards. Our operations are conducted
                    with the utmost respect for the environment, local
                    communities, and the law. We strive to maintain a harmonious
                    balance between your mining endeavors and the broader
                    ecosystem.
                  </p>
                </li>
              </ul>

              <p>
                With our unwavering commitment to these principles, we are not
                only a logistics partner but a trusted ally in ensuring the
                success and sustainability of your mining ventures.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
