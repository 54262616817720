import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Apparel
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Apparel
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-12">
            <div className="mb-5">
              <div className="text-center" style={{ padding: "10px 80px" }}>
                <h1 className="mb-4">Apparel</h1>
                <img
                  className="img-fluid w-100 mb-5 "
                  src="img/Apparel.jpg"
                  alt=""
                />
              </div>

              <p>
                Within the garment industry, the importance of a robust supply
                chain management strategy cannot be emphasized enough. The
                intricate coordination of supply chain activities plays a
                crucial role in achieving success in this sector. Senior
                management recognizes this fundamental reality and prioritizes
                the development of a strong supply chain management system.
              </p>
              <p>
                At our organization, the intricate process of resource
                management involves the seamless movement of vital components
                from one point to another. This coordination spans across
                various parties, departments, and geographic locations. The core
                of our approach is to ensure the availability of resources in
                the right quantities precisely when they are needed. Achieving
                this balance involves minimizing process costs while maximizing
                efficiency.
              </p>
              <p>
                Unlike our proactive approach, many logistics companies face
                challenges that they acknowledge but struggle to effectively
                address. It is essential for organizations to carefully plan
                their supply networks, as the success of the garment industry
                relies heavily on the precise execution of supply chain
                management plans. In this ever-evolving landscape, a
                well-crafted supply chain framework can serve as the key not
                only to resolve existing issues but also to drive the entire
                garment business toward triumph.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
