import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Local Transportation
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Local Transportation
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-4">
            <div className="mb-5">
              <h2 className="mb-4">Other Services</h2>
              <div className="d-flex flex-column justify-content-start bg-secondary p-4">
                <Link className="h5 mb-3" to="/Sea">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Sea Freight
                </Link>
                <Link className="h5 mb-3" to="/Air">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Air Freight
                </Link>
                <Link className="h5 mb-3" to="/LCL">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  LCL Consolidation
                </Link>
                <Link className="h5 mb-3" to="/Custom">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Custom Clearance
                </Link>
                <Link className="h5 mb-3" to="/Local">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Local Transportation
                </Link>
                <Link className="h5 mb-3" to="/Special">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Special Equipments
                </Link>
                <Link className="h5 mb-3" to="/Hazardous">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Hazardous Materials
                </Link>
                <Link className="h5 mb-3" to="/Warehousing">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Warehousing and Distribution
                </Link>
                <Link className="h5 mb-3" to="/Bonded">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Bonded & Warehouse Storage
                </Link>
              </div>
            </div>

            {/* Plain Text End */}
          </div>
          <div className="col-lg-8">
            {/* Blog Detail Start */}
            <div className="mb-5">
              <img
                className="img-fluid w-100 mb-5"
                src="img/Local Transportation.jpg"
                alt=""
              />
              <h1 className="mb-4">Local Transportation</h1>
              <p>
                When you talk about Transport and Logistics Company Services,
                they have different sets of operations, but these two work
                effectively when combined. Logistics refers to supervising the
                entire process of the flow of goods from the manufacturer to the
                consumer. The flow or movement of these products is termed
                transportation.
              </p>

              <h2>WHAT IS TRANSPORTATION?</h2>
              <p>
                The movement of goods and products from one place to another
                through air, ocean, rail, and road is referred to as
                transportation. Transportation helps in acquiring and delivering
                goods from the manufacturer to the end-users. It makes the
                communication, import, and export between two parties flawless
                and immaculate. Transportation includes three aspects that help
                in making it better: structure, process, and trucks.
              </p>

              <h2>What is Logistics?</h2>
              <p>
                Logistics involves all the processes from obtaining to
                manufacturing to distributing products and materials to the
                final consumers in the right quantity. The transportation and
                storage of goods are part of logistics. Moreover, it requires
                proper planning, management, and implementation procedures for
                effective results. The logistics services check the demands and
                needs of the customers and the number of products needed to
                satisfy them.
              </p>

              <h2>How To Maximise Your Transport and Logistics Services?</h2>
              <p>
                When your business depends on the delivery, distribution, and
                transportation of goods, it is better to understand how you can
                maximize your transport and logistics services. It will help you
                get more successful and keep you up in the competition.
              </p>

              <p>
                The best idea to get the most out of it is by identifying the
                balance between efficiency and rate. You need to calculate the
                weight and quantity of your goods to select the right vehicle
                option for them. The capacity of the vehicle should be as per
                the weight and dimensions of your products. Further, you can
                also choose the transportation suppliers near the products
                storage facility to avoid extra costs. You can opt for{" "}
                <a href="#" target="_blank" rel="noopener">
                  LCL consolidation
                </a>{" "}
                or you Can Say LCL Shipment as well. Moreover, logistics have
                been automated now, and you can track your shipment. It will
                help you anticipate the time of delivery and identify any issues
                related to it.
              </p>

              <h2>What Do Transportation And Logistics Entail?</h2>
              <p>
                Transportation of goods and taking care of the logistics is part
                of a more critical process. You need to complete some procedures
                before and after transportation. Logistics helps you plan and
                manage these operations. These processes include:
              </p>

              <ul>
                <li>Packaging and moving of products</li>
                <li>The storage facility, such as warehousing</li>
                <li>Documentation and approvals</li>
                <li>Following regulatory rules</li>
                <li>Communication between the owner and the trader</li>
                <li>Using a freight option</li>
              </ul>

              <p>
                Businesses have a choice to hire services for transportation
                while handling logistics themselves. However, if you outsource
                both the transportation and logistics services, it will save
                your time and money, along with the stress and hassle related to
                it.
              </p>

              <p>
                Trust Walkers Logistics With Transport And Logistics Services
              </p>
              <p>
                Walkers Logistics Pte Ltd can be categorized amongst the best
                transport and logistics services in Singapore. We offer seamless
                transportation services and freight options to the customers.
                Our trucking operations are smooth, reliable, and secure. We
                handle all the logistics and provide excellent and complex
                logistics solutions to our customers. Whether you need to
                transport goods in bulk or haul fragile items, our trucking will
                make the work easy and as per your requirements. Our transport
                and logistics solutions are available at affordable rates with
                complete support.
              </p>
            </div>
          </div>
          {/* Sidebar Start */}

          {/* Sidebar End */}
        </div>
      </div>

      <Footer />
    </>
  );
}
