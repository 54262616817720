import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Industrial
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Industrial
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-12">
            <div className="mb-5">
              <div className="text-center" style={{ padding: "10px 80px" }}>
                <h1 className="mb-4">Industrial</h1>
                <img
                  className="img-fluid w-100 mb-5 "
                  src="img/Industrial.jpg"
                  alt=""
                />
              </div>

              <p>
                Meeting your objectives requires not just competence, but a
                mastery of technical skills, an impeccable strategic approach,
                and an unwavering commitment to precision. Success in your
                endeavors hinges on meticulous execution and vigilant
                monitoring.
              </p>
              <p>
                In response to the escalating demands of the global market, air
                freight has risen to prominence as an indispensable facet of
                effective supply chain management.
              </p>
              <p>
                Beyond just integrated freight and warehousing services, Hong
                Kong is dedicated to offering its clientele an all-encompassing,
                one-stop-shop end-to-end solution. This commitment revolves
                around affordability, exceptional quality, personalized
                customization, and adaptable flexibility. The primary aim is to
                foster seamless information flow and amplify the efficiency of
                logistics. This holistic solution doesn't solely yield tangible,
                quantitative benefits for the bottom line; it also delivers
                intangible advantages by enhancing customer satisfaction through
                swifter response times and reduced supply chain cycle durations.
              </p>
              <p>Our Comprehensive Offerings Include:</p>
              <ul>
                <li>Logistics Diagnosis</li>
                <li>Process Mapping and Re-engineering</li>
                <li>
                  Tailor-Made Integrated Logistics Solutions by Local and Group
                  Experts, Encompassing Contingency Planning
                </li>
                <li>
                  Centralized Coordination, Collaborating with Sub-contractors
                  Embedded within the Solution Framework
                </li>
                <li>Consultancy</li>
              </ul>
              <p>
                At its core, this approach is not just about offering services –
                it's about architecting solutions that holistically address the
                intricate landscape of modern logistics. By leveraging local and
                group expertise, we are equipped to guide your operations
                through strategic navigation, ultimately optimizing your
                logistics journey.
              </p>
              <p>
                We are a pioneering force in the logistics realm, offering an
                all-encompassing range of sustainable and multimodal
                transportation solutions meticulously tailored to combat the
                intricacies of your supply chain challenges.
              </p>
              <p>
                Our dedication extends to optimizing your supply flows,
                distribution networks, and after-sales services. With an adept
                understanding of the diverse landscapes your projects navigate,
                we expertly mold our solutions to fit each unique endeavor—no
                matter how intricate or daunting. The hallmark of our approach
                lies in customizing solutions that resonate with your specific
                requirements.
              </p>
              <p>
                Leveraging an intimate familiarity with your industry and this
                finely-tailored approach, we orchestrate your logistics in a
                manner that maximizes efficiency and economy. At {companyname}, we understand that excellence isn't a
                one-size-fits-all concept; it's about crafting strategies that
                seamlessly align with your vision, ensuring not just logistics,
                but a trajectory toward success.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
