import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
          Automotive
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Automotive
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-12">
            <div className="mb-5">
              <div className="text-center" style={{ padding: "10px 80px" }}>
                <h1 className="mb-4">Automotive</h1>
                <img
                  className="img-fluid w-100 mb-5 "
                  src="img/Automotive.jpg"
                  alt=""
                />
              </div>

              <p>The automobile industry is a realm riddled with complex challenges, especially concerning support models and provisions. Particularly in a landscape where manufacturing centers span multiple companies and international borders, alongside intricate in-line production stages, meticulous temporal planning becomes an indispensable element. These complexities are intricately woven into the fabric of the automobile sector, and we, as a diligent player in the field, are acutely cognizant of these challenges and steadfastly committed to addressing them through transparent and effective communication.</p>
<p>Our focus is unwaveringly centered on offering robust business support, liberating your bandwidth to excel in your core competencies. Within the sphere of automotive supply, we pride ourselves on harboring the industry's finest talent, fortified by a truly global presence. Our adept service team and a well-knit communication network have adeptly navigated an array of challenges - spanning from strategic location selection to the nuanced demands of the world's largest automotive giants.</p>
<p>The efficacy of our management structure, combined with our expansive global network, ensures that accountability is a constant. From our industry experts who dwell within the business intricacies to the adept hands that manage operations on the warehouse floor, you can rest assured that you're in capable hands. With us as your partner, the automotive industry's multifaceted challenges are met with an unwavering commitment to precision, efficiency, and excellence.</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
