import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 breadcrumb-1">
              <h1 className="title">Multimodal Logistics </h1>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="bread-tag">
                <Link to="/Home">Home</Link>
                <span> / </span>
                <Link to="#" className="active">
                  Multimodal Logistics
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="rts-blog-list-area rts-section-gap">
        <div className="container">
          <div className="row g-5">
            {/* rts blo post area */}
            <div className="col-xl-8 col-md-12 col-sm-12 col-12">
              {/* single post */}
              <div className="blog-single-post-listing details mb--0">
                <div className="thumbnail">
                  <img
                    src="assets/images/Multimodal Logistics.jpg"
                    alt="Business-Blog"
                  />
                </div>
                <div className="blog-listing-content">
                  <h3 className="title">Multimodal Logistics</h3>
                  <p className="disc para-1">
                    At {companyname}, we recognize the diverse needs of
                    shipments that demand various modes of transportation for
                    their successful journey to the final destination. Serving
                    as a comprehensive Total Transportation Solutions provider,
                    we extend multi-modal logistics services that combine
                    distinct shipping modes to achieve efficient and
                    cost-effective delivery of your goods right to your
                    doorstep.
                    <br />
                    Leveraging our deep-rooted expertise in logistics and
                    transportation, we adeptly harmonize different
                    transportation modes, such as air-sea, air-land, sea-land,
                    and more, tailored precisely to the requisites of your
                    shipments. This multi-modal strategy empowers us to optimize
                    the transportation process, determining the most suitable
                    amalgamation of routes and carriers for your merchandise.
                    <br />
                    Our forte lies in inter-modal transportation, presenting an
                    innovative and budget-friendly solution for select product
                    lines. By capitalizing on the strengths of diverse
                    transportation modes, we heighten the efficiency and
                    reliability of your supply chain. Whether it entails the
                    fusion of air and sea freight or the seamless transition
                    from sea to land transportation, our multi-modal logistics
                    methodology guarantees the seamless and punctual delivery of
                    your cargo.
                    <br />
                    We take immense pride in our exceptional service and
                    delivery framework. Our team of adept logistics
                    professionals meticulously orchestrates and coordinates
                    every facet of the transportation process, harnessing our
                    sprawling network of partners and resources. Rooted in our
                    commitment to quality and customer contentment, we strive to
                    surpass your expectations concerning service dependability,
                    transit timelines, and holistic efficiency.
                    <br />
                    When you opt for {companyname} to cater to your multi-modal
                    logistics requirements, you are poised to benefit from a
                    comprehensive and unified transportation solution. We
                    oversee every aspect of the logistics journey, encompassing
                    documentation, customs clearance, tracking, and final
                    delivery. Our objective is to bestow upon you an untroubled
                    and streamlined experience, allowing you to channel your
                    energies into your core business while we deftly manage your
                    transportation demands.
                    <br />
                    Confide in {companyname} to expeditiously and economically
                    dispatch your merchandise through our innovative multi-modal
                    logistics services. Our commitment is directed at unearthing
                    the ultimate transportation solution for your shipments,
                    ensuring their prompt arrival at their intended destination
                    while optimizing cost efficiencies. Permit us to stand as
                    your trusted companion for all your multi-modal logistics
                    prerequisites.
                  </p>
                </div>
              </div>
              {/* single post End*/}
            </div>
            {/* rts-blog post end area */}
            {/*rts blog wizered area */}
            <div className="col-xl-4 col-md-12 col-sm-12 col-12">
              <div className="rts-single-wized Recent-post">
                <div className="wized-header">
                  <h5 className="title">Other Services</h5>
                </div>
                <div className="wized-body">
                  {/* recent-post */}
                  <div className="recent-post-single">
                    <div className="thumbnail">
                      <Link to="/Road">
                        <img
                          src="assets/images/Road Freight.jpg"
                          alt="Blog_post"
                          style={{ width: "90px" }}
                        />
                      </Link>
                    </div>
                    <div className="content-area">
                      <Link className="post-title" to="/Road">
                        <h6 className="">Road Freight</h6>
                      </Link>
                    </div>
                  </div>
                  <div className="recent-post-single">
                    <div className="thumbnail">
                      <Link to="/Ocean">
                        <img
                          src="assets/images/Ocean Freight.jpg"
                          alt="Blog_post"
                          style={{ width: "90px" }}
                        />
                      </Link>
                    </div>
                    <div className="content-area">
                      <Link className="post-title" to="/Ocean">
                        <h6 className="">Ocean Freight</h6>
                      </Link>
                    </div>
                  </div>
                  <div className="recent-post-single">
                    <div className="thumbnail">
                      <Link to="/Air">
                        <img
                          src="assets/images/Air Freight.jpg"
                          alt="Blog_post"
                          style={{ width: "90px" }}
                        />
                      </Link>
                    </div>
                    <div className="content-area">
                      <Link className="post-title" to="/Air">
                        <h6 className="">Air Freight</h6>
                      </Link>
                    </div>
                  </div>
                  <div className="recent-post-single">
                    <div className="thumbnail">
                      <Link to="/Customs">
                        <img
                          src="assets/images/Customs Compliance.jpg"
                          alt="Blog_post"
                          style={{ width: "90px" }}
                        />
                      </Link>
                    </div>
                    <div className="content-area">
                      <Link className="post-title" to="/Customs">
                        <h6 className="">Customs Compliance</h6>
                      </Link>
                    </div>
                  </div>
                  <div className="recent-post-single">
                    <div className="thumbnail">
                      <Link to="/Contract">
                        <img
                          src="assets/images/Contract Logistics.jpg"
                          alt="Blog_post"
                          style={{ width: "90px" }}
                        />
                      </Link>
                    </div>
                    <div className="content-area">
                      <Link className="post-title" to="/Contract">
                        <h6 className="">Contract Logistics</h6>
                      </Link>
                    </div>
                  </div>
                  <div className="recent-post-single">
                    <div className="thumbnail">
                      <Link to="/Material">
                        <img
                          src="assets/images/Material Handling.jpg"
                          alt="Blog_post"
                          style={{ width: "90px" }}
                        />
                      </Link>
                    </div>
                    <div className="content-area">
                      <Link className="post-title" to="/Material">
                        <h6 className="">Material Handling</h6>
                      </Link>
                    </div>
                  </div>
                  <div className="recent-post-single">
                    <div className="thumbnail">
                      <Link to="/Multimodal">
                        <img
                          src="assets/images/Multimodal Logistics.jpg"
                          alt="Blog_post"
                          style={{ width: "90px" }}
                        />
                      </Link>
                    </div>
                    <div className="content-area">
                      <Link className="post-title" to="/Multimodal">
                        <h6 className="">Multimodal Logistics</h6>
                      </Link>
                    </div>
                  </div>
                  <div className="recent-post-single">
                    <div className="thumbnail">
                      <Link to="/Project">
                        <img
                          src="assets/images/Project Logistics.jpg"
                          alt="Blog_post"
                          style={{ width: "90px" }}
                        />
                      </Link>
                    </div>
                    <div className="content-area">
                      <Link className="post-title" to="/Project">
                        <h6 className="">Project Logistics</h6>
                      </Link>
                    </div>
                  </div>
                  <div className="recent-post-single">
                    <div className="thumbnail">
                      <Link to="/Warehousing">
                        <img
                          src="assets/images/Warehousing & Distribution.jpg"
                          alt="Blog_post"
                          style={{width:"90px"}}
                        />
                      </Link>
                    </div>
                    <div className="content-area">
                    
                      <Link className="post-title" to="/Warehousing">
                        <h6 className="">
                        Warehousing & Distribution
                        </h6>
                      </Link>
                    </div>
                  </div>

                  {/* recent-post End */}
                </div>
              </div>
              <div className="rts-single-wized Categories">
                <div className="wized-header">
                  <h5 className="title">Industries </h5>
                </div>
                <div className="wized-body">
                  {/* single categoris */}
                  <ul className="single-categories">
                    <li>
                      <Link to="/Chemical ">
                        Chemical
                        <i className="far fa-long-arrow-right" />
                      </Link>
                    </li>
                  </ul>

                  <ul className="single-categories">
                    <li>
                      <Link to="/Consumer">
                        Consumer Electronics
                        <i className="far fa-long-arrow-right" />
                      </Link>
                    </li>
                  </ul>
                  <ul className="single-categories">
                    <li>
                      <Link to="/Fashion">
                        Fashion
                        <i className="far fa-long-arrow-right" />
                      </Link>
                    </li>
                  </ul>
                  <ul className="single-categories">
                    <li>
                      <Link to="/Food">
                        Food
                        <i className="far fa-long-arrow-right" />
                      </Link>
                    </li>
                  </ul>
                  <ul className="single-categories">
                    <li>
                      <Link to="/Furniture">
                        Furniture
                        <i className="far fa-long-arrow-right" />
                      </Link>
                    </li>
                  </ul>
                  <ul className="single-categories">
                    <li>
                      <Link to="/Mining">
                        Mining
                        <i className="far fa-long-arrow-right" />
                      </Link>
                    </li>
                  </ul>
                  <ul className="single-categories">
                    <li>
                      <Link to="/Energy">
                        Renewable Energy
                        <i className="far fa-long-arrow-right" />
                      </Link>
                    </li>
                  </ul>
                  <ul className="single-categories">
                    <li>
                      <Link to="/Retail">
                        Retail
                        <i className="far fa-long-arrow-right" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* single wizered End */}
              {/* single wizered start */}
            </div>
            {/* rts- blog wizered end area */}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
