import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            FAQ's
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                FAQ's
              </li>
            </ol>
          </nav>
        </div>
      </div>

      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-12">
            <div className="mb-5">
              <div className="text-center" style={{ padding: "10px 80px" }}>
                <h1 className="mb-4">Frequently Asked Questions</h1>
              </div>
            </div>
            <div className="border-start border-5 border-primary ps-4 mb-5">
              <h2 className="display-8 mb-0">
                How and where can we pick up the cargo at destination?
              </h2>
              <h6 className="text-body text-uppercase mb-2">
                We will provide you with the necessary paperwork for custom
                clearance, once custom is done, you can go to the warehouse to
                pick up the cargo. We also advise you to check with destination
                custom prior to shipment departs, just to make sure if there’s
                any special rule & regulation at destination for certain
                commodities
              </h6>
            </div>
            <div className="border-start border-5 border-primary ps-4 mb-5">
              <h2 className="display-8 mb-0">
                Do you handle international household moves?
              </h2>
              <h6 className="text-body text-uppercase mb-2">
                We will provide you with the necessary paperwork for custom
                clearance, once custom is done, you can go to the warehouse to
                pick up the cargo. Yes. However we are not an international
                moving company. We are an international freight forwarding
                company. We can help you ship but we can’t help you load. An
                empty container can be delivered to your premises and you will
                be responsible for loading it properly.
              </h6>
            </div>
            <div className="border-start border-5 border-primary ps-4 mb-5">
              <h2 className="display-8 mb-0">
              Can I get payment terms?
              </h2>
              <h6 className="text-body text-uppercase mb-2">
             
Yes. You would fill out a credit application that we would send you ahead of time, with all necessary banking information and references. Once your credit is approved, you would be granted the appropriate credit amount and length of time.
              </h6>
            </div>
            <div className="border-start border-5 border-primary ps-4 mb-5">
              <h2 className="display-8 mb-0">
              What are the usual methods of freight payment?

              </h2>
              <h6 className="text-body text-uppercase mb-2">
              Most freight payments are done with a Company cheque. However you can also pay with telegraphic transfer. Payment is sent right around the time the freight is due to arrive, clear customs and be released.
              </h6>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
