import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Why Choose Us
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Why Choose Us
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="border-start border-5 border-primary ps-4 mb-5">
                <h6 className="text-body text-uppercase mb-2">
                  Why Choose Us!
                </h6>
                <h1 className="display-6 mb-0">
                  Warehouse Company in Singapore
                </h1>
              </div>
              <p className="mb-5">
                At Walkers Logistics Pte Ltd, you will get the best warehousing
                solutions and services as per your need. With our warehouse in
                Singapore, we try to facilitate all our customers by storing
                their products safely and helping them reach the final
                consumers. As a warehouse company in Singapore, we assist the
                manufacturers, suppliers, and business owners in getting a
                storage facility at the most affordable rates and giving them
                access to transportation and goods distribution. Contact us for
                effective and efficient solutions and storage services!
              </p>
              <div className="row gy-5 gx-4">
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                  <div className="d-flex align-items-center mb-3">
                    <i className="fa fa-check fa-2x text-primary flex-shrink-0 me-3" />
                    <h6 className="mb-0">Network</h6>
                  </div>
                </div>
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                  <div className="d-flex align-items-center mb-3">
                    <i className="fa fa-check fa-2x text-primary flex-shrink-0 me-3" />
                    <h6 className="mb-0">Warehouse Storage Facility</h6>
                  </div>
                </div>
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                  <div className="d-flex align-items-center mb-3">
                    <i className="fa fa-check fa-2x text-primary flex-shrink-0 me-3" />
                    <h6 className="mb-0">Various Freight Options</h6>
                  </div>
                </div>
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                  <div className="d-flex align-items-center mb-3">
                    <i className="fa fa-check fa-2x text-primary flex-shrink-0 me-3" />
                    <h6 className="mb-0">Customer Satisfaction</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div
                className="position-relative overflow-hidden ps-5 pt-5 h-100"
                style={{ minHeight: "400px" }}
              >
                <img
                  className="position-absolute w-100 h-100"
                  src="img/About2.jpg"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
                <div
                  className="position-absolute top-0 start-0 bg-white pe-3 pb-3"
                  style={{ width: "200px", height: "200px" }}
                >
                  <div className="d-flex flex-column justify-content-center text-center bg-primary h-100 p-3">
                    <h5 className="text-white mb-0">{companyname}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-end mb-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="border-start border-5 border-primary ps-4">
                <h6 className="text-body text-uppercase mb-2">Our Industry Solutions</h6>
                <h1 className="display-6 mb-0">On Time, On Track, On Target.</h1>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <p className="mb-0">
                Our company is committed to meeting deadlines, maintaining shipment integrity, and achieving clients' objectives with precision. It's a concise and powerful slogan that can inspire trust and confidence in your services.
              </p>
            </div>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            ><Link to="/Apparel">
              <div className="team-item position-relative">
                <img className="img-fluid" src="img/Apparel.jpg" alt="" />
                <div className="team-text bg-white p-4">
                  <h5>Apparel</h5>
                  <span>Industries</span>
                </div>
              </div></Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            ><Link to="/Healthcare">
              <div className="team-item position-relative">
                <img className="img-fluid" src="img/Healthcare.jpg" alt="" />
                <div className="team-text bg-white p-4">
                  <h5>Healthcare</h5>
                  <span>Industries</span>
                </div>
              </div></Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            ><Link to="/Food">
              <div className="team-item position-relative">
                <img className="img-fluid" src="img/Food & Beverages.jpg" alt="" />
                <div className="team-text bg-white p-4">
                  <h5>Food & Beverages</h5>
                  <span>Industries</span>
                </div>
              </div></Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            ><Link to="/Energy">
              <div className="team-item position-relative">
                <img className="img-fluid" src="img/Energy.jpg" alt="" />
                <div className="team-text bg-white p-4">
                  <h5>Energy</h5>
                  <span>Industries</span>
                </div>
              </div></Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            ><Link to="/Automotive">
              <div className="team-item position-relative">
                <img className="img-fluid" src="img/Automotive.jpg" alt="" />
                <div className="team-text bg-white p-4">
                  <h5>Automotive</h5>
                  <span>Industries</span>
                </div>
              </div></Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            ><Link to="/Electronics">
              <div className="team-item position-relative">
                <img className="img-fluid" src="img/Electronics.jpg" alt="" />
                <div className="team-text bg-white p-4">
                  <h5>Electronics</h5>
                  <span>Industries</span>
                </div>
              </div></Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            ><Link to="/Retail">
              <div className="team-item position-relative">
                <img className="img-fluid" src="img/Retail.jpg" alt="" />
                <div className="team-text bg-white p-4">
                  <h5>Retail</h5>
                  <span>Industries</span>
                </div>
              </div></Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            ><Link to="/High">
              <div className="team-item position-relative">
                <img className="img-fluid" src="img/High Tech.jpg" alt="" />
                <div className="team-text bg-white p-4">
                  <h5>High Tech</h5>
                  <span>Industries</span>
                </div>
              </div></Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            ><Link to="/Industrial">
              <div className="team-item position-relative">
                <img className="img-fluid" src="img/Industrial.jpg" alt="" />
                <div className="team-text bg-white p-4">
                  <h5>Industrial</h5>
                  <span>Industries</span>
                </div>
              </div></Link>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
