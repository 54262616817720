import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Container from "./pages/Container";
import Air from "./pages/Air";
import Sea from "./pages/Sea";
import Railway from "./pages/Railway";

import Getquote from "./pages/Getquote";
import About from "./pages/About";
import Services from "./pages/Services";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Industrial from "./pages/Industrial";
import Healthcare from "./pages/Healthcare";
import LCL from "./pages/LCL";
import Food from "./pages/Food";
import Mining from "./pages/Mining";

import Electronics from "./pages/Electronics";
import Track from "./pages/Track";
import Trace from "./pages/Trace";
import Road from "./pages/Road";
import Special from "./pages/Special";
import Automotive from "./pages/Automotive";
import High from "./pages/High";
import Hazardous from "./pages/Hazardous";
import Chemical from "./pages/Chemical";
import Warehousing from "./pages/Warehousing";
import Bonded from "./pages/Bonded";
import Apparel from "./pages/Apparel";
import Retail from "./pages/Retail";
import Contract from "./pages/Contract";
import Energy from "./pages/Energy";
import Whyus from "./pages/Whyus";
import Careers from "./pages/Careers";
import Incoterms from "./pages/Incoterms";
import Distance from "./pages/Distance";
import Metric from "./pages/Metric";
import Custom from "./pages/Custom";
import Local from "./pages/Local";
import Missionvision from "./pages/Missionvision";
import Faqs from "./pages/Faqs";
import Industries from "./pages/Industries";
import Requestquote from "./pages/Requestquote";
import Multimodal from "./pages/Multimodal";
import Oil from "./pages/Oil";

export default function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/Industrial" element={<Industrial />} />
          <Route path="/Multimodal" element={<Multimodal />} />
          <Route path="/Oil" element={<Oil />} />
          <Route path="/" element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Container" element={<Container />} />
          <Route path="/Air" element={<Air />} />
          <Route path="/Custom" element={<Custom />} />
          <Route path="/Sea" element={<Sea />} />
          <Route path="/Local" element={<Local />} />
          <Route path="/Requestquote" element={<Requestquote />} />
          <Route path="/Railway" element={<Railway />} />
          <Route path="/Getquote" element={<Getquote />} />
          <Route path="/About" element={<About />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Road" element={<Road />} />
          <Route path="/Privacy" element={<Privacy />} />
          <Route path="/Terms" element={<Terms />} />
          <Route path="/Healthcare" element={<Healthcare />} />
          <Route path="/LCL" element={<LCL />} />
          <Route path="/Food" element={<Food />} />
          <Route path="/Mining" element={<Mining />} />
          <Route path="/Electronics" element={<Electronics />} />
          <Route path="/Track" element={<Track />} />
          <Route path="/Trace" element={<Trace />} />
          <Route path="/Special" element={<Special />} />
          <Route path="/Automotive" element={<Automotive />} />
          <Route path="/High" element={<High />} />
          <Route path="/Hazardous" element={<Hazardous />} />
          <Route path="/Chemical" element={<Chemical />} />
          <Route path="/Warehousing" element={<Warehousing />} />
          <Route path="/Bonded" element={<Bonded />} />
          <Route path="/Apparel" element={<Apparel />} />
          <Route path="/Retail" element={<Retail />} />
          <Route path="/Contract" element={<Contract />} />
          <Route path="/Energy" element={<Energy />} />
          <Route path="/Whyus" element={<Whyus />} />
          <Route path="/Careers" element={<Careers />} />
          <Route path="/Missionvision" element={<Missionvision />} />
          <Route path="/Incoterms" element={<Incoterms />} />
          <Route path="/Metric" element={<Metric />} />
          <Route path="/Distance" element={<Distance />} />
          <Route path="/Faqs" element={<Faqs />} />
          <Route path="/Industries" element={<Industries />} />
        </Routes>
      </Router>
    </div>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
