import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import jsPDF from "jspdf";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import axios from "axios";
import "jspdf-autotable";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [trackingNo, setTrackingNo] = useState("");
  const [items, setItems] = useState([]);
  const [notFound, setNotFound] = useState(false);

  const generatePDF = () => {
    const doc = new jsPDF();

    const pageTitle = "walkerslogistics.com.sg/Track";
    const textWidth = doc.getTextWidth(pageTitle);
    const pageWidth = doc.internal.pageSize.getWidth();
    const xPos = pageWidth - textWidth;

    const logoWidth = 45;
    const logoHeight = 15;

    doc.setFont("Arial", "", 8);

    const headers = [
      "Container No",
      "Container Type",
      "Vessel Voyage",
      "BL No",
      "Origin",
      "Destination",
      "Departure Time",
      "Arrival Time",
    ];
    const data = items.map((item) => [
      item.containerNo,
      item.equipmentType,
      item.vesselVoyage,
      item.blNo,
      item.portOfLoading,
      item.portOfDischarge,
      item.exTimeOfDeparture,
      item.exTimeArrival,
    ]);

    const tableOptions = {
      startY: 50, // Adjusted startY to accommodate the header content
      headStyles: {
        fillColor: [15, 23, 135],
        textColor: [255, 255, 255],
      },
      styles: { textColor: [0, 0, 0], fontSize: 10 },

      didDrawPage: function (data) {
        // Footer content
        const pageCount = doc.internal.getNumberOfPages();
        const currentPage = doc.internal.getCurrentPageInfo().pageNumber;
        const formattedDate = new Date().toLocaleDateString();
        const formattedTime = new Date().toLocaleTimeString();

        const footerText = `Page ${currentPage}  | Date: ${formattedDate} | Time: ${formattedTime}`;
        const footerX = doc.internal.pageSize.getWidth() / 2;
        const footerY = doc.internal.pageSize.getHeight() - 10;
        doc.text(footerText, footerX, footerY, { align: "center" });
      },
    };

    // Header content
    doc.setFont("Arial", "B", 18);
    doc.text("WALKERS LOGISTICS PTE LTD SHIPMENT DETAILS", 105, 45, {
      align: "center",
    });

    // Logo
    doc.addImage("img/logo.png", "PNG", 20, 7, logoWidth, logoHeight);

    // Title
    doc.setFont("Arial", "", 8);
    doc.setFontSize(10);
    doc.text(pageTitle, 137, 20);
    const imageCompressionOptions = {
      quality: 0.7, // Adjust this value to control image quality
    };
    // Generate the table using autoTable function with the defined options
    doc.autoTable(headers, data, tableOptions);

    doc.save("shipment-details.pdf");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const submitType = e.target.name; // Get the form name (e.g., "submit1")
    axios
      .get(
        `https://sgserver.net/shipmenttracking/index.php?trackingNo=${trackingNo}&submitType=${submitType}`
      )
      .then((response) => {
        if (response.data.length > 0) {
          setItems(response.data);
          setNotFound(false);
        } else {
          setItems([]);
          setNotFound(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Track Shipment
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Track Shipment
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* Contact Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.5s">
              <div className="border-start border-5 border-primary ps-4 mb-5">
                <h6 className="text-body text-uppercase mb-2">
                  Track Your Shipment
                </h6>
                <h1 className="display-6 mb-0">
                  Track and Trace Made Easy - Follow Your Shipment's Journey!
                </h1>
              </div>
              <form onSubmit={handleSubmit} name="walkerslogistics ">
                <div className="row g-3">
                  <div className=" col-lg-6">
                    <input
                      type="text"
                      value={trackingNo}
                      onChange={(e) => setTrackingNo(e.target.value)}
                      placeholder="Enter BL No. or Container No."
                      className="form-control w-100 py-3"
                    />
                    <span />
                  </div>

                  <div className=" col-lg-6  text-lg-center">
                    <button
                      name="submit"
                      className="btn btn-primary w-100 py-3"
                      type="submit"
                    >
                      Track Shipment
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="table-wrapper mt-4">
            {items.length > 0 && (
              <table class="responsive-table">
                <thead>
                  <tr>
                    <th scope="col">Container No</th>
                    <th scope="col">Type</th>
                    <th scope="col"> Port Of Loading</th>
                    <th scope="col">Port Of Destination</th>
                    <th scope="col">Estimated Departure Time</th>
                    <th scope="col">Estimated Arrival Time</th>
                    <th scope="col">Vessel Voyage</th>
                    <th scope="col">Details</th>
                    <th scope="col">Download</th>
                  </tr>
                </thead>
                <tbody>
                  {notFound ? (
                    <tr>
                      <td colSpan="3">No items found with the given name.</td>
                    </tr>
                  ) : (
                    items.map((item) => (
                      <tr key={item.id}>
                        <th scope="row">{item.containerNo}</th>

                        <td data-title="Type">{item.equipmentType}</td>
                        <td data-title="Port Of Loading">
                          {item.portOfLoading}
                        </td>
                        <td data-title="Port Of Destination">
                          {item.portOfDischarge}
                        </td>
                        <td data-title="Estimated Departure Time">
                          {item.exTimeOfDeparture}
                        </td>
                        <td data-title="Estimated Arrival Time">
                          {item.exTimeArrival}
                        </td>
                        <td data-title="Vessel Voyage">{item.vesselVoyage}</td>

                        <td data-title="Details">
                          <Link
                            to={{
                              pathname: `/Trace`,
                              search: `id=${item.blNo}&contid=${item.containerNo}`,
                            }}
                            className="url"
                          >
                            <img
                              src="assets/images/details.png"
                              height="30px"
                            />
                          </Link>
                        </td>
                        <td data-title="Download">
                          <a onClick={generatePDF}>
                            {" "}
                            <img src="assets/images/pdf.png" height="30px" />
                          </a>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            )}

            {items.length === 0 && notFound && (
              <p>
                No Air/Sea Way BL available. Please provide the valid
                Container/BL no. to get the results
              </p>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
