import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="page-title-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 text-center">
              <div className="page-title-content">
                <h3 className="title text-white">Chemical</h3>
                <nav aria-label="breadcrumb"></nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Page Title End */}
      {/* Service Details Section Start */}
      <section className="service-details-page pdt-110 pdb-90">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-5 sidebar-right">
              <div className="service-nav-menu mrb-30">
                <div className="service-link-list mb-30">
                  <ul className>
                    <li>
                      <Link to="/Fashion">
                        <i className="fa fa-chevron-right" /> Fashion
                      </Link>
                    </li>
                    <li>
                      <Link to="/Mining">
                        <i className="fa fa-chevron-right" /> Mining{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="/Chemical">
                        <i className="fa fa-chevron-right" />
                        Chemical{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="/Automotive">
                        <i className="fa fa-chevron-right" /> Automotive &
                        Aerospace
                      </Link>
                    </li>
                    <li>
                      <Link to="/Consumer">
                        <i className="fa fa-chevron-right" />
                        Consumer Electronics
                      </Link>
                    </li>
                    <li>
                      <Link to="/Food">
                        <i className="fa fa-chevron-right" /> Food
                      </Link>
                    </li>
                    <li>
                      <Link to="/Furniture">
                        <i className="fa fa-chevron-right" /> Furniture{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="/Energy">
                        <i className="fa fa-chevron-right" /> Renewable Energy{" "}
                      </Link>
                    </li>
                  </ul>
                </div>{" "}
              </div>
            </div>{" "}
            <div className="col-xl-8 col-lg-7">
              <div className="service-detail-text">
                <h3 className="mrb-20">Chemical</h3>
                <div className="blog-standared-img slider-blog mrb-35">
                  <img className="img-full" src="images/Chemical.jpg" alt="" />
                </div>
                <p>Our specialized chemicals team is well-equipped with a high level of expertise in managing chemical products. We bolster this expertise through continuous training in dangerous goods and transport regulations, ensuring that we are fully prepared to meet your company's imperatives for a safe and secure supply chain.</p>

<p>We excel in handling Dangerous Goods, utilizing special equipment and distinct modes of transportation to ensure the utmost safety. Whether it involves transporting chemicals or managing intricate equipment, our proficiency shines through.</p>

<p>Our offerings extend to tailored services that cater to the unique demands of the chemical industry. {companyname} offers global coverage across transportation models, providing bespoke solutions aligned with the specific requirements of each customer and product. You can rely on us to deliver unparalleled support and secure logistical solutions, regardless of the scale or complexity of the task at hand.</p>

                {/*
						<div class="service-details-content">
							<div class="row d-flex mrb-40">
								<div class="col-lg-12 col-xl-6">
									<img class="img-full mrb-lg-40" src="images/service/service-details-inner-img1.jpg" alt="">
								</div>
								<div class="col-lg-12 col-xl-6">
									<h3 class="mrb-20">Service Features</h3>
									<div class="service-features-item d-flex mrb-20">
										<div class="service-features-icon">
											<span class="webexflaticon flaticon-presentation-1"></span>
										</div>
										<div class="service-features-details">
											<h5>Creative Design</h5>
											<p class="mrb-0">Leverage agile frameworks to provide a robust</p>
										</div>
									</div>
									<div class="service-features-item d-flex">
										<div class="service-features-icon">
											<span class="webexflaticon flaticon-search-1"></span>
										</div>
										<div class="service-features-details">
											<h5>Complete Analysis</h5>
											<p class="mrb-0">Leverage agile frameworks to provide a robust</p>
										</div>
									</div>
								</div>
							</div>
							<div class="row d-flex mrb-40">
								<div class="col-lg-12 col-xl-6">
									<h3 class="mrb-20">Service Included</h3>
									<ul class="order-list primary-color mrb-lg-40">
										<li>revolutionary catalysts for chang</li>
										<li>catalysts for chang the Seamlessly</li>
										<li>business applications through</li>
										<li>procedures whereas processes</li>
									</ul>
								</div>
								<div class="col-lg-12 col-xl-6">
									<img class="img-full" src="images/service/service-details-inner-img2.jpg" alt="">
								</div>
							</div>
							<div class="row">
								<div class="col-lg-12">
									<h3 class="mrb-20">Frequently Asked Question</h3>
								</div>
								<div class="col-lg-12">
									<div class="faq-block">
										<div class="accordion">
											<div class="accordion-item">
												<div class="accordion-header active">
													<h5 class="title">Q: What happens during Freshers' Week?</h5>
													<span class="fas fa-arrow-right"></span>
												</div>
												<div class="accordion-body">
													<p>A: Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy foster collaborative thinking to further the overall value proposition. Organically grow the holistic world view of disruptive innovation via workplace diversity and empowerment.</p>
												</div>
											</div>
											<div class="accordion-item">
												<div class="accordion-header">
													<h5 class="title">Q: What is the transfer application process?</h5>
													<span class="fas fa-arrow-right"></span>
												</div>
												<div class="accordion-body">
													<p>A: Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy foster collaborative thinking to further the overall value proposition. Organically grow the holistic world view of disruptive innovation via workplace diversity and empowerment.</p>
												</div>
											</div>
											<div class="accordion-item">
												<div class="accordion-header">
													<h5 class="title">Q: Why should I attend community college?</h5>
													<span class="fas fa-arrow-right"></span>
												</div>
												<div class="accordion-body">
													<p>A: Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy foster collaborative thinking to further the overall value proposition. Organically grow the holistic world view of disruptive innovation via workplace diversity and empowerment.</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						*/}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
