import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Healthcare
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Healthcare
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-12">
            <div className="mb-5">
              <div className="text-center" style={{ padding: "10px 80px" }}>
                <h1 className="mb-4">Healthcare</h1>
                <img
                  className="img-fluid w-100 mb-5 "
                  src="img/Healthcare.jpg"
                  alt=""
                />
              </div>

              <p>
                We have a profound understanding of the vital role
                temperature-controlled logistics solutions play in the
                pharmaceutical and healthcare sectors. Recognizing the
                sensitivity of these industries, our team possesses a
                comprehensive understanding of the challenges they face. These
                challenges range from supply shortages to outdated inventory
                practices and inefficient delivery methods, and we are
                well-versed in addressing the intricate issues affecting
                healthcare operations.
              </p>
              <p>
                Our commitment goes beyond mere awareness; we provide end-to-end
                technological solutions customized to tackle these challenges
                head-on. Our specialization lies in facilitating the seamless
                transportation of medical and pharmaceutical goods, making the
                most of established shipping channels. In an environment where
                warehouses are in a constant state of flux due to frequent
                transfers of goods, our technology-driven strategies come into
                play.
              </p>
              <p>
                One of our key strategies involves meticulous sorting and batch
                assignment, where each batch is assigned a unique identification
                number. This strategic organization proves to be invaluable in
                efficiently managing goods that are constantly on the move. This
                approach is particularly critical for perishable items like
                food, vitamins, proteins, as well as pharmaceutical and medical
                supplies. The use of these identification numbers serves as a
                powerful tool to streamline inventory management, organization,
                and overall handling procedures.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
