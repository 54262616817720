import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {

 
  return (

    <>
<div className="container-fluid bg-light p-0">
          <div className="row gx-0 d-none d-lg-flex">
            <div className="col-lg-7 px-5 text-start">
             
              <div className="h-100 d-inline-flex align-items-center border-end px-3">
                <small className="far fa-envelope-open me-2" />
                <small>{companyemail}</small>
              </div>
             
              <div
                      id="google_translate_element"
                      className="text-dark btn btn-sm background-white"
                    >
                      {" "}
                    </div>
             
            </div>
            <div className="col-lg-5 px-5 text-end">
              <div className="h-100 d-inline-flex align-items-center">
                <Link className=" border-end border-start ps-2 pe-2" to="/Privacy">Privacy Policy</Link>
                <Link className=" border-end ps-2 pe-2" to="/Terms">Terms</Link>
                
              </div>
            </div>
          </div>
        </div>
        {/* Topbar End */}
        {/* Navbar Start */}
        <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top px-4 px-lg-5 py-lg-0">
          <Link to="/Home" className="navbar-brand d-flex align-items-center">
            <img src="img/logo.png" style={{height:"70px"}}></img>
          </Link>
          <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto py-3 py-lg-0">
              <Link to="/Home" className="nav-item nav-link active">Home</Link>
              <div className="nav-item dropdown">
                <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">About Company</Link>
                <div className="dropdown-menu bg-light m-0">
                  <Link to="/About" className="dropdown-item">About Us</Link>
                  <Link to="/Whyus" className="dropdown-item">Why Choose Us</Link>
                  <Link to="/Careers" className="dropdown-item">Careers</Link>
                  <Link to="/Privacy" className="dropdown-item">Privacy Policy</Link>
                  <Link to="/Terms" className="dropdown-item">Terms</Link>
          
                </div>
              </div>
              <div className="nav-item dropdown">
                <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">What We Do</Link>
                <div className="dropdown-menu bg-light m-0">
                  <Link to="/Sea" className="dropdown-item">Sea Freight</Link>
                  <Link to="/Air" className="dropdown-item">Air Freight</Link>
                  <Link to="/LCL" className="dropdown-item">LCL Consolidation</Link>
                  <Link to="/Custom" className="dropdown-item">Custom Clearance</Link>
                  <Link to="/Local" className="dropdown-item">Local Transportation</Link>
                  <Link to="/Special" className="dropdown-item">Special Equipments</Link>
                  <Link to="/Hazardous" className="dropdown-item">Hazardous Materials</Link>
                  <Link to="/Warehousing" className="dropdown-item">Warehousing and Distribution</Link>
                  <Link to="/Bonded" className="dropdown-item">Bonded & Warehouse Storage</Link>
              
                </div>
              </div>
              <div className="nav-item dropdown">
                <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Key Sectors</Link>
                <div className="dropdown-menu bg-light m-0">
             
                  <Link to="/Apparel" className="dropdown-item">Apparel</Link>
                  <Link to="/Healthcare" className="dropdown-item">Healthcare</Link>
                  <Link to="/Food" className="dropdown-item">Food & Beverages & Beverages</Link>
                  <Link to="/Energy" className="dropdown-item">Energy</Link>
                  <Link to="/Automotive" className="dropdown-item">Automotive</Link>
                  <Link to="/Electronics" className="dropdown-item">Electronics</Link>
                  <Link to="/Retail" className="dropdown-item">Retail</Link>
                  <Link to="/High" className="dropdown-item">High Tech</Link>
                  <Link to="/Industrial" className="dropdown-item">Industrial</Link>
          
                </div>
              </div>
              <div className="nav-item dropdown">
                <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Tools</Link>
                <div className="dropdown-menu bg-light m-0">
                  <Link to="/Faqs" className="dropdown-item">FAQ's</Link>
                  <Link to="/Container" className="dropdown-item">Container Dimensions</Link>
                  <Link to="/Incoterms" className="dropdown-item">Incoterms</Link>
                  <Link to="/Getquote" className="dropdown-item">Request A Quote</Link>
          
                </div>
              </div>
              <Link to="/Contact" className="nav-item nav-link">Contact</Link>
            </div>
            <Link to="/Track" className="btn ms-4 nav-item nav-link btn-primary py-3 px-5">Tracking</Link>

          </div>
        </nav>  
    </>
  );
};

export default Header;
