import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
           Industries
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Industries
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-end mb-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="border-start border-5 border-primary ps-4">
                <h6 className="text-body text-uppercase mb-2">Our Industry Solutions</h6>
                <h1 className="display-6 mb-0">On Time, On Track, On Target.</h1>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <p className="mb-0">
                Our company is committed to meeting deadlines, maintaining shipment integrity, and achieving clients' objectives with precision. It's a concise and powerful slogan that can inspire trust and confidence in your services.
              </p>
            </div>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            ><Link to="/Apparel">
              <div className="team-item position-relative">
                <img className="img-fluid" src="img/Apparel.jpg" alt="" />
                <div className="team-text bg-white p-4">
                  <h5>Apparel</h5>
                  <span>Industries</span>
                </div>
              </div></Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            ><Link to="/Healthcare">
              <div className="team-item position-relative">
                <img className="img-fluid" src="img/Healthcare.jpg" alt="" />
                <div className="team-text bg-white p-4">
                  <h5>Healthcare</h5>
                  <span>Industries</span>
                </div>
              </div></Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            ><Link to="/Food">
              <div className="team-item position-relative">
                <img className="img-fluid" src="img/Food & Beverages.jpg" alt="" />
                <div className="team-text bg-white p-4">
                  <h5>Food & Beverages</h5>
                  <span>Industries</span>
                </div>
              </div></Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            ><Link to="/Energy">
              <div className="team-item position-relative">
                <img className="img-fluid" src="img/Energy.jpg" alt="" />
                <div className="team-text bg-white p-4">
                  <h5>Energy</h5>
                  <span>Industries</span>
                </div>
              </div></Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            ><Link to="/Automotive">
              <div className="team-item position-relative">
                <img className="img-fluid" src="img/Automotive.jpg" alt="" />
                <div className="team-text bg-white p-4">
                  <h5>Automotive</h5>
                  <span>Industries</span>
                </div>
              </div></Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            ><Link to="/Electronics">
              <div className="team-item position-relative">
                <img className="img-fluid" src="img/Electronics.jpg" alt="" />
                <div className="team-text bg-white p-4">
                  <h5>Electronics</h5>
                  <span>Industries</span>
                </div>
              </div></Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            ><Link to="/Retail">
              <div className="team-item position-relative">
                <img className="img-fluid" src="img/Retail.jpg" alt="" />
                <div className="team-text bg-white p-4">
                  <h5>Retail</h5>
                  <span>Industries</span>
                </div>
              </div></Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            ><Link to="/High">
              <div className="team-item position-relative">
                <img className="img-fluid" src="img/High Tech.jpg" alt="" />
                <div className="team-text bg-white p-4">
                  <h5>High Tech</h5>
                  <span>Industries</span>
                </div>
              </div></Link>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            ><Link to="/Industrial">
              <div className="team-item position-relative">
                <img className="img-fluid" src="img/Industrial.jpg" alt="" />
                <div className="team-text bg-white p-4">
                  <h5>Industrial</h5>
                  <span>Industries</span>
                </div>
              </div></Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
