import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import React, { useState } from 'react';

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
    const [originOption, setOriginOption] = useState('');
    const [terminalOption, setTerminalOption] = useState('');
    const [distance, setDistance] = useState('');
  
    const handleSubmit = (event) => {
      event.preventDefault();
      const calculatedDistance = calculateDistance(originOption, terminalOption);
      setDistance(calculatedDistance);
    };
  
    const calculateDistance = (origin, terminal) => {
      const eind = origin.split('/');
      const tourism = terminal.split('/');
      const d = Math.acos(Math.sin(eind[2]) * Math.sin(tourism[2]) +
        Math.cos(eind[2]) * Math.cos(tourism[2]) * Math.cos(eind[4] - tourism[4]));
      const a = Math.round(3956.073 * d);
      let b;
      if (Math.sin(tourism[4] - eind[4]) < 0) {
        b = Math.acos((Math.sin(tourism[2]) - Math.sin(eind[2]) * Math.cos(d)) /
          (Math.sin(d) * Math.cos(eind[2])));
      } else {
        b = 2 * Math.PI -
          Math.acos((Math.sin(tourism[2]) - Math.sin(eind[2]) * Math.cos(d)) /
            (Math.sin(d) * Math.cos(eind[2])));
      }
      return `${Math.round(a * 1.6094)} kms`;
    };
  

  
   
  return (
    <>
      <Header />
      <div
        className="container-fluid "
        style={{
          backgroundImage: 'url("img/banner.jpg")',
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          padding: "70px",
        }}
      >
        <div className="row">
          <div className="col-12 text-center">
            <h1 className="display-4 text-white">Distance Calculator</h1>
            <Link to="/">Home</Link>
            <i className="far fa-square text-primary px-2" />
            <Link href>Distance Calculator</Link>
          </div>
        </div>
      </div>
      <section className="about-section">
        {/*===============spacing==============*/}
        <div className="pd_top_90" />
        {/*===============spacing==============*/}
        <div className="container">
        <div className=" g-5 align-items-center mb-5">
        <div className=" m-5 wow fadeIn text-center" data-wow-delay="0.3s" style={{width: '1000px'}}>
          <h1 className="display-6 mb-3 p-4">Distance Calculator</h1>
        </div>
        <form className="row"  onSubmit={handleSubmit}>
          <div className="col-lg-6"><label htmlFor>Enter Origin point</label>
            <select name="abc"value={originOption} onChange={(e) => setOriginOption(e.target.value)} className="form-control">
              <option value="/1/0.158995591/-1/1.369611552" selected="SELECTED">
                Abiramam , TN</option>
              <option value="/1/0.526186067/-1/1.295638007">Abohar
                , Punjab</option>
              <option value="/1/0.371031746/-1/1.353291005">Achalpur
                , Maharashtra</option>
              <option value="/1/0.275936067/-1/1.396306437">Addanki , AP</option>
              <option value="/1/0.343536596/-1/1.371362434">Adilabad , AP</option>
              <option value="/1/0.27280291/-1/1.349202381">Adoni , AP</option>
              <option value="/1/0.410181658/-1/1.502780423">Adra , WB</option>
              <option value="/1/0.192305996/-1/1.387770282">Aduturai , TN</option>
              <option value="/1/0.416108466/-1/1.593700176">Agartala
                , Tripura</option>
              <option value="/1/0.474527778/-1/1.362064815">Agra , UP</option>
              <option value="/1/0.402280864/-1/1.258015873">Ahmedabad
                , Gujarat</option>
              <option value="/1/0.33331261/-1/1.305061728">Ahmadnagar
                , Maharashtra</option>
              <option value="/1/0.436895944/-1/1.449633157">Ahraura , UP</option>
              <option value="/1/0.461704145/-1/1.303281746">Ajmer
                , Rajasthan</option>
              <option value="/1/0.46142284/-1/1.432633598">Akbarpur , UP</option>
              <option value="/1/0.361491623/-1/1.344391093">Akola
                , Maharashtra</option>
              <option value="/1/0.368310847/-1/1.345429012">Akot
                , Maharashtra</option>
              <option value="/1/0.27721164/-1/1.364247354">Alampur , AP</option>
              <option value="/1/0.192228395/-1/1.332993386">Alanallur
                , Kerala</option>
              <option value="/1/0.180874339/-1/1.379141975">Alangudi , TN</option>
              <option value="/1/0.487375661/-1/1.383633157">Alapur , UP</option>
              <option value="/1/0.490838624/-1/1.407165785">Aliganj , UP</option>
              <option value="/1/0.487031305/-1/1.364257055">Aligarh , UP</option>
              <option value="/1/0.480090829/-1/1.382343034">Alignj , UP</option>
              <option value="/1/0.444238977/-1/1.428748677">Allahabad
                , UP</option>
              <option value="/1/0.159883157/-1/1.332682981">Alleppey
                , Kerala</option>
              <option value="/1/0.256370811/-1/1.397795414">Allur , AP</option>
              <option value="/1/0.516869048/-1/1.390762787">Almora , UP</option>
              <option value="/1/0.48123545/-1/1.33761067">Alwar
                , Rajasthan</option>
              <option value="/1/0.176480159/-1/1.327590388">Alwaye
                , Kerala</option>
              <option value="/1/0.367428131/-1/1.310571429">Amalner
                , Maharashtra</option>
              <option value="/1/0.466238977/-1/1.365799383">Ambah , MP</option>
              <option value="/1/0.529906085/-1/1.341519841">Ambala
                , Haryana</option>
              <option value="/1/0.529726631/-1/1.337523369">Ambala
                Cantonment , Punjab</option>
              <option value="/1/0.157938272/-1/1.33319224">Ambalapulai
                , Kerala</option>
              <option value="/1/0.403667989/-1/1.452664462">Ambikapur
                , MP</option>
              <option value="/1/0.22327866/-1/1.374398589">Ambur , TN</option>
              <option value="/1/0.467116843/-1/1.417059965">Amethi , UP</option>
              <option value="/1/0.38282716/-1/1.364261905">Amla , MP</option>
              <option value="/1/0.365454145/-1/1.357690035">Amravati
                , Maharashtra</option>
              <option value="/1/0.377191358/-1/1.239997795">Amreli
                , Gujarat</option>
              <option value="/1/0.552284392/-1/1.307278219">Amritsar
                , Punjab</option>
              <option value="/1/0.504695326/-1/1.37004321">Amroha , UP</option>
              <option value="/1/0.184710758/-1/1.343261023">Anaimalai
                , TN</option>
              <option value="/1/0.308800265/-1/1.449356702">Anakapalle
                , AP</option>
              <option value="/1/0.393919312/-1/1.27381261">Anand
                , Gujarat</option>
              <option value="/1/0.256395062/-1/1.354741182">Anantapur
                , AP</option>
              <option value="/1/0.589290564/-1/1.312918871">Anantnag
                , Jammu &amp; Kashmir</option>
              <option value="/1/0.197888448/-1/1.38601455">Andimatam, TN</option>
              <option value="/1/0.159888007/-1/1.355337743">Andipatti
                , TN</option>
              <option value="/1/0.202098325/-1/1.354750882">Andiyur , TN</option>
              <option value="/1/0.221886684/-1/1.356564815">Anekal
                , Karnataka</option>
              <option value="/1/0.363717813/-1/1.485824515">Angul
                , Orissa</option>
              <option value="/1/0.369479718/-1/1.349837743">Anjangaon
                , Maharashtra</option>
              <option value="/1/0.403546737/-1/1.222590829">Anjar
                , Gujarat</option>
              <option value="/1/0.142417989/-1/1.340617725">Anjengo
                , Kerala</option>
              <option value="/1/0.377647266/-1/1.258617284">Ankleshwar
                , Gujarat</option>
              <option value="/1/0.255914903/-1/1.297093034">
                Ankola, Karnataka</option>
              <option value="/1/0.269233245/-1/1.317036596">Annegeri
                , Karnataka</option>
              <option value="/1/0.196186067/-1/1.346297178">Annur, TN</option>
              <option value="/1/0.350855379/-1/1.417142416">Antagarh, MP</option>
              <option value="/1/0.495140653/-1/1.366643298">Anupshahr, UP</option>
              <option value="/1/0.493729277/-1/1.382333333">Aonla , UP</option>
              <option value="/1/0.221168871/-1/1.384307319">Arani, TN</option>
              <option value="/1/0.232876984/-1/1.398425926">Arani, TN</option>
              <option value="/1/0.177455026/-1/1.379311728">Arantangi, TN</option>
              <option value="/1/0.456446649/-1/1.527981481">Araria, Bihar</option>
              <option value="/1/0.188047619/-1/1.360333333">
                Aravakkurichchi, TN</option>
              <option value="/1/0.195938713/-1/1.327837743">
                Arikkod, Kerala</option>
              <option value="/1/0.178953704/-1/1.377512346">Arimalam, TN</option>
              <option value="/1/0.194415785/-1/1.380606702">Ariyalpur, TN</option>
              <option value="/1/0.22276455/-1/1.327973545">
                Arkalgud, Karnataka</option>
              <option value="/1/0.228429453/-1/1.391058642">Arkonam, TN</option>
              <option value="/1/0.446246914/-1/1.467840388">Arrah, Bihar</option>
              <option value="/1/0.232377425/-1/1.331305556">
                Arsikere, Karnataka</option>
              <option value="/1/0.157264109/-1/1.363539242">
                Aruppukottai, TN</option>
              <option value="/1/0.366584215/-1/1.366027337">
                Arvi, Maharashtra</option>
              <option value="/1/0.41358157/-1/1.518582011">Asansol, WB</option>
              <option value="/1/0.429048501/-1/1.357040123">
                Ashoknagar, MP</option>
              <option value="/1/0.441474427/-1/1.397708113">Atarra, UP</option>
              <option value="/1/0.180481481/-1/1.385902998">
                Atirampattinam, TN</option>
              <option value="/1/0.277231041/-1/1.372177249">Atmakur, AP</option>
              <option value="/1/0.255134039/-1/1.390292328">Atmakur, AP</option>
              <option value="/1/0.489432099/-1/1.362205467">Atrauli, UP</option>
              <option value="/1/0.201414462/-1/1.364242504">
                Attayyampatti, TN</option>
              <option value="/1/0.20240873/-1/1.362802028">Attur, TN</option>
              <option value="/1/0.462067901/-1/1.38820679">Auraiya, UP</option>
              <option value="/1/0.432113757/-1/1.473238536">
                Aurangabad, Bihar</option>
              <option value="/1/0.347203263/-1/1.315101411">
                Aurangabad, Maharashtra</option>
              <option value="/1/0.195419753/-1/1.34916843">Avanashi, TN</option>
              <option value="/1/0.467873457/-1/1.435213845">Ayodhya, UP</option>
              <option value="/1/0.455078924/-1/1.449516755">Azamgarh, UP</option>
              <option value="/1/0.207966931/-1/1.315736772">
                Azhikkal, Kerala</option>
              <option value="/1/0.423989859/-1/1.537870811">Azimganj, WB</option>
              <option value="/1/0.202447531/-1/1.319670194">
                Badagara, Kerala</option>
              <option value="/1/0.277876102/-1/1.321338624">
                Badami, Karnataka</option>
              <option value="/1/0.36411552/-1/1.357146825">
                Badnera, Maharashtra</option>
              <option value="/1/0.257321429/-1/1.380466049">Badvel, AP</option>
              <option value="/1/0.473121252/-1/1.468247795">Bagha, Bihar</option>
              <option value="/1/0.282595238/-1/1.321678131">
                Bagalkot, Karnataka</option>
              <option value="/1/0.275358907/-1/1.303208995">
                Bagevadi, Karnataka</option>
              <option value="/1/0.469115079/-1/1.372298501">Bah, UP</option>
              <option value="/1/0.501115961/-1/1.341185185">
                Bahadurgarh, Haryana</option>
              <option value="/1/0.502357584/-1/1.388012787">Baheri, UP</option>
              <option value="/1/0.481439153/-1/1.424674603">Bahraich, UP</option>
              <option value="/1/0.275931217/-1/1.304130511">Bail
                Hongal, Karnataka</option>
              <option value="/1/0.380916226/-1/1.400089506">Balaghat, MP</option>
              <option value="/1/0.37512522/-1/1.518092152">
                Balasore, Orissa</option>
              <option value="/1/0.3465/-1/1.38546164">
                Balharshah, Maharashtra</option>
              <option value="/1/0.449748677/-1/1.469266314">Ballia, UP</option>
              <option value="/1/0.450922399/-1/1.261367284">
                Balotra, Rajasthan</option>
              <option value="/1/0.478926808/-1/1.43492284">Balrampur, UP</option>
              <option value="/1/0.440421958/-1/1.550020282">Balurghat, WB</option>
              <option value="/1/0.276071869/-1/1.289638448">Banda, Goa</option>
              <option value="/1/0.444753086/-1/1.402630952">Banda, UP</option>
              <option value="/1/0.226542769/-1/1.354702381">
                Bangalore, Karnataka</option>
              <option value="/1/0.26732231/-1/1.365809083">
                Banganapalle, AP</option>
              <option value="/1/0.402353616/-1/1.551116402">Bangaon, WB</option>
              <option value="/1/0.226722222/-1/1.365013668">
                Bangarapet, Karnataka</option>
              <option value="/1/0.469517637/-1/1.400477513">Bangarmau, UP</option>
              <option value="/1/0.434393298/-1/1.517558642">Banka, Bihar</option>
              <option value="/1/0.260430335/-1/1.31395679">
                Bankapur, Karnataka</option>
              <option value="/1/0.405680776/-1/1.520308642">Bankura, WB</option>
              <option value="/1/0.451955467/-1/1.522515432">
                Banmnkhi, Bihar</option>
              <option value="/1/0.215256614/-1/1.34200485">
                Bannur, Karnataka</option>
              <option value="/1/0.225179894/-1/1.310154321">
                Bantval, Karnataka</option>
              <option value="/1/0.277696649/-1/1.397426808">Bapatala, AP</option>
              <option value="/1/0.31681261/-1/1.302122575">
                Baramati, Maharashtra</option>
              <option value="/1/0.597361111/-1/1.292650353">
                Baramula, Jammu &amp; Kashmir</option>
              <option value="/1/0.438156966/-1/1.335874339">Baran
                , Rajasthan</option>
              <option value="/1/0.508129189/-1/1.349066578">Baraut , UP</option>
              <option value="/1/0.440363757/-1/1.496882716">Barbigha
                , Bihar</option>
              <option value="/1/0.405641975/-1/1.534126543">Bardhaman
                , WB</option>
              <option value="/1/0.495053351/-1/1.386509259">Bareilly, UP</option>
              <option value="/1/0.444670635/-1/1.49654321">Barh, Bihar</option>
              <option value="/1/0.441488977/-1/1.501839506">
                Barhiya, Bihar</option>
              <option value="/1/0.46531746/-1/1.35518739">Bari, Rajasthan</option>
              <option value="/1/0.382987213/-1/1.514386684">
                Baripada, Orissa</option>
              <option value="/1/0.422054674/-1/1.499598765">Barki
                Saria, Bihar</option>
              <option value="/1/0.449394621/-1/1.246438713">
                Barmer, Rajasthan</option>
              <option value="/1/0.459511905/-1/1.589223545">
                Barpeta, Assam</option>
              <option value="/1/0.318398589/-1/1.321585979">
                Barsi, Maharashtra</option>
              <option value="/1/0.444753086/-1/1.50093254">Barauni, Bihar</option>
              <option value="/1/0.442992504/-1/1.374616843">Barwa
                Sagar, UP</option>
              <option value="/1/0.456242945/-1/1.527166667">
                Basantpur, Bihar</option>
              <option value="/1/0.395548942/-1/1.537967813">Basirhat, WB</option>
              <option value="/1/0.467844356/-1/1.444710317">Basti, UP</option>
              <option value="/1/0.555383598/-1/1.313078924">
                Batala, Punjab</option>
              <option value="/1/0.469803792/-1/1.349469136">
                Bayana, Rajasthan</option>
              <option value="/1/0.455593034/-1/1.297510141">
                Beawar, Rajasthan</option>
              <option value="/1/0.443715168/-1/1.503750441">
                Begusarai, Bihar</option>
              <option value="/1/0.452469577/-1/1.43166358">Bela, UP</option>
              <option value="/1/0.276750882/-1/1.300871252">
                Belgaum, Karnataka</option>
              <option value="/1/0.264320106/-1/1.328473104">
                Bellary, Karnataka</option>
              <option value="/1/0.229802028/-1/1.324471781">
                Belur, Karnataka</option>
              <option value="/1/0.441430776/-1/1.448440035">Benaras, UP</option>
              <option value="/1/0.420633598/-1/1.540824515">
                Berhampore, WB</option>
              <option value="/1/0.33712963/-1/1.480799824">
                Berhampur, Orissa</option>
              <option value="/1/0.269713404/-1/1.364436508">
                Betamcherla, AP</option>
              <option value="/1/0.467931658/-1/1.475464727">
                Bettiah, Bihar</option>
              <option value="/1/0.382429453/-1/1.360241182">Betul, MP</option>
              <option value="/1/0.195371252/-1/1.32387037">
                Beypore, Kerala</option>
              <option value="/1/0.437196649/-1/1.459900794">Bhabua, Bihar</option>
              <option value="/1/0.443370811/-1/1.441620811">Bhadohi, UP</option>
              <option value="/1/0.367568783/-1/1.510797619">
                Bhadrakh, Orissa</option>
              <option value="/1/0.241524691/-1/1.32162478">
                Bhadravati, Karnataka</option>
              <option value="/1/0.440698413/-1/1.503061728">
                Bhagalpur, Bihar</option>
              <option value="/1/0.438447972/-1/1.436470018">
                Bharatganj, UP</option>
              <option value="/1/0.47519224/-1/1.353">Bharatpur, Rajasthan</option>
              <option value="/1/0.467150794/-1/1.379660935">Bharthana, UP</option>
              <option value="/1/0.379165344/-1/1.274205467">
                Bharuch, Gujarat</option>
              <option value="/1/0.472083333/-1/1.34529321">
                Bhasawar, Rajasthan</option>
              <option value="/1/0.379422399/-1/1.430795414">Bhatapara, MP</option>
              <option value="/1/0.244158289/-1/1.301579365">
                Bhatkali, Karnataka</option>
              <option value="/1/0.199736332/-1/1.345647266">Bhavani, TN</option>
              <option value="/1/0.380111111/-1/1.259635802">
                Bhavnagar, Gujarat</option>
              <option value="/1/0.347562169/-1/1.452218254">
                Bhawanipatna, Orissa</option>
              <option value="/1/0.472301587/-1/1.314601852">
                Bheslana, Rajasthan</option>
              <option value="/1/0.442551146/-1/1.303170194">
                Bhilwara, Rajasthan</option>
              <option value="/1/0.288885802/-1/1.423563933">Bhimvaram, AP</option>
              <option value="/1/0.463818783/-1/1.363068783">Bhind, MP</option>
              <option value="/1/0.426647707/-1/1.296675926">
                Bhindar, Rajasthan</option>
              <option value="/1/0.483723545/-1/1.43056261">Bhinga, UP</option>
              <option value="/1/0.336940476/-1/1.275534392">
                Bhiwandi, Maharashtra</option>
              <option value="/1/0.502667989/-1/1.329380071">
                Bhiwani, Haryana</option>
              <option value="/1/0.314688272/-1/1.450118166">
                Bhogapuram, AP</option>
              <option value="/1/0.475934303/-1/1.382401235">Bhongaon, UP</option>
              <option value="/1/0.405598325/-1/1.351632275">Bhopal, MP</option>
              <option value="/1/0.353804233/-1/1.485611111">
                Bhubaneshwar, Orissa</option>
              <option value="/1/0.405981481/-1/1.216329365">Bhuj, Gujarat</option>
              <option value="/1/0.367491182/-1/1.32327381">
                Bhusawal, Maharashtra</option>
              <option value="/1/0.312665785/-1/1.353645062">
                Bidar, Karnataka</option>
              <option value="/1/0.439961199/-1/1.49323545">Bihar, Bihar</option>
              <option value="/1/0.293750441/-1/1.322012787">
                Bijaipur, Karnataka</option>
              <option value="/1/0.429940917/-1/1.387876984">Bijawar, MP</option>
              <option value="/1/0.512843474/-1/1.364281305">Bijnor, UP</option>
              <option value="/1/0.489233245/-1/1.27490873">
                Bikaner, Rajasthan</option>
              <option value="/1/0.546958995/-1/1.34036552">Bilaspur, HP</option>
              <option value="/1/0.385586861/-1/1.434452381">Bilaspur, MP</option>
              <option value="/1/0.474445326/-1/1.397305556">Bilgram, UP</option>
              <option value="/1/0.468697972/-1/1.397902116">Bilhaur, UP</option>
              <option value="/1/0.422001323/-1/1.365183422">Bina, MP</option>
              <option value="/1/0.454584215/-1/1.406782628">Bindki, UP</option>
              <option value="/1/0.237416667/-1/1.310978836">
                Birur, Karnataka</option>
              <option value="/1/0.494068783/-1/1.393391534">Bisalpur, UP</option>
              <option value="/1/0.402979277/-1/1.524780423">Bishnupur, WB</option>
              <option value="/1/0.48016843/-1/1.414154762">Biswan, UP</option>
              <option value="/1/0.324286596/-1/1.455467813">Bobbili, AP</option>
              <option value="/1/0.325794974/-1/1.360241182">Bodhan, AP</option>
              <option value="/1/0.39103836/-1/1.272842593">
                Borsad, Gujarat</option>
              <option value="/1/0.38722619/-1/1.25125485">Botad, Gujarat</option>
              <option value="/1/0.48947575/-1/1.381474868">Budaun, UP</option>
              <option value="/1/0.511388448/-1/1.352747795">Budhana, UP</option>
              <option value="/1/0.247868607/-1/1.345618166">
                Bukkapatnam, AP</option>
              <option value="/1/0.495926367/-1/1.359271164">
                Bulandshahr, UP</option>
              <option value="/1/0.372011464/-1/1.3309903">Berhanpur, MP</option>
              <option value="/1/0.256133157/-1/1.310130071">
                Byadgi, Karnataka</option>
              <option value="/1/0.393613757/-1/1.537104497">Calcutta, WB</option>
              <option value="/1/0.206996914/-1/1.315833774">
                Cannanore, Kerala</option>
              <option value="/1/0.461248236/-1/1.484946649">Chakia, Bihar</option>
              <option value="/1/0.437385802/-1/1.452926367">Chakia, UP</option>
              <option value="/1/0.357175044/-1/1.311003086">
                Chalisgaon, Maharashtra</option>
              <option value="/1/0.250002646/-1/1.338260582">
                Challakere, Karnataka</option>
              <option value="/1/0.208151235/-1/1.343387125">
                Chamrajnagar, Karnataka</option>
              <option value="/1/0.496779982/-1/1.37555291">Chandausi, UP</option>
              <option value="/1/0.431454145/-1/1.364295855">Chanderi, MP</option>
              <option value="/1/0.536691358/-1/1.328162698">
                Chandigarh, Chandigarh City</option>
              <option value="/1/0.508711199/-1/1.366648148">Chandpur, UP</option>
              <option value="/1/0.396916667/-1/1.528156085">Chandrakona
                Road , WB</option>
              <option value="/1/0.348275132/-1/1.384651675">
                Chandrapur, Maharashtra</option>
              <option value="/1/0.370794092/-1/1.357452381">Chandur
                Bazar , Maharashtra</option>
              <option value="/1/0.159044092/-1/1.336407848">
                Changanacheri, Kerala</option>
              <option value="/1/0.244837302/-1/1.325606702">
                Channagiri, Karnataka</option>
              <option value="/1/0.220940917/-1/1.347902557">
                Channapatna, Karnataka</option>
              <option value="/1/0.225194444/-1/1.333599647">
                Channarayapatna , Karnataka</option>
              <option value="/1/0.470671958/-1/1.476104938">Chanpatia
                , Bihar</option>
              <option value="/1/0.443511464/-1/1.392416667">Charkhari
                , UP</option>
              <option value="/1/0.515869929/-1/1.354794533">Charthawal
                , UP</option>
              <option value="/1/0.422568783/-1/1.443575397">Chatra, UP</option>
              <option value="/1/0.337895944/-1/1.48392328">
                Chatrapur, Orissa</option>
              <option value="/1/0.182707672/-1/1.35520679">
                Chattrappatt, TN</option>
              <option value="/1/0.214849206/-1/1.375712963">Chengam, TN</option>
              <option value="/1/0.159713404/-1/1.337712522">
                Chengannur, Kerala</option>
              <option value="/1/0.194900794/-1/1.355070988">
                Chennimalai, TN</option>
              <option value="/1/0.217628307/-1/1.38543254">Chetput, TN</option>
              <option value="/1/0.21560097/-1/1.396878748">Cheyur, TN</option>
              <option value="/1/0.528082451/-1/1.350696208">
                Chhachrauli, Haryana</option>
              <option value="/1/0.484111552/-1/1.353242504">Chhata, UP</option>
              <option value="/1/0.434951058/-1/1.389627866">
                Chhattarpur, MP</option>
              <option value="/1/0.474028219/-1/1.388056437">
                Chhibramau, UP</option>
              <option value="/1/0.385140653/-1/1.378385362">
                Chhindwara, MP</option>
              <option value="/1/0.198994268/-1/1.391436949">
                Chidambaram, TN</option>
              <option value="/1/0.234370811/-1/1.357025573">Chik
                Ballapur, Karnataka</option>
              <option value="/1/0.232435626/-1/1.322982804">
                Chikmagalur, Karnataka</option>
              <option value="/1/0.234191358/-1/1.337727072">
                Chiknayakanhalli , Karnataka</option>
              <option value="/1/0.272055996/-1/1.380844356">Chimakurti
                , AP</option>
              <option value="/1/0.22162963/-1/1.396359788">Chingleput, TN</option>
              <option value="/1/0.259945326/-1/1.391189594">Chinna
                Annaluru , AP</option>
              <option value="/1/0.189429894/-1/1.345933422">Chinna
                Dharapuram , TN</option>
              <option value="/1/0.27388448/-1/1.400763668">Chinna Ganjam
                , AP</option>
              <option value="/1/0.203165344/-1/1.377240741">Chinna Salem
                , TN</option>
              <option value="/1/0.319771164/-1/1.459212081">
                Chipurupalle, AP</option>
              <option value="/1/0.276362875/-1/1.40305291">Chirala, AP</option>
              <option value="/1/0.493011464/-1/1.320742063">
                Chirawa, Rajasthan</option>
              <option value="/1/0.404948413/-1/1.438060847">Chirmiri, MP</option>
              <option value="/1/0.184895062/-1/1.327609788">
                Chittalancheri, Kerala</option>
              <option value="/1/0.4344806/-1/1.292965608">
                Chittaurgarh, Rajasthan</option>
              <option value="/1/0.230675044/-1/1.381018959">Chittoor, AP</option>
              <option value="/1/0.247466049/-1/1.385141534">Chitvel, AP</option>
              <option value="/1/0.17488933/-1/1.361148148">
                Cholavandan, TN</option>
              <option value="/1/0.474246473/-1/1.322158289">
                Chomun, Rajasthan</option>
              <option value="/1/0.370958995/-1/1.314660053">
                Chopda, Maharashtra</option>
              <option value="/1/0.399817019/-1/1.539544092">Chunchura, WB</option>
              <option value="/1/0.174811728/-1/1.330912698">
                Cochin, Kerala</option>
              <option value="/1/0.192150794/-1/1.343930335">
                Coimbatore, TN</option>
              <option value="/1/0.44110582/-1/1.519353175">Colong, Bihar</option>
              <option value="/1/0.459565256/-1/1.562053351">Cooch Behar,
                WB</option>
              <option value="/1/0.237843474/-1/1.30406746">
                Coondapoor, Karnataka</option>
              <option value="/1/0.198145503/-1/1.340840829">Coonoor, TN</option>
              <option value="/1/0.205182981/-1/1.392693122">Cuddalore, TN</option>
              <option value="/1/0.252650794/-1/1.376231922">Cuddappah, AP</option>
              <option value="/1/0.271939594/-1/1.381164462">Cumbum, AP</option>
              <option value="/1/0.357305996/-1/1.499477513">
                Cuttak, Orissa</option>
              <option value="/1/0.386387125/-1/1.275199735">
                Dabhoi, Gujarat</option>
              <option value="/1/0.339574074/-1/1.438977513">Dabugam
                , Orissa</option>
              <option value="/1/0.498492063/-1/1.35412037">Dadri, UP</option>
              <option value="/1/0.451732363/-1/1.533660935">Dalkola, WB</option>
              <option value="/1/0.419755732/-1/1.467796737">
                Daltonganj, Bihar</option>
              <option value="/1/0.447604938/-1/1.484936949">
                Danapur, Bihar</option>
              <option value="/1/0.456572751/-1/1.499676367">
                Darbhanga, Bihar</option>
              <option value="/1/0.472136684/-1/1.541023369">
                Darjeeling, WB</option>
              <option value="/1/0.469420635/-1/1.42397134">Daryabad, UP</option>
              <option value="/1/0.448172399/-1/1.369858907">Datia, MP</option>
              <option value="/1/0.437075397/-1/1.473587743">
                Daudnagar, Bihar</option>
              <option value="/1/0.322361111/-1/1.302137125">
                Daund, Maharashtra</option>
              <option value="/1/0.469478836/-1/1.327609788">
                Dausa, Rajasthan</option>
              <option value="/1/0.252364638/-1/1.311017637">
                Davangere, Karnataka</option>
              <option value="/1/0.52962963/-1/1.362734127">Dehradun, UP</option>
              <option value="/1/0.434577601/-1/1.469634921">Dehri, Bihar</option>
              <option value="/1/0.448094797/-1/1.4987403">Delsingh
                Sarai, Bihar</option>
              <option value="/1/0.449772928/-1/1.31616358">
                Delvi, Rajasthan</option>
              <option value="/1/0.218680776/-1/1.358179894">
                Denkanikota, TN</option>
              <option value="/1/0.518818783/-1/1.345894621">
                Deoband, Haryana</option>
              <option value="/1/0.427583774/-1/1.513664021">
                Deoghar, Jharkhand</option>
              <option value="/1/0.17711067/-1/1.355682099">
                Devadanappatti, TN</option>
              <option value="/1/0.159097443/-1/1.376324074">
                Devakottai, TN</option>
              <option value="/1/0.231203704/-1/1.356797619">
                Devanhalli, Karnataka</option>
              <option value="/1/0.175713845/-1/1.346287478">
                Devikolam, Kerala</option>
              <option value="/1/0.400879189/-1/1.327837743">Dewas, MP</option>
              <option value="/1/0.183444885/-1/1.347698854">Dhali, TN</option>
              <option value="/1/0.511616402/-1/1.370722222">Dhampur, UP</option>
              <option value="/1/0.361520723/-1/1.42397619">Dhamtari, MP</option>
              <option value="/1/0.505587743/-1/1.366425044">Dhanaura, UP</option>
              <option value="/1/0.415327601/-1/1.502198413">
                Dhanbad, Bihar</option>
              <option value="/1/0.187426808/-1/1.353654762">
                Dharampuram, TN</option>
              <option value="/1/0.2117306/-1/1.364771164">Dharampuri, TN</option>
              <option value="/1/0.22602866/-1/1.316110229">
                Dharmastala, Karnataka</option>
              <option value="/1/0.251671076/-1/1.345618166">
                Dharmavaram, AP</option>
              <option value="/1/0.562581129/-1/1.327309083">
                Dharmshala, HP</option>
              <option value="/1/0.269776455/-1/1.309669312">
                Dharwar, Karnataka</option>
              <option value="/1/0.360662257/-1/1.494675926">
                Dhenkanal, Orissa</option>
              <option value="/1/0.426720459/-1/1.347698854">Dhirpur, MP</option>
              <option value="/1/0.396887566/-1/1.264883598">
                Dholka, Gujarat</option>
              <option value="/1/0.466035273/-1/1.359804674">
                Dholpur, Rajasthan</option>
              <option value="/1/0.268719136/-1/1.345933422">Dhone, AP</option>
              <option value="/1/0.37949515/-1/1.229972663">
                Dhoraji, Gujarat</option>
              <option value="/1/0.401373898/-1/1.247733686">
                Dhrangadhra, Gujarat</option>
              <option value="/1/0.454351411/-1/1.571045414">Dhubri, Assam</option>
              <option value="/1/0.364954586/-1/1.305522487">
                Dhule, Maharashtra</option>
              <option value="/1/0.431425044/-1/1.531420194">
                Dhulian, Bihar</option>
              <option value="/1/0.464284392/-1/1.554201058">Dhupgari, WB</option>
              <option value="/1/0.492521605/-1/1.366444444">Dibai, UP</option>
              <option value="/1/0.479790123/-1/1.65714418">
                Dibrugarh, Assam</option>
              <option value="/1/0.479639771/-1/1.350128748">
                Dig, Rajasthan</option>
              <option value="/1/0.561077601/-1/1.310144621">
                Dinanagar, Punjab</option>
              <option value="/1/0.180830688/-1/1.361482804">Dindigul, TN</option>
              <option value="/1/0.456213845/-1/1.562218254">Dinhata, WB</option>
              <option value="/1/0.423606702/-1/1.260305115">Disa, Gujarat</option>
              <option value="/1/0.232018519/-1/1.353732363">Dod
                Ballapur, Karnataka</option>
              <option value="/1/0.335422399/-1/1.276048501">
                Dombivli, Maharashtra</option>
              <option value="/1/0.369964727/-1/1.410109788">
                Dongargarh, MP</option>
              <option value="/1/0.41526455/-1/1.51963448">Dubrajpur, WB</option>
              <option value="/1/0.423645503/-1/1.523349647">
                Dumka, Jharkhand</option>
              <option value="/1/0.44606746/-1/1.467830688">Dumraon, Bihar</option>
              <option value="/1/0.370105379/-1/1.420770282">Durg, MP</option>
              <option value="/1/0.204571869/-1/1.382551587">
                Elavanasur, TN</option>
              <option value="/1/0.291791005/-1/1.416128748">Eluru, AP</option>
              <option value="/1/0.275358907/-1/1.352835097">
                Emmiganuru, AP</option>
              <option value="/1/0.436512787/-1/1.538966931">English
                Bazar, WB</option>
              <option value="/1/0.188421076/-1/1.351200617">
                Erakampatti, TN</option>
              <option value="/1/0.174860229/-1/1.332280423">
                Ernakulam, Kerala</option>
              <option value="/1/0.197907848/-1/1.357205026">Erode, TN</option>
              <option value="/1/0.481186949/-1/1.362802028">Etah, UP</option>
              <option value="/1/0.467533951/-1/1.382018078">Etawah, UP</option>
              <option value="/1/0.158171076/-1/1.361739859">
                Ettaiyapuram, TN</option>
              <option value="/1/0.467563051/-1/1.434049824">Faizabad, UP</option>
              <option value="/1/0.463013668/-1/1.55759127">Falakata, WB</option>
              <option value="/1/0.49555776/-1/1.349735891">
                Faridabad, Haryana</option>
              <option value="/1/0.535420635/-1/1.305255732">
                Faridkot, Punjab</option>
              <option value="/1/0.492647707/-1/1.380257496">Faridpur, UP</option>
              <option value="/1/0.477791887/-1/1.390355379">
                Farrukhabad, UP</option>
              <option value="/1/0.471850529/-1/1.367113757">Fatehabad, UP</option>
              <option value="/1/0.488772487/-1/1.308762346">
                Fatehpur, Rajasthan</option>
              <option value="/1/0.474421076/-1/1.41791843">Fatehpur, UP</option>
              <option value="/1/0.452692681/-1/1.411036155">Fatehpur, UP</option>
              <option value="/1/0.420439594/-1/1.531701499">Fatehpur, WB</option>
              <option value="/1/0.473063051/-1/1.355968254">Fatehpur
                Sikri, UP</option>
              <option value="/1/0.530856702/-1/1.29250485">
                Fazilka, Punjab</option>
              <option value="/1/0.474042769/-1/1.368748236">Firozabad, UP</option>
              <option value="/1/0.539839065/-1/1.302820988">
                Firozpur, Punjab</option>
              <option value="/1/0.485008818/-1/1.343484127">Firozpur
                Jhirka, Haryana</option>
              <option value="/1/0.459119048/-1/1.524271164">
                Forbesganj, Bihar</option>
              <option value="/1/0.269388448/-1/1.32054321">
                Gadag, Karnataka</option>
              <option value="/1/0.400190476/-1/1.375640212">Gadarwara, MP</option>
              <option value="/1/0.445199295/-1/1.463397707">Gahmar, UP</option>
              <option value="/1/0.274684744/-1/1.310983686">
                Gajendragarh, Karnataka</option>
              <option value="/1/0.402828924/-1/1.222251323">Gandhi Dam,
                Gujarat</option>
              <option value="/1/0.522456349/-1/1.289643298">
                Ganganagar, Rajasthan</option>
              <option value="/1/0.462203704/-1/1.339482804">
                Gangapur, Rajasthan</option>
              <option value="/1/0.269402998/-1/1.336058642">
                Gangawati, Karnataka</option>
              <option value="/1/0.520041005/-1/1.348969577">Gangoh, UP</option>
              <option value="/1/0.477175926/-1/1.537380952">
                Gangtok, Sikkim</option>
              <option value="/1/0.320702381/-1/1.457247795">Garbham, AP</option>
              <option value="/1/0.457183862/-1/1.59007716">Gauhati, Assam</option>
              <option value="/1/0.237537919/-1/1.353392857">
                Gauribidanur, Karnataka</option>
              <option value="/1/0.455491182/-1/1.570764109">
                Gauripur, Assam</option>
              <option value="/1/0.43286067/-1/1.484059083">Gaya, Bihar</option>
              <option value="/1/0.395597443/-1/1.532036155">Ghatal, WB</option>
              <option value="/1/0.500432099/-1/1.351855379">Ghaziabad, UP</option>
              <option value="/1/0.446838624/-1/1.449541005">Ghazipur, UP</option>
              <option value="/1/0.268447531/-1/1.378036155">Giddalur, AP</option>
              <option value="/1/0.627140653/-1/1.297122134">Gilgit, Jammu
                &amp; Kashmir</option>
              <option value="/1/0.422185626/-1/1.501917108">Giridih
                , Bihar</option>
              <option value="/1/0.45706261/-1/1.58233157">Goalpara
                , Assam</option>
              <option value="/1/0.397484127/-1/1.285239418">Godhra
                , Gujarat</option>
              <option value="3/1/0.461413139/-1/1.369660053">Gohad, MP</option>
              <option value="/1/0.282275132/-1/1.306395503">
                Gokak, Karnataka</option>
              <option value="/1/0.254033069/-1/1.297548942">
                Gokarn, Karnataka</option>
              <option value="/1/0.490295414/-1/1.397412257">Gola
                Gokarannath, UP</option>
              <option value="/1/0.462853616/-1/1.640736332">
                Golaghat, Assam</option>
              <option value="/1/0.473645062/-1/1.431062169">Gonda, UP</option>
              <option value="/1/0.383447972/-1/1.236248677">
                Gondal, Gujarat</option>
              <option value="/1/0.374693563/-1/1.40028351">
                Gondia, Maharashtra</option>
              <option value="/1/0.263932099/-1/1.345341711">Gooty, AP</option>
              <option value="/1/0.462111552/-1/1.474436508">
                Gopalganj, Bihar</option>
              <option value="/1/0.199983686/-1/1.352097884">
                Gopichettipalaiyam, TN</option>
              <option value="/1/0.441440476/-1/1.439263668">Gopiganj, UP</option>
              <option value="/1/0.467145944/-1/1.455530864">Gorakhpur, UP</option>
              <option value="/1/0.480095679/-1/1.352481041">Govardhan, UP</option>
              <option value="/1/0.15731746/-1/1.348794974">Gudalur, TN</option>
              <option value="/1/0.287008818/-1/1.412753086">Gudivada, AP</option>
              <option value="/1/0.225951058/-1/1.376940035">
                Gudiyattam, TN</option>
              <option value="/1/0.211585097/-1/1.390277778">
                Gudraippattu, TN</option>
              <option value="/1/0.24695194/-1/1.394123898">Gudur, AP</option>
              <option value="/1/0.498986772/-1/1.358238095">Gulaothi, UP</option>
              <option value="/1/0.302635802/-1/1.341481041">
                Gulbarga, Karnataka</option>
              <option value="/1/0.402319665/-1/1.47622619">Gumla, Bihar</option>
              <option value="/1/0.240850529/-1/1.360595238">
                Gummanayakanapalaya , Karnataka</option>
              <option value="/1/0.430304674/-1/1.349857143">Guna , MP</option>
              <option value="/1/0.206157848/-1/1.339046296">Gundalpet
                , Karnataka</option>
              <option value="/1/0.264834215/-1/1.350924162">Guntakal , AP</option>
              <option value="/1/0.284574074/-1/1.397412257">Guntur, AP</option>
              <option value="/1/0.559317019/-1/1.316629189">
                Gurdaspur, Punjab</option>
              <option value="/1/0.496983686/-1/1.346500882">
                Gurgaon, Haryana</option>
              <option value="/1/0.447226631/-1/1.382415785">Gursarai, UP</option>
              <option value="/1/0.458231481/-1/1.364237654">Gwalior, MP</option>
              <option value="/1/0.398555996/-1/1.537419753">Habra, WB</option>
              <option value="/1/0.262249118/-1/1.325694004">
                Hadagalli, Karnataka</option>
              <option value="/1/0.448351852/-1/1.487686949">
                Hajipur, Bihar</option>
              <option value="/1/0.510205026/-1/1.388570547">Haldwani, UP</option>
              <option value="/1/0.267627866/-1/1.305241182">
                Haliyal, Karnataka</option>
              <option value="/1/0.453153439/-1/1.399381393">Hamirpur, UP</option>
              <option value="/1/0.257743386/-1/1.311686949">
                Hangal, Karnataka</option>
              <option value="/1/0.508143739/-1/1.326246914">
                Hansi, Haryana</option>
              <option value="/1/0.394714727/-1/1.540155203">Haora, WB</option>
              <option value="/1/0.50158642/-1/1.358058642">Hapur, UP</option>
              <option value="/1/0.389903439/-1/1.345991623">Harda Khas,
                MP</option>
              <option value="/1/0.478354497/-1/1.399085538">Hardoi, UP</option>
              <option value="/1/0.253402557/-1/1.310712081">
                Harihar, Karnataka</option>
              <option value="/1/0.159097443/-1/1.334821869">
                Haripad, Kerala</option>
              <option value="/1/0.258155644/-1/1.326639771">
                Harpanahalli, Karnataka</option>
              <option value="/1/0.210377425/-1/1.362496473">Hapur, TN</option>
              <option value="/1/0.501450617/-1/1.366929453">Hasanpur, UP</option>
              <option value="/1/0.227105379/-1/1.328633157">
                Hassan, Karnataka</option>
              <option value="/1/0.481890212/-1/1.364261905">Hathras, UP</option>
              <option value="/1/0.258194444/-1/1.310130071">
                Haveri, Karnataka</option>
              <option value="/1/0.418974868/-1/1.490601852">
                Hazaribag, Jharkhand</option>
              <option value="/1/0.419173721/-1/1.490456349">
                Hazaribagh, Jharkhand</option>
              <option value="/1/0.270334215/-1/1.311657848">
                Hebli, Karnataka</option>
              <option value="/1/0.466748236/-1/1.345011905">
                Hindaun, Rajasthan</option>
              <option value="/1/0.241335538/-1/1.345050705">Hindupur, AP</option>
              <option value="/1/0.358916226/-1/1.376537478">
                Hinganghat, Maharashtra</option>
              <option value="/1/0.34429321/-1/1.34693739">
                Hingoli, Maharashtra</option>
              <option value="/1/0.252374339/-1/1.316925044">
                Hirekerur, Karnataka</option>
              <option value="/1/0.509021605/-1/1.310716931">
                Hisar, Haryana</option>
              <option value="/1/0.433534832/-1/1.491319665">Hisua, Bihar</option>
              <option value="/1/0.487074956/-1/1.35079321">Hodal, Haryana</option>
              <option value="/1/0.223196208/-1/1.331130952">Hole
                Narsipur, Karnataka</option>
              <option value="/1/0.24859612/-1/1.320746914">
                Honnali, Karnataka</option>
              <option value="/1/0.39712037/-1/1.345642416">
                Hoshangabad, MP</option>
              <option value="/1/0.550485009/-1/1.32547575">
                Hoshiarpur, Punjab</option>
              <option value="/1/0.228191799/-1/1.358213845">
                Hoskote, Karnataka</option>
              <option value="/1/0.266638448/-1/1.333541446">
                Hospet, Karnataka</option>
              <option value="/1/0.222318342/-1/1.358858907">Hosur, TN</option>
              <option value="/1/0.267918871/-1/1.311007937">
                Hubli, Karnataka</option>
              <option value="/1/0.214747354/-1/1.332052469">
                Hunsur, Karnataka</option>
              <option value="/1/0.303712522/-1/1.362515873">Hyderabad, AP</option>
              <option value="/1/0.291213845/-1/1.300129189">Ichalkaranji
                , Maharashtra</option>
              <option value="/1/0.333656966/-1/1.478791887">Ichchapuram
                , AP</option>
              <option value="/1/0.202205026/-1/1.345923721">Idappadi, TN</option>
              <option value="/1/0.159296296/-1/1.37280776">
                Ilaiyankudi, TN</option>
              <option value="/1/0.278652116/-1/1.328875661">
                Ilkal, Karnataka</option>
              <option value="/1/0.432797619/-1/1.640236772">
                Imphal, Manipur</option>
              <option value="/1/0.396819665/-1/1.324651235">Indore, MP</option>
              <option value="/1/0.276231922/-1/1.400152557">Inkollu, AP</option>
              <option value="/1/0.18040873/-1/1.330742945">
                Irinjalakuda, Kerala</option>
              <option value="/1/0.458362434/-1/1.536808642">Islampur, WB</option>
              <option value="/1/0.394763228/-1/1.345608466">Itarsi, MP</option>
              <option value="/1/0.404444004/-1/1.395850529">Jabalpur, MP</option>
              <option value="/1/0.526797178/-1/1.3447694">
                Jagadhari, Haryana</option>
              <option value="/1/0.333239859/-1/1.43222134">Jagdalpur, MP</option>
              <option value="/1/0.444578483/-1/1.473932099">
                Jagdispur, Bihar</option>
              <option value="/1/0.537462522/-1/1.317856261">
                Jagraoh, Punjab</option>
              <option value="/1/0.440174603/-1/1.483753527">
                Jahanabad, Bihar</option>
              <option value="/1/0.455825838/-1/1.402994709">Jahanabad, UP</option>
              <option value="/1/0.495989418/-1/1.363704145">
                Jahangirabad, UP</option>
              <option value="/1/0.469769841/-1/1.310707231">
                Jaipur, Rajasthan</option>
              <option value="/1/0.458541887/-1/1.423825838">Jais, UP</option>
              <option value="/1/0.469886243/-1/1.238154762">
                Jaisalmer, Rajasthan</option>
              <option value="/1/0.517135802/-1/1.345070106">Jalabad, UP</option>
              <option value="/1/0.484111552/-1/1.353242504">Jalabad, UP</option>
              <option value="/1/0.486648148/-1/1.366240741">Jalai, UP</option>
              <option value="/1/0.204101411/-1/1.359702822">
                Jalakandapuram, TN</option>
              <option value="/1/0.456451499/-1/1.37995679">Jalaun, UP</option>
              <option value="/1/0.479688272/-1/1.362229718">Jalesar, UP</option>
              <option value="/1/0.366894621/-1/1.319369489">
                Jalgaon, Maharashtra</option>
              <option value="/1/0.346393298/-1/1.325010141">
                Jalna, Maharashtra</option>
              <option value="/1/0.442512346/-1/1.267890653">
                Jalor, Rajasthan</option>
              <option value="/1/0.46308157/-1/1.537681658">Jalpaiguri, WB</option>
              <option value="/1/0.441969136/-1/1.510079806">
                Jamalpur, Bihar</option>
              <option value="/1/0.288070988/-1/1.314538801">
                Jamkhandi, Karnataka</option>
              <option value="/1/0.259091711/-1/1.368554233">
                Jammalamadugu, AP</option>
              <option value="/1/0.571093034/-1/1.293562169">Jammu, Jammu
                &amp; Kashmir</option>
              <option value="/1/0.392231481/-1/1.222261023">Jamnagar
                , Gujarat</option>
              <option value="/1/0.397716931/-1/1.505234568">Jamshedpur
                , Jharkhand</option>
              <option value="/1/0.427384921/-1/1.537162698">Jangipur , WB</option>
              <option value="/1/0.51204321/-1/1.359198413">Jansath, UP</option>
              <option value="/1/0.412708554/-1/1.311017637">Jaora, MP</option>
              <option value="/1/0.511194444/-1/1.376333774">Jaspur, UP</option>
              <option value="/1/0.475575397/-1/1.362787478">Jasrana, UP</option>
              <option value="/1/0.469449735/-1/1.377720899">
                Jaswantnagar, UP</option>
              <option value="/1/0.449709877/-1/1.443847002">Jaunpur, UP</option>
              <option value="/1/0.195676808/-1/1.37996164">
                Jayamkondacholapuram, TN</option>
              <option value="/1/0.464148589/-1/1.503376984">
                Jayanagar, Bihar</option>
              <option value="/1/0.387070988/-1/1.543880071">Jaynagar, WB</option>
              <option value="/1/0.401674603/-1/1.223696649">
                Jetpur, Gujarat</option>
              <option value="/1/0.379781305/-1/1.233052469">
                Jetpur, Gujarat</option>
              <option value="/1/0.432462963/-1/1.50811552">Jhajha, Bihar</option>
              <option value="/1/0.499340829/-1/1.338406085">
                Jhajjar, Haryana</option>
              <option value="/1/0.426589506/-1/1.493293651">Jhalpo, Bihar</option>
              <option value="/1/0.444229277/-1/1.371789242">Jhansi, UP</option>
              <option value="/1/0.41467284/-1/1.508925485">
                Jharia, Jharkhand</option>
              <option value="/1/0.423121693/-1/1.541037919">Jiaganj, WB</option>
              <option value="/1/0.511766755/-1/1.332338624">Jind, Haryana</option>
              <option value="/1/0.458881393/-1/1.275005732">
                Jodhpur, Rajasthan</option>
              <option value="/1/0.467165344/-1/1.644926808">Jorhat, Assam</option>
              <option value="/1/0.546832892/-1/1.319631393">
                Jullundur, Punjab</option>
              <option value="/1/0.37566358/-1/1.230190917">
                Junagarh, Gujarat</option>
              <option value="/1/0.178555996/-1/1.345045855">Kadaikan, TN</option>
              <option value="/1/0.157511464/-1/1.350410053">
                Kadiyanallur, TN</option>
              <option value="/1/0.216488536/-1/1.378763668">Kadaladi, TN</option>
              <option value="/1/0.14244224/-1/1.359436067">Kadambur, TN</option>
              <option value="/1/0.24637963/-1/1.364703263">Kadiri, AP</option>
              <option value="/1/0.513260582/-1/1.348009259">Kairana, UP</option>
              <option value="/1/0.520235009/-1/1.333953704">
                Kaithal, Haryana</option>
              <option value="/1/0.486982804/-1/1.382663139">Kakala, UP</option>
              <option value="/1/0.295865079/-1/1.435912257">Kakinada, AP</option>
              <option value="/1/0.469115079/-1/1.398033069">Kakori, UP</option>
              <option value="/1/0.177532628/-1/1.334778219">
                Kaladi, Kerala</option>
              <option value="/1/0.240123016/-1/1.391650353">Kalahasti, AP</option>
              <option value="/1/0.157239859/-1/1.373040564">
                Kalaiyarkovil, TN</option>
              <option value="/1/0.139963845/-1/1.354164021">Kalakkadu, TN</option>
              <option value="/1/0.193426367/-1/1.345113757">
                Kalappatti, TN</option>
              <option value="/1/0.222953704/-1/1.386635362">Kalavai, TN</option>
              <option value="/1/0.264970018/-1/1.308936949">
                Kalghatgi, Karnataka</option>
              <option value="/1/0.194954145/-1/1.327275132">
                Kalikavu, Kerala</option>
              <option value="/1/0.472708995/-1/1.544850088">Kalimpong, WB</option>
              <option value="/1/0.53844224/-1/1.328473104">Kalka, Haryana</option>
              <option value="/1/0.204964727/-1/1.378700617">
                Kallakkurichchi, TN</option>
              <option value="/1/0.139983245/-1/1.352558642">
                Kallidaikurichchi, TN</option>
              <option value="/1/0.405855379/-1/1.265839065">
                Kallol, Gujarat</option>
              <option value="/1/0.456039242/-1/1.392402116">Kalpi, UP</option>
              <option value="/1/0.158534832/-1/1.345652116">
                Kalugumalai, TN</option>
              <option value="/1/0.253315256/-1/1.38653836">Kaluvaya, AP</option>
              <option value="/1/0.253960317/-1/1.346282628">
                Kalyandrug, AP</option>
              <option value="/1/0.254910935/-1/1.373651675">
                Kamalapuram, AP</option>
              <option value="/1/0.482826279/-1/1.34914903">
                Kaman, Rajasthan</option>
              <option value="/1/0.190399912/-1/1.348217813">
                Kamanaayakkanpalaiyam, TN</option>
              <option value="/1/0.370440035/-1/1.379680335">
                Kamthi, Maharashtra</option>
              <option value="/1/0.158263228/-1/1.362496473">Kamudi, TN</option>
              <option value="/1/0.323835538/-1/1.397160053">Kanakanur, AP</option>
              <option value="/1/0.21891358/-1/1.345045855">
                Kanakapura, Karnataka</option>
              <option value="/1/0.271847443/-1/1.334167108">
                Kanakgiri, Karnataka</option>
              <option value="/1/0.224049824/-1/1.391582451">
                Kanchipuram, TN</option>
              <option value="/1/0.399196208/-1/1.543443563">
                Kancgarapara, WB</option>
              <option value="/1/0.511975309/-1/1.34475">Kandhla, UP</option>
              <option value="/1/0.26562963/-1/1.395186067">Kandukur, AP</option>
              <option value="/1/0.2585097/-1/1.345671517">Kanekalla, AP</option>
              <option value="/1/0.192204145/-1/1.354280423">Kangayam, TN</option>
              <option value="/1/0.268917989/-1/1.38821649">Kani Giri, AP</option>
              <option value="/1/0.2254806/-1/1.384244268">Kanippettai, TN</option>
              <option value="/1/0.353886684/-1/1.423011023">Kanker, MP</option>
              <option value="/1/0.472417989/-1/1.380834656">Kannauj, UP</option>
              <option value="/1/0.465045855/-1/1.389031305">Kansua, UP</option>
              <option value="/1/0.507333774/-1/1.372759259">Kanth, UP</option>
              <option value="/1/0.380314815/-1/1.532205908">Kanthi, WB</option>
              <option value="/1/0.401960758/-1/1.275815697">
                Kapadvanj, Gujarat</option>
              <option value="/1/0.54781261/-1/1.31012037">
                Kapurthala, Punjab</option>
              <option value="/0.301796737/-1/1.295216049">
                Karad, Maharashtra</option>
              <option value="/1/0.175718695/-1/1.375397707">
                Karaikkudi, TN</option>
              <option value="/1/0.462640212/-1/1.344924603">
                Karauli, Rajasthan</option>
              <option value="/1/0.444578483/-1/1.364378307">Karera, MP</option>
              <option value="/1/0.471525573/-1/1.378147707">Karhal, UP</option>
              <option value="/1/0.190792769/-1/1.393992945">Karaikal, TN</option>
              <option value="/1/0.214892857/-1/1.365508377">
                Karimanglam, TN</option>
              <option value="/1/0.321866402/-1/1.381790123">
                Karimnagar, AP</option>
              <option value="/1/0.23044224/-1/1.309407407">
                Karkal, Karnataka</option>
              <option value="/1/0.518387125/-1/1.344124339">
                Karnal, Haryana</option>
              <option value="/1/0.157511464/-1/1.327891093">
                Karunagapalli, Kerala</option>
              <option value="/1/0.191292328/-1/1.363325838">Karur, TN</option>
              <option value="/1/0.194095679/-1/1.328967813">
                Karuvambaram, Kerala</option>
              <option value="/1/0.23431746/-1/1.387343474">
                Karvetnagar, AP</option>
              <option value="/1/0.258630952/-1/1.294347884">
                Karwar, Karnataka</option>
              <option value="/1/0.440189153/-1/1.412757937">Karwi, UP</option>
              <option value="/1/0.218118166/-1/1.309358907">
                Kasaragod, Kerala</option>
              <option value="/1/0.451291005/-1/1.528277337">Kasba, Bihar</option>
              <option value="/1/0.485522928/-1/1.373152116">Kasganj, UP</option>
              <option value="/1/0.510098325/-1/1.378642416">Kashipur, UP</option>
              <option value="/1/0.565132275/-1/1.318637125">Kathua, Jammu
                &amp; Kashmir</option>
              <option value="/1/0.446368166/-1/1.503828042">Katihar
                , Bihar</option>
              <option value="/1/0.279030423/-1/1.311900353">Katkol
                , Karnataka</option>
              <option value="/1/0.486522046/-1/1.367409612">Kauriaganj
                , UP</option>
              <option value="/1/0.260372134/-1/1.396723545">Kavali, AP</option>
              <option value="/1/0.199503527/-1/1.354227072">
                Kavundappadi, TN</option>
              <option value="/1/0.384306437/-1/1.41844224">Kawardha, MP</option>
              <option value="/1/0.140701058/-1/1.364164903">
                Kayalapatnam, TN</option>
              <option value="/1/0.158869489/-1/1.327861993">
                Kayankulam, Kerala</option>
              <option value="/1/0.141661376/-1/1.357908289">Kayattar, TN</option>
              <option value="/1/0.445281746/-1/1.509580247">
                Khagaria, Bihar</option>
              <option value="/1/0.44662037/-1/1.484820547">Khagaul, Bihar</option>
              <option value="/1/0.48785582/-1/1.35906261">Khair, UP</option>
              <option value="/1/0.480609788/-1/1.410022487">Khairabad, UP</option>
              <option value="/1/0.389597884/-1/1.267958554">
                Khambhat, Gujarat</option>
              <option value="/1/0.361389771/-1/1.327876543">
                Khamgaon, Maharashtra</option>
              <option value="/1/0.301074074/-1/1.396844797">Khammam, AP</option>
              <option value="/1/0.481962963/-1/1.310430776">
                Khandela, Rajasthan</option>
              <option value="/1/0.381018078/-1/1.333037037">Khandawa, MP</option>
              <option value="/1/0.498225309/-1/1.364257055">Khanpur, UP</option>
              <option value="/1/0.438598325/-1/1.511146825">
                Kharagpur, Bihar</option>
              <option value="/1/0.389893739/-1/1.519362875">Kharagpur, WB</option>
              <option value="/1/0.503996914/-1/1.349367284">Khekra, UP</option>
              <option value="/1/0.487167108/-1/1.41074515">Kheri, UP</option>
              <option value="/1/0.491556437/-1/1.391742504">Khudaganj, UP</option>
              <option value="/1/0.419784832/-1/1.367627866">Khurai, MP</option>
              <option value="/1/0.493268519/-1/1.345899471">Khurja, UP</option>
              <option value="/1/0.158248677/-1/1.363102734">Kilakarai, TN</option>
              <option value="/1/0.185142416/-1/1.339119048">
                Kilakkottara, Kerala</option>
              <option value="/1/0.177750882/-1/1.363068783">
                Kilsevvalapatti, TN</option>
              <option value="/1/0.141447972/-1/1.328463404">
                Kilimanur, Kerala</option>
              <option value="/1/0.202908289/-1/1.382614638">Kiranur, TN</option>
              <option value="/1/0.184550705/-1/1.375640212">Kiranur, TN</option>
              <option value="/1/0.515220018/-1/1.365479277">Kiratpur, UP</option>
              <option value="/1/0.193222663/-1/1.359862875">Kodumudi, TN</option>
              <option value="/1/0.273753527/-1/1.357903439">Kodumuru, AP</option>
              <option value="/1/0.448177249/-1/1.643282628">
                Kohima, Nagaland</option>
              <option value="/1/0.265896384/-1/1.367419312">
                Koilkuntla, AP</option>
              <option value="/1/0.139823192/-1/1.348955026">Kolachel, TN</option>
              <option value="/1/0.22936067/-1/1.364286155">
                Kolar, Karnataka</option>
              <option value="/1/0.225907407/-1/1.366463845">Kolar Gold
                Fields , Karnataka</option>
              <option value="/1/0.440339506/-1/1.355051587">Kolaras , MP</option>
              <option value="/1/0.291543651/-1/1.296016314">Kolhapur
                , Maharashtra</option>
              <option value="/1/0.212259259/-1/1.346243827">Kollegal
                , Karnataka</option>
              <option value="/1/0.296582892/-1/1.362748677">Kolur , AP</option>
              <option value="/1/0.194566138/-1/1.326319665">Kondotti
                , Kerala</option>
              <option value="/1/0.204193563/-1/1.352388889">Kongadak , TN</option>
              <option value="/1/0.45432716/-1/1.459042328">Kopagani, UP</option>
              <option value="/1/0.346960758/-1/1.300264991">
                Kopargaon, Maharashtra</option>
              <option value="/1/0.267802469/-1/1.329549824">
                Koppal, Karnataka</option>
              <option value="/1/0.485348325/-1/1.352005732">Kosi, UP</option>
              <option value="/1/0.276838183/-1/1.366551146">Kosigi, AP</option>
              <option value="/1/0.439558642/-1/1.324137125">
                Kota, Rajasthan</option>
              <option value="/1/0.519560847/-1/1.371197531">Kotdwara, UP</option>
              <option value="/1/0.533965608/-1/1.306604056">
                Kotkapura, Punjab</option>
              <option value="/1/0.30633157/-1/1.408116402">Kottagudem, AP</option>
              <option value="/1/0.192757055/-1/1.325582451">
                Kottakkal, Kerala</option>
              <option value="/1/0.238406085/-1/1.366463845">Kottakota, AP</option>
              <option value="/1/0.200546296/-1/1.346520282">
                Kottamanglam, TN</option>
              <option value="/1/0.269538801/-1/1.399701499">
                Kottapatnam, AP</option>
              <option value="/1/0.142544092/-1/1.340525573">
                Kottarakara, Kerala</option>
              <option value="/1/0.158651235/-1/1.336092593">
                Kottayam, Kerala</option>
              <option value="/1/0.258829806/-1/1.330825397">
                Kotturu, Karnataka</option>
              <option value="/1/0.15728351/-1/1.359664021">
                Kovilapatti, TN</option>
              <option value="/1/0.253324956/-1/1.380829806">Kovur, AP</option>
              <option value="/1/0.196520723/-1/1.323162257">
                Kozhikode, Kerala</option>
              <option value="/1/0.218768078/-1/1.365678131">
                Krishnagiri, TN</option>
              <option value="/1/0.40866843/-1/1.545296296">
                Krishnanagar, WB</option>
              <option value="/1/0.220926367/-1/1.33540873">
                Krishnarajpet, Karnataka</option>
              <option value="/1/0.473965168/-1/1.307001764">
                Kuchaman, Rajasthan</option>
              <option value="/1/0.26017328/-1/1.333580247">
                Kudligi, Karnataka</option>
              <option value="/1/0.270421517/-1/1.326775573">
                Kuknur, Karnataka</option>
              <option value="/1/0.140744709/-1/1.364257055">
                Kulashekharapatnam, TN</option>
              <option value="/1/0.441998236/-1/1.390559083">Kulpahar, UP</option>
              <option value="/1/0.414100529/-1/1.517296737">Kulti, WB</option>
              <option value="/1/0.189289242/-1/1.362782628">Kulumani, TN</option>
              <option value="/1/0.191340829/-1/1.386029101">
                Kumbakonam, TN</option>
              <option value="/1/0.476928571/-1/1.345055556">
                Kumher, Rajasthan</option>
              <option value="/1/0.251811728/-1/1.299037919">
                Kumta, Karnataka</option>
              <option value="/1/0.453944004/-1/1.382061728">Kunch, UP</option>
              <option value="/1/0.189395944/-1/1.352160935">Kundadam, TN</option>
              <option value="/1/0.500776455/-1/1.363088183">Kundarkhi, UP</option>
              <option value="/1/0.265590829/-1/1.312395062">
                Kundgol, Karnataka</option>
              <option value="/1/0.372588624/-1/1.245104938">
                Kundla, Gujarat</option>
              <option value="/1/0.227333333/-1/1.344813051">
                Kunigal, Karnataka</option>
              <option value="/1/0.196695326/-1/1.351617725">Kunnatur, TN</option>
              <option value="/1/0.222551146/-1/1.367909171">Kuppam, AP</option>
              <option value="/1/0.238323633/-1/1.370276014">
                Kurabalakota, AP</option>
              <option value="/1/0.478451499/-1/1.37887037">Kurauli, UP</option>
              <option value="/1/0.20187522/-1/1.389802469">
                Kurinjippadi, TN</option>
              <option value="/1/0.276265873/-1/1.362598325">Kurnool, AP</option>
              <option value="/1/0.469391534/-1/1.541377425">Kurseong, WB</option>
              <option value="/1/0.275106702/-1/1.327313933">
                Kushtagi, Karnataka</option>
              <option value="/1/0.206448854/-1/1.319374339">
                Kuttaparamba, Kerala</option>
              <option value="/1/0.142253086/-1/1.348115961">
                Kuzhittora, TN</option>
              <option value="/1/0.48564903/-1/1.310067019">
                Lachhmangarh, Rajasthan</option>
              <option value="/1/0.477685185/-1/1.341805996">
                Lachhmangarh, Rajasthan</option>
              <option value="/1/0.482520723/-1/1.298843915">
                Ladnun, Rajasthan</option>
              <option value="/1/0.457382716/-1/1.363384039">Lahar, MP</option>
              <option value="/1/0.483835097/-1/1.412626984">Laharpur, UP</option>
              <option value="/1/0.451586861/-1/1.487095238">
                Laiganj, Bihar</option>
              <option value="/1/0.488001323/-1/1.410395944">Lakhimpur, UP</option>
              <option value="/1/0.18973545/-1/1.355706349">
                Lakkamapayakkanpatti, TN</option>
              <option value="/1/0.247684303/-1/1.374097884">
                Lakkireddipalle, AP</option>
              <option value="/1/0.264130952/-1/1.310130071">
                Lakhamoshwar, Karnataka</option>
              <option value="/1/0.431129189/-1/1.362501323">Lalitpur, UP</option>
              <option value="/1/0.457867725/-1/1.364936067">Lashkar, MP</option>
              <option value="/1/0.32122619/-1/1.337125661">
                Latur, Maharashtra</option>
              <option value="/1/0.596798501/-1/1.354571429">Leh, Jammu &amp;
                Kashmir</option>
              <option value="/1/0.39380776/-1/1.253636243">
                Limbdi, Gujarat</option>
              <option value="/1/0.409056437/-1/1.478660935">
                Lohardagga, Bihar</option>
              <option value="/1/0.439505291/-1/1.503401235">
                Lukhisarai, Bihar</option>
              <option value="/1/0.468794974/-1/1.412884039">Lucknow, UP</option>
              <option value="/1/0.539625661/-1/1.324306878">
                Ludhiana, Punjab</option>
              <option value="/1/0.449603175/-1/1.62687963">Lumding, Assam</option>
              <option value="/1/0.287692681/-1/1.38711552">Macherala, AP</option>
              <option value="/1/0.448410053/-1/1.438880511">
                Machhlishahr, UP</option>
              <option value="/1/0.282580688/-1/1.41460097">
                Machilipatnam, AP</option>
              <option value="/1/0.243265873/-1/1.34475485">Madakasira, AP</option>
              <option value="/1/0.236558201/-1/1.370717372">
                Madanappale, AP</option>
              <option value="/1/0.266235891/-1/1.345026455">Maddikera, AP</option>
              <option value="/1/0.266643298/-1/1.291161376">Madgaon, Goa</option>
              <option value="/1/0.456926808/-1/1.508042769">
                Madhepura, Bihar</option>
              <option value="/1/0.499782187/-1/1.396849647">Madho Tanda,
                UP</option>
              <option value="/1/0.565093474/-1/1.31998545">
                Madhopur, Punjab</option>
              <option value="/1/0.460161817/-1/1.503343034">
                Madhubani, Bihar</option>
              <option value="/1/0.238372134/-1/1.348091711">
                Madhugiri, Karnataka</option>
              <option value="/1/0.423689153/-1/1.512601852">
                Madhupur, Jharkhand</option>
              <option value="/1/0.228055996/-1/1.401151675">Madras, TN</option>
              <option value="/1/0.182998677/-1/1.386339506">Madukkur, TN</option>
              <option value="/1/0.158549383/-1/1.364029101">Madurai, TN</option>
              <option value="/1/0.218380071/-1/1.394924162">
                Madurantakam, TN</option>
              <option value="/1/0.226091711/-1/1.3447403">
                Magedi, Karnataka</option>
              <option value="/1/0.220286155/-1/1.400210758">
                Mahabalipuram, TN</option>
              <option value="/1/0.47903836/-1/1.35739418">Mahaban, UP</option>
              <option value="/1/0.455893739/-1/1.467554233">
                Maharajganj, Bihar</option>
              <option value="/1/0.436847443/-1/1.392115961">
                Maharajpur, MP</option>
              <option value="/1/0.292314815/-1/1.345943122">
                Mahbunagar, AP</option>
              <option value="/1/0.412053792/-1/1.263976631">
                Mahesana, Gujarat</option>
              <option value="/1/0.476637566/-1/1.416385802">
                Mahmudabad, UP</option>
              <option value="/1/0.441566578/-1/1.394594356">Mahoba, UP</option>
              <option value="/1/0.368165344/-1/1.253059083">
                Mahuva, Gujarat</option>
              <option value="/1/0.423727954/-1/1.410041887">Maihar, MP</option>
              <option value="/1/0.475425044/-1/1.379840388">Mainpur, UP</option>
              <option value="/1/0.252611993/-1/1.39864418">Maipadu, AP</option>
              <option value="/1/0.472160935/-1/1.304712522">
                Makrana, Rajasthan</option>
              <option value="/1/0.21615873/-1/1.345399912">
                Malavalli, Karnataka</option>
              <option value="/1/0.358809524/-1/1.301351411">
                Malegaon, Maharashtra</option>
              <option value="/1/0.532995591/-1/1.324859788">Maler
                Kotla, Punjab</option>
              <option value="/1/0.43725485/-1/1.391145944">Malhara, MP</option>
              <option value="/1/0.29533157/-1/1.29084612">
                Malkapur, Maharashtra</option>
              <option value="/1/0.472131834/-1/1.399410494">Mallanwan, UP</option>
              <option value="/1/0.226794974/-1/1.360643739">
                Malur, Karnataka</option>
              <option value="/1/0.204984127/-1/1.362705026">Malur, TN</option>
              <option value="/1/0.157579365/-1/1.369892857">
                Manamudarai, TN</option>
              <option value="/1/0.185195767/-1/1.369291446">
                Manapparai, TN</option>
              <option value="/1/0.184720459/-1/1.327517637">
                Manattala, Kerala</option>
              <option value="/1/0.440184303/-1/1.310993386">
                Mandalgarh, Rajasthan</option>
              <option value="/1/0.329350088/-1/1.474790564">Mandasa, AP</option>
              <option value="/1/0.553661817/-1/1.343387125">Mandi, HP</option>
              <option value="/1/0.394447972/-1/1.403387566">Mandla, MP</option>
              <option value="/1/0.398672399/-1/1.210805115">
                Mandvi, Gujarat</option>
              <option value="/1/0.218646825/-1/1.342645062">
                Mandya, Karnataka</option>
              <option value="/1/0.224699735/-1/1.30677381">
                Mangalore, Karnataka</option>
              <option value="/1/0.520133157/-1/1.359799824">Manglaur, UP</option>
              <option value="/1/0.368795855/-1/1.224123457">
                Mangrol, Gujarat</option>
              <option value="/1/0.437579806/-1/1.415852293">Manikpur, UP</option>
              <option value="/1/0.353566578/-1/1.292689153">
                Manmad, Maharashtra</option>
              <option value="/1/0.190438713/-1/1.374107584">
                Mannachchanellur, TN</option>
              <option value="/1/0.196850529/-1/1.380276896">
                Mannargudi, TN</option>
              <option value="/1/0.186287037/-1/1.387309524">
                Mannargudi, TN</option>
              <option value="/1/0.276726631/-1/1.311628748">
                Manoli, Karnataka</option>
              <option value="/1/0.523402116/-1/1.316386684">Mansa, Punjab</option>
              <option value="/1/0.279088624/-1/1.345225309">
                Manvi, Karnataka</option>
              <option value="/1/0.21284612/-1/1.395806878">Marakkanam, TN</option>
              <option value="/1/0.446984127/-1/1.432657848">Mariahu, UP</option>
              <option value="/1/0.465162257/-1/1.646779541">
                Mariana, Assam</option>
              <option value="/1/0.274772046/-1/1.384147266">Markapur, AP</option>
              <option value="/1/0.269029541/-1/1.288372575">Marmagao, Goa</option>
              <option value="/1/0.278564815/-1/1.338430335">
                Maski, Karnataka</option>
              <option value="/1/0.480032628/-1/1.345613316">Mathura, UP</option>
              <option value="/1/0.158850088/-1/1.331460758">
                Mattancheri, Kerala</option>
              <option value="/1/0.452949735/-1/1.459018078">Mau, UP</option>
              <option value="/1/0.448924162/-1/1.415784392">Mau Aimma,
                UP</option>
              <option value="/1/0.440659612/-1/1.381639771">Mau Ranipur,
                UP</option>
              <option value="/1/0.448405203/-1/1.398843034">Maudaha, UP</option>
              <option value="/1/0.46144709/-1/1.412098325">Maurawan, UP</option>
              <option value="/1/0.508056437/-1/1.360435185">Mawana, UP</option>
              <option value="/1/0.193770723/-1/1.380247795">Mayuram, TN</option>
              <option value="/1/0.206550705/-1/1.360968695">Mechcheri, TN</option>
              <option value="/1/0.470919312/-1/1.451054233">Mehndawal, UP</option>
              <option value="/1/0.1402597/-1/1.356783069">Mel Palaiyam,
                TN</option>
              <option value="/1/0.175107584/-1/1.367904321">Melur, TN</option>
              <option value="/1/0.20187037/-1/1.329486772">
                Meppadi, Kerala</option>
              <option value="/1/0.216798942/-1/1.310687831">
                Mercara, Karnataka</option>
              <option value="/1/0.197287037/-1/1.328468254">
                Mettupalaiyam, TN</option>
              <option value="/1/0.205735891/-1/1.345618166">Mettur, TN</option>
              <option value="/1/0.393701058/-1/1.322817901">Mhow, MP</option>
              <option value="/1/0.293900794/-1/1.297398589">
                Miraj, Maharashtra</option>
              <option value="/1/0.511373898/-1/1.361017196">Miranpur, UP</option>
              <option value="/1/0.460477072/-1/1.467287478">
                Mirganj, Bihar</option>
              <option value="/1/0.439093034/-1/1.44157716">Mirzapur, UP</option>
              <option value="/1/0.478970459/-1/1.406132716">Misrikh, UP</option>
              <option value="/1/0.537996032/-1/1.312501764">Moga, Punjab</option>
              <option value="/1/0.19307231/-1/1.364392857">Mohanur, TN</option>
              <option value="/1/0.44331261/-1/1.485620811">Mokameh, Bihar</option>
              <option value="/1/0.443167108/-1/1.509803351">
                Monghyr, Bihar</option>
              <option value="/1/0.503492504/-1/1.363093034">Moradabad, UP</option>
              <option value="/1/0.457945326/-1/1.365799383">Morar, MP</option>
              <option value="/1/0.398337743/-1/1.236675485">
                Morbi, Gujarat</option>
              <option value="/1/0.465254409/-1/1.468126543">
                Motihari, Bihar</option>
              <option value="/1/0.455491182/-1/1.45422134">Mubarakpur, UP</option>
              <option value="/1/0.157254409/-1/1.370877425">
                Mudukulattur, TN</option>
              <option value="/1/0.441285273/-1/1.451219136">Mughai Sarai
                , UP</option>
              <option value="/1/0.2117403/-1/1.343450176">
                Muguru, Karnataka</option>
              <option value="/1/0.488078924/-1/1.400448413">Muhamadi, UP</option>
              <option value="/1/0.454530864/-1/1.45585097">
                Muhammadabad, UP</option>
              <option value="/1/0.447284832/-1/1.462403439">
                Muhammadabad, UP</option>
              <option value="/1/0.531986772/-1/1.292946208">
                Muktsar, Punjab</option>
              <option value="/1/0.140453704/-1/1.345637566">
                Mulaikkaraippatti, TN</option>
              <option value="/1/0.188387125/-1/1.345623016">Mulanur, TN</option>
              <option value="/1/0.229792328/-1/1.368690035">
                Mulbagal, Karnataka</option>
              <option value="/1/0.228526455/-1/1.293227513">
                Mulki, Karnataka</option>
              <option value="/1/0.32388889/-1/1.266571428">
                Mumbai, Maharashtra</option>
              <option value="/1/0.265411376/-1/1.324864638">
                Mundargi, Karnataka</option>
              <option value="/1/0.426148148/-1/1.363558642">Mungaoli, MP</option>
              <option value="/1/0.448075397/-1/1.401263228">Mungra
                Badshahpur, UP</option>
              <option value="/1/0.267409612/-1/1.327570988">
                Munirabad, Karnataka</option>
              <option value="/1/0.502561287/-1/1.353140653">
                Muradnagar, UP</option>
              <option value="/1/0.606935185/-1/1.353441358">Murgi, Jammu
                &amp; Kashmir</option>
              <option value="/1/0.277347443/-1/1.308223986">
                Murgod, Karnataka</option>
              <option value="/1/0.452202822/-1/1.518873016">
                Murliganj, Bihar</option>
              <option value="/1/0.421972222/-1/1.541251323">
                Murshidabad, WB</option>
              <option value="/1/0.416181217/-1/1.403727072">Murwara, MP</option>
              <option value="/1/0.191214727/-1/1.369660053">Musiri, TN</option>
              <option value="/1/0.53187522/-1/1.363141534">Mussorie, UP</option>
              <option value="/1/0.249473986/-1/1.398488977">Muttukuru, AP</option>
              <option value="/1/0.181868607/-1/1.388463845">Muttupet, TN</option>
              <option value="/1/0.185258818/-1/1.34091843">
                Muvatagupudur, Kerala</option>
              <option value="/1/0.159698854/-1/1.336999559">
                Muvattupula, Kerala</option>
              <option value="/1/0.514526455/-1/1.356608466">
                Muzaffarnagar, UP</option>
              <option value="/1/0.456092593/-1/1.490752205">
                Muzaffarpur, Bihar</option>
              <option value="/1/0.214815256/-1/1.338260582">
                Mysore, Karnataka</option>
              <option value="/1/0.530366843/-1/1.329598325">Nabha, Punjab</option>
              <option value="/1/0.475294092/-1/1.347858907">
                Nadbai, Rajasthan</option>
              <option value="/1/0.396164903/-1/1.272110229">
                Nadiad, Gujarat</option>
              <option value="/1/0.233686949/-1/1.393221781">
                Nagalapuram, TN</option>
              <option value="/1/0.188367725/-1/1.394070547">
                Nagappattinam, TN</option>
              <option value="/1/0.232532628/-1/1.380281746">Nagari, AP</option>
              <option value="/1/0.474896384/-1/1.275786596">
                Nagaur, Rajasthan</option>
              <option value="/1/0.139876543/-1/1.345041005">Nagercoil, TN</option>
              <option value="/1/0.514152998/-1/1.362525573">Nagina, UP</option>
              <option value="/1/0.369382716/-1/1.380917108">
                Nagpur, Maharashtra</option>
              <option value="/1/0.513294533/-1/1.38712522">Nainital, UP</option>
              <option value="/1/0.517092152/-1/1.367787919">Najibabad, UP</option>
              <option value="/1/0.424130511/-1/1.533525132">Nalhati, WB</option>
              <option value="/1/0.195914462/-1/1.364785714">Namakkal, TN</option>
              <option value="/1/0.198329806/-1/1.350065697">Nambiyur, TN</option>
              <option value="/1/0.249027778/-1/1.381993827">Nandalur, AP</option>
              <option value="/1/0.33441843/-1/1.349784392">
                Nanded, Maharashtra</option>
              <option value="/1/0.276838183/-1/1.366551146">
                Nandikotkur, AP</option>
              <option value="/1/0.373054233/-1/1.296287919">
                Nandurbar, Maharashtra</option>
              <option value="/1/0.270377866/-1/1.362515873">Nandyal, AP</option>
              <option value="/1/0.211594797/-1/1.338886243">
                Nanjangud, Karnataka</option>
              <option value="/1/0.189924603/-1/1.390030423">Nannilam, TN</option>
              <option value="/1/0.486560847/-1/1.422933422">Nanpara, UP</option>
              <option value="/1/0.287028219/-1/1.426590388">Narasapur, AP</option>
              <option value="/1/0.283492504/-1/1.397776014">
                Narasaropet, AP</option>
              <option value="/1/0.271852293/-1/1.310716931">
                Naregal, Karnataka</option>
              <option value="/1/0.27446164/-1/1.31012037">
                Nargund, Karnataka</option>
              <option value="/1/0.489548501/-1/1.32882716">
                Narnaul, Haryana</option>
              <option value="/1/0.400617284/-1/1.382750441">
                Narsimhapur, MP</option>
              <option value="/1/0.213272928/-1/1.328477954">
                Narsipur, Karnataka</option>
              <option value="/1/0.349216049/-1/1.288236772">
                Nasik, Maharashtra</option>
              <option value="/1/0.459157848/-1/1.304702822">
                Nasirabad, Rajasthan</option>
              <option value="/1/0.178439594/-1/1.365954586">Nattam, TN</option>
              <option value="/1/0.478946208/-1/1.456476631">Nautanwa, UP</option>
              <option value="/1/0.408697531/-1/1.543099206">Navadwip, WB</option>
              <option value="/1/0.271619489/-1/1.31010582">
                Navalgund, Karnataka</option>
              <option value="/1/0.365822751/-1/1.2732306">
                Navsari, Gujarat</option>
              <option value="/1/0.498361111/-1/1.39040388">Nawabganj, UP</option>
              <option value="/1/0.469813492/-1/1.417593474">Nawabganj, UP</option>
              <option value="/1/0.469081129/-1/1.434224427">Nawabganj, UP</option>
              <option value="/1/0.434349647/-1/1.493599206">Nawada, Bihar</option>
              <option value="/1/0.48618254/-1/1.314242945">
                Nawalgarh, Rajasthan</option>
              <option value="/1/0.242819665/-1/1.394982363">Nayudupeta
                , AP</option>
              <option value="/1/0.140739859/-1/1.361453704">Nazareth, TN</option>
              <option value="/1/0.228710758/-1/1.351200617">
                Nelamangala, Karnataka</option>
              <option value="/1/0.205595238/-1/1.391131393">
                Nellikkuppam, TN</option>
              <option value="/1/0.252291887/-1/1.380863757">Nellore, AP</option>
              <option value="/1/0.501707672/-1/1.394904762">Neoria
                Husainpur, UP</option>
              <option value="/1/0.498817019/-1/1.34473545">New
                Delhi, Capital City</option>
              <option value="/1/0.202675485/-1/1.387940035">Neyveli, TN</option>
              <option value="/1/0.295103616/-1/1.42612963">Nidadavole, AP</option>
              <option value="/1/0.188018519/-1/1.38657231">
                Nidamangalam, TN</option>
              <option value="/1/0.196768078/-1/1.330869048">
                Nilambur, Kerala</option>
              <option value="/1/0.484111552/-1/1.310697531">Nim Kathana,
                Rajasthan</option>
              <option value="/1/0.427132716/-1/1.307147266">Nimach, MP</option>
              <option value="/1/0.333598765/-1/1.362511023">Nirmal, AP</option>
              <option value="/1/0.459371252/-1/1.511549383">
                Nirmalj, Bihar</option>
              <option value="/1/0.325925926/-1/1.363592593">Nizamabad, AP</option>
              <option value="/1/0.277657848/-1/1.398008818">
                Nizampatam, AP</option>
              <option value="/1/0.457513668/-1/1.601678571">North
                Guwahati, Assam</option>
              <option value="/1/0.460084215/-1/1.618435626">
                Nowgong, Assam</option>
              <option value="/1/0.437526455/-1/1.386994268">Nowgong, MP</option>
              <option value="/1/0.563905203/-1/1.32492769">Nurpur, HP</option>
              <option value="/1/0.293061728/-1/1.411661817">Nuzvid, AP</option>
              <option value="/1/0.247029541/-1/1.319505291">
                Nyamti, Karnataka</option>
              <option value="/1/0.270518519/-1/1.397674162">Ongole, AP</option>
              <option value="/1/0.199251323/-1/1.339109347">
                Ootacamund, TN</option>
              <option value="/1/0.453682099/-1/1.37997134">Orai, UP</option>
              <option value="/1/0.245559965/-1/1.384098765">Orampadu, AP</option>
              <option value="/1/0.188052469/-1/1.333565697">
                Ottapalam, Kerala</option>
              <option value="/1/0.46972134/-1/1.46630291">Padrauna, UP</option>
              <option value="/1/0.234880071/-1/1.381382716">Pakala, AP</option>
              <option value="/1/0.288439594/-1/1.415459436">Palakollu, AP</option>
              <option value="/1/0.456019841/-1/1.598254409">
                Palashari, Assam</option>
              <option value="/1/0.140623457/-1/1.345627866">
                Palayankottai, TN</option>
              <option value="/1/0.188013668/-1/1.338294533">
                Palghat, Kerala</option>
              <option value="/1/0.375915785/-1/1.240890212">
                Palitana, Gujarat</option>
              <option value="/1/0.324776455/-1/1.46247134">Palkonda, AP</option>
              <option value="/1/0.191874339/-1/1.349473986">Palladam, TN</option>
              <option value="/1/0.214766755/-1/1.363175485">
                Pallakkodu, TN</option>
              <option value="/1/0.225310847/-1/1.378375661">
                Pallikondaj, TN</option>
              <option value="/1/0.230568342/-1/1.374927249">Palmaner, AP</option>
              <option value="/1/0.182465168/-1/1.353354056">Palni, TN</option>
              <option value="/1/0.192800705/-1/1.380601852">Paluvur, TN</option>
              <option value="/1/0.491304233/-1/1.350186949">
                Palwal, Haryana</option>
              <option value="/1/0.159141093/-1/1.383075397">Pamban, TN</option>
              <option value="/1/0.260852293/-1/1.354852734">Pamidi, AP</option>
              <option value="/1/0.142354938/-1/1.354474427">
                Panaikkudi, TN</option>
              <option value="/1/0.270494268/-1/1.275791446">Panaji, Goa</option>
              <option value="/1/0.157676367/-1/1.378584215">
                Panalkkulam, TN</option>
              <option value="/1/0.158190476/-1/1.363767196">
                Pandalkudi, TN</option>
              <option value="/1/0.308591711/-1/1.315203263">
                Pandharpur, Maharashtra</option>
              <option value="/1/0.513076279/-1/1.328448854">
                Panipat, Haryana</option>
              <option value="/1/0.431551146/-1/1.397140653">Panna, MP</option>
              <option value="/1/0.388467813/-1/1.481585538">
                Panposh, Orissa</option>
              <option value="/1/0.205580688/-1/1.389036155">Panruti, TN</option>
              <option value="/1/0.331605379/-1/1.276465608">
                Panvel, Maharashtra</option>
              <option value="/1/0.190695767/-1/1.384084215">Papansam, TN</option>
              <option value="/1/0.140482804/-1/1.351263668">Papansam, TN</option>
              <option value="/1/0.202864638/-1/1.361667108">
                Pappambadi, TN</option>
              <option value="/1/0.182397266/-1/1.345065256">
                Pappampatti, TN</option>
              <option value="/1/0.179603616/-1/1.329559524">
                Pappinivatti, Kerala</option>
              <option value="/1/0.15784127/-1/1.3722597">Paramagudi, TN</option>
              <option value="/1/0.19471649/-1/1.362268519">Paramati, TN</option>
              <option value="/1/0.387415344/-1/1.374898148">Parasia, MP</option>
              <option value="/1/0.336246914/-1/1.340491623">
                Parbhani, Maharashtra</option>
              <option value="/1/0.327880511/-1/1.467869489">
                Parklakimidi, Orissa</option>
              <option value="/1/0.17710582/-1/1.330762346">Parur, Kerala</option>
              <option value="/1/0.327909612/-1/1.456762787">
                Parvatipuram, AP</option>
              <option value="/1/0.416438272/-1/1.259145944">
                Patan, Gujarat</option>
              <option value="/1/0.563328042/-1/1.320858466">
                Pathankot, Punjab</option>
              <option value="/1/0.561106702/-1/1.334181658">Pathiar, HP</option>
              <option value="/1/0.529406526/-1/1.333880952">
                Patiala, Punjab</option>
              <option value="/1/0.447226631/-1/1.485615961">Patna, Bihar</option>
              <option value="/1/0.157438713/-1/1.34143739">
                Pattanapuram, Kerala</option>
              <option value="/1/0.268728836/-1/1.353232804">
                Pattikonda, AP</option>
              <option value="/1/0.181965608/-1/1.384942681">
                Pattukkottai, TN</option>
              <option value="/1/0.489994709/-1/1.398678131">Pawayan, UP</option>
              <option value="/1/0.36297575/-1/1.390549383">
                Pawni, Maharashtra</option>
              <option value="/1/0.203378748/-1/1.370785273">Pedda
                Nayakkanpalaiyam , TN</option>
              <option value="/1/0.324926808/-1/1.385970899">
                Peddapalli, AP</option>
              <option value="/1/0.298222222/-1/1.434209877">
                Peddepuram, AP</option>
              <option value="/1/0.19910582/-1/1.383560406">Pennadam, TN</option>
              <option value="/1/0.211808201/-1/1.360085979">
                Pennagaram, TN</option>
              <option value="/1/0.24583642/-1/1.345351411">Penukonda, AP</option>
              <option value="/1/0.196263668/-1/1.376944885">Peramblur, TN</option>
              <option value="/1/0.225766755/-1/1.363112434">
                Peranambattu, TN</option>
              <option value="/1/0.191544533/-1/1.330878748">
                Perintalmanna, Kerala</option>
              <option value="/1/0.176654762/-1/1.353887566">
                Periyakulam, TN</option>
              <option value="/1/0.208723545/-1/1.36072619">Perumbalai, TN</option>
              <option value="/1/0.392444885/-1/1.271096561">
                Petlad, Gujarat</option>
              <option value="/1/0.546279982/-1/1.320451058">
                Phagwara, Punjab</option>
              <option value="/1/0.473776014/-1/1.263414021">
                Phaliodi, Rajasthan</option>
              <option value="/1/0.31405291/-1/1.299576279">
                Phaltan, Maharashtra</option>
              <option value="/1/0.464497795/-1/1.387362875">Phaphund, UP</option>
              <option value="/1/0.529324074/-1/1.313651235">Phul, Punjab</option>
              <option value="/1/0.446111111/-1/1.43332716">Phulpur, UP</option>
              <option value="/1/0.48233157/-1/1.400244709">Pihani, UP</option>
              <option value="/1/0.495237654/-1/1.310430776">
                Pilani, Rajasthan</option>
              <option value="/1/0.49994709/-1/1.380553351">Pilibhit, UP</option>
              <option value="/1/0.501324515/-1/1.355900353">Pilkhua, UP</option>
              <option value="/1/0.298852734/-1/1.436276014">
                Pithampuram, AP</option>
              <option value="/1/0.272473104/-1/1.380262346">Podile, AP</option>
              <option value="/1/0.186025132/-1/1.344497795">Pollachi, TN</option>
              <option value="/1/0.218428571/-1/1.381571869">Polur, TN</option>
              <option value="/1/0.208350088/-1/1.393978395">
                Pondicherry, Pondicherry City</option>
              <option value="/1/0.179361111/-1/1.371435185">
                Ponnamaravatti, TN</option>
              <option value="/1/0.188003968/-1/1.325708554">
                Ponnani, Kerala</option>
              <option value="/1/0.232828483/-1/1.400186508">Ponneri, TN</option>
              <option value="/1/0.22772619/-1/1.396835097">
                Poonamallee, TN</option>
              <option value="/1/0.377899471/-1/1.215441799">
                Porbandar, Gujarat</option>
              <option value="/1/0.203732804/-1/1.619667549">Port
                Blair, Andaman and Nicobar Island</option>
              <option value="/1/0.200623898/-1/1.392644621">Porto Novo
                , TN</option>
              <option value="/1/0.257471781/-1/1.371464286">Proddatur, AP</option>
              <option value="/1/0.182896825/-1/1.345627866">Pudu
                Chattram, TN</option>
              <option value="/1/0.181228395/-1/1.376382275">
                Pudukkottai, TN</option>
              <option value="/1/0.194352734/-1/1.339992063">Pudur, TN</option>
              <option value="/1/0.199246473/-1/1.363088183">Pulambadi, TN</option>
              <option value="/1/0.36189903/-1/1.362239418">
                Pulgaon, Maharashtra</option>
              <option value="/1/0.251748677/-1/1.365843034">
                Pulivendla, AP</option>
              <option value="/1/0.157254409/-1/1.351394621">
                Puliyangudi, TN</option>
              <option value="/1/0.191268078/-1/1.341238536">
                Puluvappatti, TN</option>
              <option value="/1/0.157734568/-1/1.328458554">
                Punalur, Kerala</option>
              <option value="/1/0.589727072/-1/1.293673721">Punch, Jammu
                &amp; Kashmir</option>
              <option value="/1/0.323636684/-1/1.276097002">Pune
                , Maharashtra</option>
              <option value="/1/0.233274691/-1/1.371857143">Punganuru
                , AP</option>
              <option value="/1/0.198160053/-1/1.34477425">Punjai
                Puliyampatti , TN</option>
              <option value="/1/0.497856702/-1/1.399420194">Puranpur, UP</option>
              <option value="/1/0.345612434/-1/1.498594797">Puri, Orissa</option>
              <option value="/1/0.328937831/-1/1.336388448">
                Purli, Maharashtra</option>
              <option value="/1/0.450059083/-1/1.527181217">Purnea, Bihar</option>
              <option value="/1/0.407329806/-1/1.508037919">Purulia, WB</option>
              <option value="/1/0.461941799/-1/1.397999118">Purwa, UP</option>
              <option value="/1/0.34759612/-1/1.354401675">
                Pusad, Maharashtra</option>
              <option value="/1/0.234361111/-1/1.389681217">Puttur, AP</option>
              <option value="/1/0.222706349/-1/1.312986772">
                Puttur, Karnataka</option>
              <option value="/1/0.142282187/-1/1.345671517">Puvar, Kerala</option>
              <option value="/1/0.19964903/-1/1.321585979">
                Quilandi, Kerala</option>
              <option value="/1/0.140497354/-1/1.337256614">
                Quilon, Kerala</option>
              <option value="/1/0.524304233/-1/1.347121693">
                Radaur, Haryana</option>
              <option value="/1/0.14132672/-1/1.356356261">Radhapuram, TN</option>
              <option value="/1/0.457940476/-1/1.41458157">Rae Bareli,
                UP</option>
              <option value="/1/0.282978395/-1/1.350628307">
                Raichur, Karnataka</option>
              <option value="/1/0.44714418/-1/1.538671076">Raiganj, WB</option>
              <option value="/1/0.381973545/-1/1.453789683">Raigarh, MP</option>
              <option value="/1/0.535047178/-1/1.320130952">
                Raikot, Punjab</option>
              <option value="/1/0.370891093/-1/1.425474868">Raipur, MP</option>
              <option value="/1/0.368369048/-1/1.414993827">Raj
                Nandgaon, MP</option>
              <option value="/1/0.29701455/-1/1.427832011">
                Rajahmundry, AP</option>
              <option value="/1/0.469667989/-1/1.364863316">
                Rajakhera, Rajasthan</option>
              <option value="/1/0.247854056/-1/1.38215873">Rajampet, AP</option>
              <option value="/1/0.159223545/-1/1.35411552">
                Rajapalaiyam, TN</option>
              <option value="/1/0.443079806/-1/1.416754409">Rajapur, UP</option>
              <option value="/1/0.430261023/-1/1.492769841">
                Rajauli, Bihar</option>
              <option value="/1/0.277381393/-1/1.358810406">Rajavolu, AP</option>
              <option value="/1/0.389306878/-1/1.236161376">
                Rajkot, Gujarat</option>
              <option value="/1/0.437628307/-1/1.533520282">
                Rajmahal, Bihar</option>
              <option value="/1/0.178890653/-1/1.376052469">
                Ramachandrapuram, TN</option>
              <option value="/1/0.221998236/-1/1.349275132">Ramanagaram
                , Karnataka</option>
              <option value="/1/0.157618166/-1/1.376547178">
                Ramanathapuram, TN</option>
              <option value="/1/0.159194444/-1/1.38487963">
                Rameshwaram, TN</option>
              <option value="/1/0.41252425/-1/1.493119048">
                Ramgarh, Jharkhand</option>
              <option value="/1/0.475793651/-1/1.312627866">
                Ramgarh, Rajasthan</option>
              <option value="/1/0.441178571/-1/1.449730159">Ramnagar, UP</option>
              <option value="/1/0.520390212/-1/1.352253086">Rampur, UP</option>
              <option value="/1/0.502958995/-1/1.382013228">Rampur, UP</option>
              <option value="/1/0.422083774/-1/1.532661817">RampurHat,
                WB</option>
              <option value="/1/0.505839947/-1/1.386305556">Rampura, UP</option>
              <option value="/1/0.373645944/-1/1.385078483">
                Ramtek, Maharashtra</option>
              <option value="/1/0.404584656/-1/1.546567019">Ranaghat, WB</option>
              <option value="/1/0.407819665/-1/1.498793651">
                Ranchi, Jharkhand</option>
              <option value="/1/0.25517769/-1/1.320344356">
                Ranibennur, Karnataka</option>
              <option value="/1/0.412208995/-1/1.521201058">Raniganj, WB</option>
              <option value="/1/0.440785714/-1/1.380335097">Ranipur, UP</option>
              <option value="/1/0.45541358/-1/1.523068342">
                Ranjganj, Bihar</option>
              <option value="/1/0.200070988/-1/1.365159171">Rasipuram, TN</option>
              <option value="/1/0.451470459/-1/1.464042769">Rasra, UP</option>
              <option value="/1/0.446867725/-1/1.38930776">Rath, UP</option>
              <option value="/1/0.407373457/-1/1.310256173">Ratlam, MP</option>
              <option value="/1/0.296684744/-1/1.279705467">
                Ratnairi, Maharashtra</option>
              <option value="/1/0.388492063/-1/1.468136243">
                Raurkela, Orissa</option>
              <option value="/1/0.471215168/-1/1.468150794">Raxaul, Bihar</option>
              <option value="/1/0.245526014/-1/1.363102734">Rayachoti, AP</option>
              <option value="/1/0.256608466/-1/1.341742945">Rayadrug, AP</option>
              <option value="/1/0.445868607/-1/1.461656526">Reotipur, UP</option>
              <option value="/1/0.450320988/-1/1.467554233">
                Revelganj, Bihar</option>
              <option value="/1/0.428199735/-1/1.419324956">Rewa, MP</option>
              <option value="/1/0.492172399/-1/1.337659171">
                Rewari, Haryana</option>
              <option value="/1/0.430964286/-1/1.45035097">
                Robertsganj, UP</option>
              <option value="/1/0.504467372/-1/1.337305115">
                Rohtak, Haryana</option>
              <option value="/1/0.274015432/-1/1.322284392">
                Ron, Karnataka</option>
              <option value="/1/0.521350529/-1/1.360061728">Roorkee, UP</option>
              <option value="/1/0.467034392/-1/1.427351852">Rudauli, UP</option>
              <option value="/1/0.449685626/-1/1.502009259">Rusera, Bihar</option>
              <option value="/1/0.458246032/-1/1.351414021">Sabalgarh, MP</option>
              <option value="/1/0.479135362/-1/1.364242504">Sadabad, UP</option>
              <option value="/1/0.530580247/-1/1.348179012">
                Sadhaura, Haryana</option>
              <option value="/1/0.466854938/-1/1.402810406">Safipur, UP</option>
              <option value="/1/0.247204145/-1/1.310003968">
                Sagar, Karnataka</option>
              <option value="/1/0.41636552/-1/1.375048501">Sagar, MP</option>
              <option value="/1/0.467267196/-1/1.479606702">
                Sagauli, Bihar</option>
              <option value="/1/0.523232363/-1/1.353950617">
                Saharanpur, UP</option>
              <option value="/1/0.490086861/-1/1.37498545">Sahaswan, UP</option>
              <option value="/1/0.451072751/-1/1.47181746">Sahatwar, UP</option>
              <option value="/1/0.485387125/-1/1.363364638">Sahawar, UP</option>
              <option value="/1/0.440693563/-1/1.530023369">
                Sahibganj, Bihar</option>
              <option value="/1/0.445985009/-1/1.45308642">Saidpur, UP</option>
              <option value="/1/0.225897707/-1/1.310716931">
                Sakleshpur, Karnataka</option>
              <option value="/1/0.2035/-1/1.364669312">Salem, TN</option>
              <option value="/1/0.323311728/-1/1.452863316">Salur, AP</option>
              <option value="/1/0.297756614/-1/1.434840388">Samalkot, AP</option>
              <option value="/1/0.526559524/-1/1.330272487">
                Samana, Punjab</option>
              <option value="/1/0.451412257/-1/1.497726631">
                Samastipur, Bihar</option>
              <option value="/1/0.374780864/-1/1.466259259">
                Sambalpur, Orissa</option>
              <option value="/1/0.499078924/-1/1.37171164">Sambhal, UP</option>
              <option value="/1/0.469798942/-1/1.309834215">
                Sambahr, Rajasthan</option>
              <option value="/1/0.451286155/-1/1.363384039">Samthar, UP</option>
              <option value="/1/0.476467813/-1/1.396000882">Sand, UP</option>
              <option value="/1/0.472607143/-1/1.405754409">Sandila, UP</option>
              <option value="/1/0.34155291/-1/1.295701058">
                Sangamner, Maharashtra</option>
              <option value="/1/0.307728395/-1/1.36344224">Sangareddi, AP</option>
              <option value="/1/0.294279101/-1/1.301914021">
                Sangli, Maharashtra</option>
              <option value="/1/0.528/-1/1.324238977">Sangrur, Punjab</option>
              <option value="/1/0.158534832/-1/1.353882716">
                Sankaranayinarkovil, TN</option>
              <option value="/1/0.405816578/-1/1.544287478">Santipur, WB</option>
              <option value="/1/0.497473545/-1/1.382018078">Saraul, UP</option>
              <option value="/1/0.496576279/-1/1.300619048">
                Sardarshahr, Rajasthan</option>
              <option value="/1/0.508885802/-1/1.355032187">Sardhana, UP</option>
              <option value="/1/0.435547619/-1/1.466933422">
                Sasaram, Bihar</option>
              <option value="/1/0.308897266/-1/1.293242063">
                Satara, Maharashtra</option>
              <option value="/1/0.428839947/-1/1.411239859">Satna, MP</option>
              <option value="/1/0.141472222/-1/1.360362434">
                Sattankulam, TN</option>
              <option value="/1/0.157472663/-1/1.360512787">Sattur, TN</option>
              <option value="/1/0.200895503/-1/1.348673721">
                Satyamangalam, TN</option>
              <option value="/1/0.275218254/-1/1.311551146">
                Saundatti, Karnataka</option>
              <option value="/1/0.277570547/-1/1.288852734">
                Savantvadi, Maharashtra</option>
              <option value="/1/0.261337302/-1/1.315266314">
                Savanpur, Karnataka</option>
              <option value="/1/0.139857143/-1/1.362404321">
                Sayarpuram, TN</option>
              <option value="/1/0.405084215/-1/1.346481481">Sehore, MP</option>
              <option value="/1/0.196942681/-1/1.362215168">
                Sendamangalam, TN</option>
              <option value="/1/0.509928571/-1/1.3722403">Seohara, UP</option>
              <option value="/1/0.456698854/-1/1.375514109">Seondha, MP</option>
              <option value="/1/0.385698413/-1/1.389031305">Seoni, MP</option>
              <option value="/1/0.498676367/-1/1.379476631">Shahabad, UP</option>
              <option value="/1/0.482617725/-1/1.395738977">Shahabad, UP</option>
              <option value="/1/0.500582451/-1/1.34474515">Shahdara, New
                Delhi</option>
              <option value="/1/0.454817019/-1/1.443662698">Shahganj, UP</option>
              <option value="/1/0.48674515/-1/1.395234568">
                Shahjahanpur, UP</option>
              <option value="/1/0.438947531/-1/1.498958554">
                Shaikhpura, Bihar</option>
              <option value="/1/0.5142403/-1/1.349871693">Shamli, UP</option>
              <option value="/1/0.471680776/-1/1.363927249">
                Shamshabad, UP</option>
              <option value="/1/0.428772046/-1/1.480436067">
                Sherghati, Bihar</option>
              <option value="/1/0.51205291/-1/1.371925044">Sherkot, UP</option>
              <option value="/1/0.446411817/-1/1.463058201">Sherpur, UP</option>
              <option value="/1/0.157438713/-1/1.327600088">
                Shertallai, Kerala</option>
              <option value="/1/0.473320106/-1/1.474722663">
                Shikarpur, Bihar</option>
              <option value="/1/0.24915388/-1/1.310134921">
                Shikarpur, Karnataka</option>
              <option value="/1/0.493845679/-1/1.362030864">Shikarpur, UP</option>
              <option value="/1/0.473295855/-1/1.362816578">
                Shikhnabad, UP</option>
              <option value="/1/0.446455467/-1/1.604176367">
                Shillong, Meghalaya</option>
              <option value="/1/0.24308642/-1/1.319481041">
                Shimoga, Karnataka</option>
              <option value="/1/0.443894621/-1/1.355856702">Shivpuri, MP</option>
              <option value="/1/0.308436508/-1/1.325208995">
                Sholapur, Maharashtra</option>
              <option value="/1/0.229006614/-1/1.386742063">
                Sholinghur, AP</option>
              <option value="/1/0.187834215/-1/1.327289683">
                Shoranur, Kerala</option>
              <option value="/1/0.479508818/-1/1.319864198">Shri
                Madhopur, Rajasthan</option>
              <option value="/1/0.471137566/-1/1.652269841">
                Sibsagar, Assam</option>
              <option value="/1/0.250361552/-1/1.307598325">
                Siddapur, Karnataka</option>
              <option value="/1/0.417544092/-1/1.263627425">
                Siddhapur, Gujarat</option>
              <option value="/1/0.252602293/-1/1.378894621">
                Siddhavattam, AP</option>
              <option value="/1/0.316070547/-1/1.363374339">Siddipet, AP</option>
              <option value="/1/0.425920194/-1/1.429597443">Sidhi, MP</option>
              <option value="/1/0.233711199/-1/1.359450617">
                Sidlaghatta, Karnataka</option>
              <option value="/1/0.496794533/-1/1.356574515">
                Sikandarabad, UP</option>
              <option value="/1/0.48345679/-1/1.362491623">Sikandra Rao,
                UP</option>
              <option value="/1/0.482021164/-1/1.311934303">
                Sikar, Rajasthan</option>
              <option value="/1/0.43342328/-1/1.619948854">Silchar, Assam</option>
              <option value="/1/0.466539683/-1/1.543918871">Siliguri, WB</option>
              <option value="/1/0.543093474/-1/1.347344797">Shimla, HP</option>
              <option value="/1/0.275368607/-1/1.340186067">
                Sindhnur, Karnataka</option>
              <option value="/1/0.494408289/-1/1.412505732">Singahi
                Kalan, UP</option>
              <option value="/1/0.251045414/-1/1.313859788">
                Siralkoppa, Karnataka</option>
              <option value="/1/0.196181217/-1/1.380563051">Sirkazhi, TN</option>
              <option value="/1/0.434529101/-1/1.271940476">
                Sirohi, Rajasthan</option>
              <option value="/1/0.420716049/-1/1.356516314">Sironj, MP</option>
              <option value="/1/0.515481922/-1/1.310033069">
                Sirsa, Haryana</option>
              <option value="/1/0.321104938/-1/1.376144621">Sirsilla, AP</option>
              <option value="/1/0.272958113/-1/1.34255291">
                Siruguppa, Karnataka</option>
              <option value="/1/0.267472663/-1/1.37116358">Sirvel, AP</option>
              <option value="/1/0.474033069/-1/1.462374339">Siswa Bazar,
                UP</option>
              <option value="/1/0.464410494/-1/1.492774691">
                Sitamarhi, Bihar</option>
              <option value="/1/0.481477954/-1/1.408615961">Sitapur, UP</option>
              <option value="/1/0.417306437/-1/1.52844709">Siuri, WB</option>
              <option value="/1/0.157336861/-1/1.370324515">Sivaganga, TN</option>
              <option value="/1/0.15732716/-1/1.351937831">Sivagiri, TN</option>
              <option value="/1/0.159150794/-1/1.358378748">Sivakasi, TN</option>
              <option value="/1/0.457804674/-1/1.47291358">Siwan, Bihar</option>
              <option value="/1/0.396286155/-1/1.362239418">Sohagpur, MP</option>
              <option value="/1/0.493244268/-1/1.346510582">
                Sohna, Haryana</option>
              <option value="/1/0.452610229/-1/1.286185185">
                Sojat, Rajasthan</option>
              <option value="/1/0.330649912/-1/1.476968254">Sompeta, AP</option>
              <option value="/1/0.219839947/-1/1.324306878">
                Somvarpet, Karnataka</option>
              <option value="/1/0.406907848/-1/1.51963448">Sonamukhi, WB</option>
              <option value="/1/0.506169753/-1/1.344716049">
                Sonepat, Haryana</option>
              <option value="/1/0.598670635/-1/1.300134039">Sopor, Jammu
                &amp; Kashmir</option>
              <option value="/1/0.486982804/-1/1.37499515">Soron, UP</option>
              <option value="/1/0.319373457/-1/1.464983686">
                Srikakulam, AP</option>
              <option value="/1/0.199028219/-1/1.386485009">
                Srimushnam, TN</option>
              <option value="/1/0.595062169/-1/1.306094797">
                Srinagar, Jammu &amp; Kashmir</option>
              <option value="/1/0.226518519/-1/1.399502646">
                Sriperumbudur, TN</option>
              <option value="/1/0.141928131/-1/1.360381834">
                Srivaikuntam, TN</option>
              <option value="/1/0.15732231/-1/1.345346561">
                Srivilliputtur, TN</option>
              <option value="/1/0.483495591/-1/1.300260141">
                Sujangarh, Rajasthan</option>
              <option value="/1/0.440664462/-1/1.517689594">
                Sultanganj, Bihar</option>
              <option value="/1/0.458430335/-1/1.433002205">Sultanpur, UP</option>
              <option value="/1/0.192456349/-1/1.346636684">Sulur, TN</option>
              <option value="/1/0.526006614/-1/1.32327381">Sunam, Punjab</option>
              <option value="/1/0.550586861/-1/1.342785714">
                Sundarnagar, HP</option>
              <option value="/1/0.494287037/-1/1.322313492">
                Surajgarh, Rajasthan</option>
              <option value="/1/0.370071429/-1/1.271450617">
                Surat, Gujarat</option>
              <option value="/1/0.396805115/-1/1.250604938">
                Surendranagar, Gujarat</option>
              <option value="/1/0.299260141/-1/1.390253527">Suriapet, AP</option>
              <option value="/1/0.293522487/-1/1.423510582">
                Tadepallegudem, AP</option>
              <option value="/1/0.260226631/-1/1.362035714">Tadpatri, AP</option>
              <option value="/1/0.394317019/-1/1.538006614">Taki, WB</option>
              <option value="/1/0.227241182/-1/1.380563051">Takkolam, TN</option>
              <option value="/1/0.19798545/-1/1.322395944">
                Talakkolattur, Kerala</option>
              <option value="/1/0.182431217/-1/1.328410053">
                Talikullam, Kerala</option>
              <option value="/1/0.199741182/-1/1.370440917">
                Tammampatti, TN</option>
              <option value="/1/0.242950617/-1/1.365290123">Tnakallu, AP</option>
              <option value="/1/0.505883598/-1/1.378336861">Tanda, UP</option>
              <option value="/1/0.463503527/-1/1.44321164">Tanda, UP</option>
              <option value="/1/0.191690035/-1/1.324723986">Tanur, Kerala</option>
              <option value="/1/0.239303351/-1/1.323589065">
                Tarikere, Karnataka</option>
              <option value="/1/0.194207231/-1/1.362525573">
                Tattayyangarpettai, TN</option>
              <option value="/1/0.275203704/-1/1.333919753">
                Tawargeri, Karnataka</option>
              <option value="/1/0.445044092/-1/1.50040388">Teghra, Bihar</option>
              <option value="/1/0.435377866/-1/1.468121693">Tekari, Bihar</option>
              <option value="/1/0.205003527/-1/1.318137566">
                Tellicherry, Kerala</option>
              <option value="/1/0.283497354/-1/1.408126102">Tenali, AP</option>
              <option value="/1/0.141758377/-1/1.349929894">Tenkasi, TN</option>
              <option value="/1/0.158229277/-1/1.349246032">Tevaram, TN</option>
              <option value="/1/0.464866402/-1/1.620380511">Tezpur, Assam</option>
              <option value="/1/0.335791005/-1/1.258602734">
                Thana, Maharashtra</option>
              <option value="/1/0.516597443/-1/1.351695326">Thana
                Bhawan, UP</option>
              <option value="/1/0.523324515/-1/1.34147134">
                Thanesar, Haryana</option>
              <option value="/1/0.188304674/-1/1.381843474">Thanjavur, TN</option>
              <option value="/1/0.204891975/-1/1.32658642">
                Tharuvana, Kerala</option>
              <option value="/1/0.188130071/-1/1.390602734">
                Thiruvarur, TN</option>
              <option value="/1/0.488229277/-1/1.392217813">Tihar, UP</option>
              <option value="/1/0.487647266/-1/1.341796296">
                Tijara, Rajasthan</option>
              <option value="/1/0.431910053/-1/1.376367725">Tikamgarh, MP</option>
              <option value="/1/0.213583333/-1/1.380242945">
                Tindivanam, TN</option>
              <option value="/1/0.479988977/-1/1.664977072">
                Tinsukia, Assam</option>
              <option value="/1/0.142417989/-1/1.364126102">
                Tiruchchendur, TN</option>
              <option value="/1/0.188619929/-1/1.374102734">
                Tiruchchirapalli, TN</option>
              <option value="/1/0.198664462/-1/1.360100529">
                Tiruchengodu, TN</option>
              <option value="/1/0.220092152/-1/1.397824515">
                Tirukkalukkunram, TN</option>
              <option value="/1/0.208873898/-1/1.382920194">
                Tirukkoyilur, TN</option>
              <option value="/1/0.159805556/-1/1.361589506">
                Tirumangalam, TN</option>
              <option value="/1/0.204984127/-1/1.386">Tirunamanallur, TN</option>
              <option value="/1/0.140691358/-1/1.345627866">
                Tirunelveli, TN</option>
              <option value="/1/0.237969577/-1/1.386630511">Tirupati, AP</option>
              <option value="/1/0.218016314/-1/1.371939594">
                Tiruppattur, TN</option>
              <option value="/1/0.176485009/-1/1.372468254">
                Tiruppattur, TN</option>
              <option value="/1/0.193775573/-1/1.350502205">Tiruppur, TN</option>
              <option value="/1/0.159854056/-1/1.366366843">
                Tiruppuvanam, TN</option>
              <option value="/1/0.192892857/-1/1.32441358">
                Tirurangadi, Kerala</option>
              <option value="/1/0.230005732/-1/1.380238095">Tiruttani, AP</option>
              <option value="/1/0.183857143/-1/1.390597884">
                Tiruttaraippundi, TN</option>
              <option value="/1/0.159131393/-1/1.377992504">
                Tiruvadanai, TN</option>
              <option value="/1/0.229467372/-1/1.395220018">
                Tiruvallur, TN</option>
              <option value="/1/0.213510582/-1/1.380684303">
                Tiruvannamalai, TN</option>
              <option value="/1/0.220955467/-1/1.388847002">
                Tiruvettipuram, TN</option>
              <option value="/1/0.298731481/-1/1.397727513">Tiruvuru, AP</option>
              <option value="/1/0.13968254/-1/1.359673721">
                Tisaiyanvilai, TN</option>
              <option value="/1/0.199018519/-1/1.382003527">Tittagudi, TN</option>
              <option value="/1/0.518799383/-1/1.325228395">
                Tohana, Haryana</option>
              <option value="/1/0.158369929/-1/1.379636684">Tondi, TN</option>
              <option value="/1/0.456844356/-1/1.32328351">
                Tonk, Rajasthan</option>
              <option value="/1/0.181014991/-1/1.362530423">
                Tovarankurichchi, TN</option>
              <option value="/1/0.192529101/-1/1.394279101">
                Tranquebar, TN</option>
              <option value="/1/0.183469136/-1/1.330636243">
                Trichur, Kerala</option>
              <option value="/1/0.158981041/-1/1.340244268">Tripunittura
                , Kerala</option>
              <option value="/1/0.139745591/-1/1.328473104">
                Trivandram, Kerala</option>
              <option value="/1/0.244003086/-1/1.377808201">
                Tsundupalle, AP</option>
              <option value="/1/0.48072134/-1/1.432342593">Tulsipur, UP</option>
              <option value="/1/0.251608025/-1/1.310411376">
                Tuminkatti, Karnataka</option>
              <option value="/1/0.232891534/-1/1.346103175">
                Tumkur, Karnataka</option>
              <option value="/1/0.475066138/-1/1.365959436">Tundla, UP</option>
              <option value="/1/0.445558201/-1/1.575454145">
                Tura, Meghalaya</option>
              <option value="/1/0.194634039/-1/1.372361552">Turaiyur, TN</option>
              <option value="/1/0.142039683/-1/1.364436508">Tuticorin, TN</option>
              <option value="/1/0.484029101/-1/1.310144621">
                Udaipur, Rajasthan</option>
              <option value="/1/0.4290194/-1/1.286675044">
                Udaipur, Rajasthan</option>
              <option value="/1/0.195308201/-1/1.384477072">
                Udaiyarpalaiyam, TN</option>
              <option value="/1/0.184744709/-1/1.348707672">Udamalpet, TN</option>
              <option value="/1/0.259722222/-1/1.384564374">Udayagiri, AP</option>
              <option value="/1/0.57477425/-1/1.311813051">
                Udhampur, Jammu &amp; Kashmir</option>
              <option value="/1/0.232784832/-1/1.305085979">Udipi
                , Karnataka</option>
              <option value="/1/0.488913139/-1/1.382008377">Ujhani , UP</option>
              <option value="/1/0.404652557/-1/1.310697531">Ujjain, MP</option>
              <option value="/1/0.248203263/-1/1.305915344">
                Ulanahalli, Karnataka</option>
              <option value="/1/0.335529101/-1/1.277348325">
                Ulhasnagar, Maharashtra</option>
              <option value="/1/0.266095238/-1/1.396849647">Ullapalem, AP</option>
              <option value="/1/0.410768519/-1/1.411501764">Umaria, MP</option>
              <option value="/1/0.425590388/-1/1.410376543">Unchahra, MP</option>
              <option value="/1/0.463503527/-1/1.397446208">Unnao, UP</option>
              <option value="/1/0.379601852/-1/1.22707716">
                Upleta, Gujarat</option>
              <option value="/1/0.196646825/-1/1.370901675">
                Uppiliyapuram, TN</option>
              <option value="/1/0.260958995/-1/1.348984127">
                Uravakonda, AP</option>
              <option value="/1/0.297785714/-1/1.296656526">Urun
                Islampur , Maharashtra</option>
              <option value="/1/0.1595097/-1/1.358194444">Usilampatti
                , TN</option>
              <option value="/1/0.477030423/-1/1.438972663">Utraula, UP</option>
              <option value="/1/0.159567901/-1/1.350167549">
                Uttamapalaiyam, TN</option>
              <option value="/1/0.220237654/-1/1.39258642">
                Uttiramerur, TN</option>
              <option value="/1/0.175961199/-1/1.345943122">
                Vadippatti, TN</option>
              <option value="/1/0.389476631/-1/1.277876984">
                Vadodra, Gujarat</option>
              <option value="/1/0.158534832/-1/1.333837302">
                Vaikam, Kerala</option>
              <option value="/1/0.189929453/-1/1.328759259">
                Valancher, Kerala</option>
              <option value="/1/0.208054233/-1/1.389448413">Valvanur, TN</option>
              <option value="/1/0.187101852/-1/1.380446649">Vallam, TN</option>
              <option value="/1/0.359857143/-1/1.273308201">
                Valsad, Gujarat</option>
              <option value="/1/0.218302469/-1/1.389938272">Vandavasi, TN</option>
              <option value="/1/0.258213845/-1/1.375455908">Vanipenta, AP</option>
              <option value="/1/0.221406526/-1/1.372574956">
                Vaniyambadi, TN</option>
              <option value="/1/0.140861111/-1/1.339317901">Varkkallai
                , Kerala</option>
              <option value="/1/0.337939594/-1/1.271169312">
                Vasei, Maharashtra</option>
              <option value="/1/0.177353175/-1/1.357709436">
                Vattalkundu, TN</option>
              <option value="/1/0.181092593/-1/1.380839506">
                Vedaranniyam, TN</option>
              <option value="/1/0.183842593/-1/1.345933422">
                Vedasandur, TN</option>
              <option value="/1/0.191044974/-1/1.356918871">
                Vellakkovil, TN</option>
              <option value="/1/0.225441799/-1/1.381746473">Vellore, TN</option>
              <option value="/1/0.27442769/-1/1.371963845">Velugodu, AP</option>
              <option value="/1/0.250851411/-1/1.369931658">Vempalle, AP</option>
              <option value="/1/0.277051587/-1/1.285574074">
                Vengurla, Maharashtra</option>
              <option value="/1/0.24367328/-1/1.389511464">
                Venkatagiri, AP</option>
              <option value="/1/0.246064374/-1/1.366623898">Veparala, AP</option>
              <option value="/1/0.175796296/-1/1.33212037">
                Verapoli, Kerala</option>
              <option value="/1/0.27557716/-1/1.402204145">Vetapalem, AP</option>
              <option value="/1/0.211357143/-1/1.383671958">
                Vettavalam, TN</option>
              <option value="/1/0.410734568/-1/1.35851455">Vidisha, MP</option>
              <option value="/1/0.141113316/-1/1.358223545">Vajaya
                Narayanam, TN</option>
              <option value="/1/0.288289242/-1/1.407849647">
                Vijayawada, AP</option>
              <option value="/1/0.158515432/-1/1.362205467">
                Vilattikulam, TN</option>
              <option value="/1/0.20847134/-1/1.388061287">Villupuram, TN</option>
              <option value="/1/0.258951058/-1/1.389487213">Vinjamur, AP</option>
              <option value="/1/0.185132716/-1/1.362787478">
                Viralimalai, TN</option>
              <option value="/1/0.159267196/-1/1.351554674">Virapandi, TN</option>
              <option value="/1/0.212904321/-1/1.323458113">
                Virarajendrapet , Karnataka</option>
              <option value="/1/0.158602734/-1/1.361177249">Virudunager
                , TN</option>
              <option value="/1/0.309294974/-1/1.454361993">
                Vishakhapatnam , AP</option>
              <option value="/1/0.413838624/-1/1.266707231">Visanagar
                , Gujarat</option>
              <option value="/1/0.3162694/-1/1.456350529">Vizianagaram
                , AP</option>
              <option value="/1/0.201036155/-1/1.385088183">
                Vriddhachalam , TN</option>
              <option value="/1/0.481565256/-1/1.345618166">Vrindavan, UP</option>
              <option value="/1/0.22330291/-1/1.39372134">Walajabad, TN</option>
              <option value="/1/0.225635802/-1/1.385742945">Walajapet, TN</option>
              <option value="/1/0.394845679/-1/1.238644621">
                Wankaner, Gujarat</option>
              <option value="/1/0.314159612/-1/1.389618166">Warangal, AP</option>
              <option value="/1/0.362199735/-1/1.362821429">
                Wardha, Maharashtra</option>
              <option value="/1/0.43672619/-1/1.48501455">Waris Allganj,
                Bihar</option>
              <option value="/1/0.353149471/-1/1.379500882">
                Warora, Maharashtra</option>
              <option value="/1/0.350932981/-1/1.346830688">
                Washim, Maharashtra</option>
              <option value="/1/0.159456349/-1/1.355551146">Watrap, TN</option>
              <option value="/1/0.471743827/-1/1.347417549">
                Wer, Rajasthan</option>
              <option value="/1/0.292693122/-1/1.346884039">
                Yadgir, Karnataka</option>
              <option value="/1/0.262690476/-1/1.359634921">Yadiki, AP</option>
              <option value="/1/0.526132716/-1/1.349469136">
                Yamunanagar, Haryana</option>
              <option value="/1/0.356146825/-1/1.364354056">
                Yavatmal, Maharashtra</option>
              <option value="/1/0.307141534/-1/1.402640653">Yellandu, AP</option>
              <option value="/1/0.468353616/-1/1.420018519">Zaidpur, UP</option>
              <option value="/1/0.443763668/-1/1.458945326">Zamania, UP</option>
            </select>
          </div>
          <div className="col-lg-6"><label htmlFor>Enter Terminal point</label>
            <select name="pqr" value={terminalOption} onChange={(e) => setTerminalOption(e.target.value)} className="form-control">
              <option value="/1/0.158995591/-1/1.369611552" selected="SELECTED">
                Abiramam , TN</option>
              <option value="/1/0.526186067/-1/1.295638007">Abohar
                , Punjab</option>
              <option value="/1/0.371031746/-1/1.353291005">Achalpur
                , Maharashtra</option>
              <option value="/1/0.275936067/-1/1.396306437">Addanki , AP</option>
              <option value="/1/0.343536596/-1/1.371362434">Adilabad , AP</option>
              <option value="/1/0.27280291/-1/1.349202381">Adoni , AP</option>
              <option value="/1/0.410181658/-1/1.502780423">Adra , WB</option>
              <option value="/1/0.192305996/-1/1.387770282">Aduturai , TN</option>
              <option value="/1/0.416108466/-1/1.593700176">Agartala
                , Tripura</option>
              <option value="/1/0.474527778/-1/1.362064815">Agra , UP</option>
              <option value="/1/0.402280864/-1/1.258015873">Ahmedabad
                , Gujarat</option>
              <option value="/1/0.33331261/-1/1.305061728">Ahmadnagar
                , Maharashtra</option>
              <option value="/1/0.436895944/-1/1.449633157">Ahraura , UP</option>
              <option value="/1/0.461704145/-1/1.303281746">Ajmer
                , Rajasthan</option>
              <option value="/1/0.46142284/-1/1.432633598">Akbarpur , UP</option>
              <option value="/1/0.361491623/-1/1.344391093">Akola
                , Maharashtra</option>
              <option value="/1/0.368310847/-1/1.345429012">Akot
                , Maharashtra</option>
              <option value="/1/0.27721164/-1/1.364247354">Alampur , AP</option>
              <option value="/1/0.192228395/-1/1.332993386">Alanallur
                , Kerala</option>
              <option value="/1/0.180874339/-1/1.379141975">Alangudi , TN</option>
              <option value="/1/0.487375661/-1/1.383633157">Alapur , UP</option>
              <option value="/1/0.490838624/-1/1.407165785">Aliganj , UP</option>
              <option value="/1/0.487031305/-1/1.364257055">Aligarh , UP</option>
              <option value="/1/0.480090829/-1/1.382343034">Alignj , UP</option>
              <option value="/1/0.444238977/-1/1.428748677">Allahabad
                , UP</option>
              <option value="/1/0.159883157/-1/1.332682981">Alleppey
                , Kerala</option>
              <option value="/1/0.256370811/-1/1.397795414">Allur , AP</option>
              <option value="/1/0.516869048/-1/1.390762787">Almora , UP</option>
              <option value="/1/0.48123545/-1/1.33761067">Alwar
                , Rajasthan</option>
              <option value="/1/0.176480159/-1/1.327590388">Alwaye
                , Kerala</option>
              <option value="/1/0.367428131/-1/1.310571429">Amalner
                , Maharashtra</option>
              <option value="/1/0.466238977/-1/1.365799383">Ambah , MP</option>
              <option value="/1/0.529906085/-1/1.341519841">Ambala
                , Haryana</option>
              <option value="/1/0.529726631/-1/1.337523369">Ambala
                Cantonment , Punjab</option>
              <option value="/1/0.157938272/-1/1.33319224">Ambalapulai
                , Kerala</option>
              <option value="/1/0.403667989/-1/1.452664462">Ambikapur
                , MP</option>
              <option value="/1/0.22327866/-1/1.374398589">Ambur , TN</option>
              <option value="/1/0.467116843/-1/1.417059965">Amethi , UP</option>
              <option value="/1/0.38282716/-1/1.364261905">Amla , MP</option>
              <option value="/1/0.365454145/-1/1.357690035">Amravati
                , Maharashtra</option>
              <option value="/1/0.377191358/-1/1.239997795">Amreli
                , Gujarat</option>
              <option value="/1/0.552284392/-1/1.307278219">Amritsar
                , Punjab</option>
              <option value="/1/0.504695326/-1/1.37004321">Amroha , UP</option>
              <option value="/1/0.184710758/-1/1.343261023">Anaimalai
                , TN</option>
              <option value="/1/0.308800265/-1/1.449356702">Anakapalle
                , AP</option>
              <option value="/1/0.393919312/-1/1.27381261">Anand
                , Gujarat</option>
              <option value="/1/0.256395062/-1/1.354741182">Anantapur
                , AP</option>
              <option value="/1/0.589290564/-1/1.312918871">Anantnag
                , Jammu &amp; Kashmir</option>
              <option value="/1/0.197888448/-1/1.38601455">Andimatam, TN</option>
              <option value="/1/0.159888007/-1/1.355337743">Andipatti
                , TN</option>
              <option value="/1/0.202098325/-1/1.354750882">Andiyur , TN</option>
              <option value="/1/0.221886684/-1/1.356564815">Anekal
                , Karnataka</option>
              <option value="/1/0.363717813/-1/1.485824515">Angul
                , Orissa</option>
              <option value="/1/0.369479718/-1/1.349837743">Anjangaon
                , Maharashtra</option>
              <option value="/1/0.403546737/-1/1.222590829">Anjar
                , Gujarat</option>
              <option value="/1/0.142417989/-1/1.340617725">Anjengo
                , Kerala</option>
              <option value="/1/0.377647266/-1/1.258617284">Ankleshwar
                , Gujarat</option>
              <option value="/1/0.255914903/-1/1.297093034">
                Ankola, Karnataka</option>
              <option value="/1/0.269233245/-1/1.317036596">Annegeri
                , Karnataka</option>
              <option value="/1/0.196186067/-1/1.346297178">Annur, TN</option>
              <option value="/1/0.350855379/-1/1.417142416">Antagarh, MP</option>
              <option value="/1/0.495140653/-1/1.366643298">Anupshahr, UP</option>
              <option value="/1/0.493729277/-1/1.382333333">Aonla , UP</option>
              <option value="/1/0.221168871/-1/1.384307319">Arani, TN</option>
              <option value="/1/0.232876984/-1/1.398425926">Arani, TN</option>
              <option value="/1/0.177455026/-1/1.379311728">Arantangi, TN</option>
              <option value="/1/0.456446649/-1/1.527981481">Araria, Bihar</option>
              <option value="/1/0.188047619/-1/1.360333333">
                Aravakkurichchi, TN</option>
              <option value="/1/0.195938713/-1/1.327837743">
                Arikkod, Kerala</option>
              <option value="/1/0.178953704/-1/1.377512346">Arimalam, TN</option>
              <option value="/1/0.194415785/-1/1.380606702">Ariyalpur, TN</option>
              <option value="/1/0.22276455/-1/1.327973545">
                Arkalgud, Karnataka</option>
              <option value="/1/0.228429453/-1/1.391058642">Arkonam, TN</option>
              <option value="/1/0.446246914/-1/1.467840388">Arrah, Bihar</option>
              <option value="/1/0.232377425/-1/1.331305556">
                Arsikere, Karnataka</option>
              <option value="/1/0.157264109/-1/1.363539242">
                Aruppukottai, TN</option>
              <option value="/1/0.366584215/-1/1.366027337">
                Arvi, Maharashtra</option>
              <option value="/1/0.41358157/-1/1.518582011">Asansol, WB</option>
              <option value="/1/0.429048501/-1/1.357040123">
                Ashoknagar, MP</option>
              <option value="/1/0.441474427/-1/1.397708113">Atarra, UP</option>
              <option value="/1/0.180481481/-1/1.385902998">
                Atirampattinam, TN</option>
              <option value="/1/0.277231041/-1/1.372177249">Atmakur, AP</option>
              <option value="/1/0.255134039/-1/1.390292328">Atmakur, AP</option>
              <option value="/1/0.489432099/-1/1.362205467">Atrauli, UP</option>
              <option value="/1/0.201414462/-1/1.364242504">
                Attayyampatti, TN</option>
              <option value="/1/0.20240873/-1/1.362802028">Attur, TN</option>
              <option value="/1/0.462067901/-1/1.38820679">Auraiya, UP</option>
              <option value="/1/0.432113757/-1/1.473238536">
                Aurangabad, Bihar</option>
              <option value="/1/0.347203263/-1/1.315101411">
                Aurangabad, Maharashtra</option>
              <option value="/1/0.195419753/-1/1.34916843">Avanashi, TN</option>
              <option value="/1/0.467873457/-1/1.435213845">Ayodhya, UP</option>
              <option value="/1/0.455078924/-1/1.449516755">Azamgarh, UP</option>
              <option value="/1/0.207966931/-1/1.315736772">
                Azhikkal, Kerala</option>
              <option value="/1/0.423989859/-1/1.537870811">Azimganj, WB</option>
              <option value="/1/0.202447531/-1/1.319670194">
                Badagara, Kerala</option>
              <option value="/1/0.277876102/-1/1.321338624">
                Badami, Karnataka</option>
              <option value="/1/0.36411552/-1/1.357146825">
                Badnera, Maharashtra</option>
              <option value="/1/0.257321429/-1/1.380466049">Badvel, AP</option>
              <option value="/1/0.473121252/-1/1.468247795">Bagha, Bihar</option>
              <option value="/1/0.282595238/-1/1.321678131">
                Bagalkot, Karnataka</option>
              <option value="/1/0.275358907/-1/1.303208995">
                Bagevadi, Karnataka</option>
              <option value="/1/0.469115079/-1/1.372298501">Bah, UP</option>
              <option value="/1/0.501115961/-1/1.341185185">
                Bahadurgarh, Haryana</option>
              <option value="/1/0.502357584/-1/1.388012787">Baheri, UP</option>
              <option value="/1/0.481439153/-1/1.424674603">Bahraich, UP</option>
              <option value="/1/0.275931217/-1/1.304130511">Bail
                Hongal, Karnataka</option>
              <option value="/1/0.380916226/-1/1.400089506">Balaghat, MP</option>
              <option value="/1/0.37512522/-1/1.518092152">
                Balasore, Orissa</option>
              <option value="/1/0.3465/-1/1.38546164">
                Balharshah, Maharashtra</option>
              <option value="/1/0.449748677/-1/1.469266314">Ballia, UP</option>
              <option value="/1/0.450922399/-1/1.261367284">
                Balotra, Rajasthan</option>
              <option value="/1/0.478926808/-1/1.43492284">Balrampur, UP</option>
              <option value="/1/0.440421958/-1/1.550020282">Balurghat, WB</option>
              <option value="/1/0.276071869/-1/1.289638448">Banda, Goa</option>
              <option value="/1/0.444753086/-1/1.402630952">Banda, UP</option>
              <option value="/1/0.226542769/-1/1.354702381">
                Bangalore, Karnataka</option>
              <option value="/1/0.26732231/-1/1.365809083">
                Banganapalle, AP</option>
              <option value="/1/0.402353616/-1/1.551116402">Bangaon, WB</option>
              <option value="/1/0.226722222/-1/1.365013668">
                Bangarapet, Karnataka</option>
              <option value="/1/0.469517637/-1/1.400477513">Bangarmau, UP</option>
              <option value="/1/0.434393298/-1/1.517558642">Banka, Bihar</option>
              <option value="/1/0.260430335/-1/1.31395679">
                Bankapur, Karnataka</option>
              <option value="/1/0.405680776/-1/1.520308642">Bankura, WB</option>
              <option value="/1/0.451955467/-1/1.522515432">
                Banmnkhi, Bihar</option>
              <option value="/1/0.215256614/-1/1.34200485">
                Bannur, Karnataka</option>
              <option value="/1/0.225179894/-1/1.310154321">
                Bantval, Karnataka</option>
              <option value="/1/0.277696649/-1/1.397426808">Bapatala, AP</option>
              <option value="/1/0.31681261/-1/1.302122575">
                Baramati, Maharashtra</option>
              <option value="/1/0.597361111/-1/1.292650353">
                Baramula, Jammu &amp; Kashmir</option>
              <option value="/1/0.438156966/-1/1.335874339">Baran
                , Rajasthan</option>
              <option value="/1/0.508129189/-1/1.349066578">Baraut , UP</option>
              <option value="/1/0.440363757/-1/1.496882716">Barbigha
                , Bihar</option>
              <option value="/1/0.405641975/-1/1.534126543">Bardhaman
                , WB</option>
              <option value="/1/0.495053351/-1/1.386509259">Bareilly, UP</option>
              <option value="/1/0.444670635/-1/1.49654321">Barh, Bihar</option>
              <option value="/1/0.441488977/-1/1.501839506">
                Barhiya, Bihar</option>
              <option value="/1/0.46531746/-1/1.35518739">Bari, Rajasthan</option>
              <option value="/1/0.382987213/-1/1.514386684">
                Baripada, Orissa</option>
              <option value="/1/0.422054674/-1/1.499598765">Barki
                Saria, Bihar</option>
              <option value="/1/0.449394621/-1/1.246438713">
                Barmer, Rajasthan</option>
              <option value="/1/0.459511905/-1/1.589223545">
                Barpeta, Assam</option>
              <option value="/1/0.318398589/-1/1.321585979">
                Barsi, Maharashtra</option>
              <option value="/1/0.444753086/-1/1.50093254">Barauni, Bihar</option>
              <option value="/1/0.442992504/-1/1.374616843">Barwa
                Sagar, UP</option>
              <option value="/1/0.456242945/-1/1.527166667">
                Basantpur, Bihar</option>
              <option value="/1/0.395548942/-1/1.537967813">Basirhat, WB</option>
              <option value="/1/0.467844356/-1/1.444710317">Basti, UP</option>
              <option value="/1/0.555383598/-1/1.313078924">
                Batala, Punjab</option>
              <option value="/1/0.469803792/-1/1.349469136">
                Bayana, Rajasthan</option>
              <option value="/1/0.455593034/-1/1.297510141">
                Beawar, Rajasthan</option>
              <option value="/1/0.443715168/-1/1.503750441">
                Begusarai, Bihar</option>
              <option value="/1/0.452469577/-1/1.43166358">Bela, UP</option>
              <option value="/1/0.276750882/-1/1.300871252">
                Belgaum, Karnataka</option>
              <option value="/1/0.264320106/-1/1.328473104">
                Bellary, Karnataka</option>
              <option value="/1/0.229802028/-1/1.324471781">
                Belur, Karnataka</option>
              <option value="/1/0.441430776/-1/1.448440035">Benaras, UP</option>
              <option value="/1/0.420633598/-1/1.540824515">
                Berhampore, WB</option>
              <option value="/1/0.33712963/-1/1.480799824">
                Berhampur, Orissa</option>
              <option value="/1/0.269713404/-1/1.364436508">
                Betamcherla, AP</option>
              <option value="/1/0.467931658/-1/1.475464727">
                Bettiah, Bihar</option>
              <option value="/1/0.382429453/-1/1.360241182">Betul, MP</option>
              <option value="/1/0.195371252/-1/1.32387037">
                Beypore, Kerala</option>
              <option value="/1/0.437196649/-1/1.459900794">Bhabua, Bihar</option>
              <option value="/1/0.443370811/-1/1.441620811">Bhadohi, UP</option>
              <option value="/1/0.367568783/-1/1.510797619">
                Bhadrakh, Orissa</option>
              <option value="/1/0.241524691/-1/1.32162478">
                Bhadravati, Karnataka</option>
              <option value="/1/0.440698413/-1/1.503061728">
                Bhagalpur, Bihar</option>
              <option value="/1/0.438447972/-1/1.436470018">
                Bharatganj, UP</option>
              <option value="/1/0.47519224/-1/1.353">Bharatpur, Rajasthan</option>
              <option value="/1/0.467150794/-1/1.379660935">Bharthana, UP</option>
              <option value="/1/0.379165344/-1/1.274205467">
                Bharuch, Gujarat</option>
              <option value="/1/0.472083333/-1/1.34529321">
                Bhasawar, Rajasthan</option>
              <option value="/1/0.379422399/-1/1.430795414">Bhatapara, MP</option>
              <option value="/1/0.244158289/-1/1.301579365">
                Bhatkali, Karnataka</option>
              <option value="/1/0.199736332/-1/1.345647266">Bhavani, TN</option>
              <option value="/1/0.380111111/-1/1.259635802">
                Bhavnagar, Gujarat</option>
              <option value="/1/0.347562169/-1/1.452218254">
                Bhawanipatna, Orissa</option>
              <option value="/1/0.472301587/-1/1.314601852">
                Bheslana, Rajasthan</option>
              <option value="/1/0.442551146/-1/1.303170194">
                Bhilwara, Rajasthan</option>
              <option value="/1/0.288885802/-1/1.423563933">Bhimvaram, AP</option>
              <option value="/1/0.463818783/-1/1.363068783">Bhind, MP</option>
              <option value="/1/0.426647707/-1/1.296675926">
                Bhindar, Rajasthan</option>
              <option value="/1/0.483723545/-1/1.43056261">Bhinga, UP</option>
              <option value="/1/0.336940476/-1/1.275534392">
                Bhiwandi, Maharashtra</option>
              <option value="/1/0.502667989/-1/1.329380071">
                Bhiwani, Haryana</option>
              <option value="/1/0.314688272/-1/1.450118166">
                Bhogapuram, AP</option>
              <option value="/1/0.475934303/-1/1.382401235">Bhongaon, UP</option>
              <option value="/1/0.405598325/-1/1.351632275">Bhopal, MP</option>
              <option value="/1/0.353804233/-1/1.485611111">
                Bhubaneshwar, Orissa</option>
              <option value="/1/0.405981481/-1/1.216329365">Bhuj, Gujarat</option>
              <option value="/1/0.367491182/-1/1.32327381">
                Bhusawal, Maharashtra</option>
              <option value="/1/0.312665785/-1/1.353645062">
                Bidar, Karnataka</option>
              <option value="/1/0.439961199/-1/1.49323545">Bihar, Bihar</option>
              <option value="/1/0.293750441/-1/1.322012787">
                Bijaipur, Karnataka</option>
              <option value="/1/0.429940917/-1/1.387876984">Bijawar, MP</option>
              <option value="/1/0.512843474/-1/1.364281305">Bijnor, UP</option>
              <option value="/1/0.489233245/-1/1.27490873">
                Bikaner, Rajasthan</option>
              <option value="/1/0.546958995/-1/1.34036552">Bilaspur, HP</option>
              <option value="/1/0.385586861/-1/1.434452381">Bilaspur, MP</option>
              <option value="/1/0.474445326/-1/1.397305556">Bilgram, UP</option>
              <option value="/1/0.468697972/-1/1.397902116">Bilhaur, UP</option>
              <option value="/1/0.422001323/-1/1.365183422">Bina, MP</option>
              <option value="/1/0.454584215/-1/1.406782628">Bindki, UP</option>
              <option value="/1/0.237416667/-1/1.310978836">
                Birur, Karnataka</option>
              <option value="/1/0.494068783/-1/1.393391534">Bisalpur, UP</option>
              <option value="/1/0.402979277/-1/1.524780423">Bishnupur, WB</option>
              <option value="/1/0.48016843/-1/1.414154762">Biswan, UP</option>
              <option value="/1/0.324286596/-1/1.455467813">Bobbili, AP</option>
              <option value="/1/0.325794974/-1/1.360241182">Bodhan, AP</option>
              <option value="/1/0.39103836/-1/1.272842593">
                Borsad, Gujarat</option>
              <option value="/1/0.38722619/-1/1.25125485">Botad, Gujarat</option>
              <option value="/1/0.48947575/-1/1.381474868">Budaun, UP</option>
              <option value="/1/0.511388448/-1/1.352747795">Budhana, UP</option>
              <option value="/1/0.247868607/-1/1.345618166">
                Bukkapatnam, AP</option>
              <option value="/1/0.495926367/-1/1.359271164">
                Bulandshahr, UP</option>
              <option value="/1/0.372011464/-1/1.3309903">Berhanpur, MP</option>
              <option value="/1/0.256133157/-1/1.310130071">
                Byadgi, Karnataka</option>
              <option value="/1/0.393613757/-1/1.537104497">Calcutta, WB</option>
              <option value="/1/0.206996914/-1/1.315833774">
                Cannanore, Kerala</option>
              <option value="/1/0.461248236/-1/1.484946649">Chakia, Bihar</option>
              <option value="/1/0.437385802/-1/1.452926367">Chakia, UP</option>
              <option value="/1/0.357175044/-1/1.311003086">
                Chalisgaon, Maharashtra</option>
              <option value="/1/0.250002646/-1/1.338260582">
                Challakere, Karnataka</option>
              <option value="/1/0.208151235/-1/1.343387125">
                Chamrajnagar, Karnataka</option>
              <option value="/1/0.496779982/-1/1.37555291">Chandausi, UP</option>
              <option value="/1/0.431454145/-1/1.364295855">Chanderi, MP</option>
              <option value="/1/0.536691358/-1/1.328162698">
                Chandigarh, Chandigarh City</option>
              <option value="/1/0.508711199/-1/1.366648148">Chandpur, UP</option>
              <option value="/1/0.396916667/-1/1.528156085">Chandrakona
                Road , WB</option>
              <option value="/1/0.348275132/-1/1.384651675">
                Chandrapur, Maharashtra</option>
              <option value="/1/0.370794092/-1/1.357452381">Chandur
                Bazar , Maharashtra</option>
              <option value="/1/0.159044092/-1/1.336407848">
                Changanacheri, Kerala</option>
              <option value="/1/0.244837302/-1/1.325606702">
                Channagiri, Karnataka</option>
              <option value="/1/0.220940917/-1/1.347902557">
                Channapatna, Karnataka</option>
              <option value="/1/0.225194444/-1/1.333599647">
                Channarayapatna , Karnataka</option>
              <option value="/1/0.470671958/-1/1.476104938">Chanpatia
                , Bihar</option>
              <option value="/1/0.443511464/-1/1.392416667">Charkhari
                , UP</option>
              <option value="/1/0.515869929/-1/1.354794533">Charthawal
                , UP</option>
              <option value="/1/0.422568783/-1/1.443575397">Chatra, UP</option>
              <option value="/1/0.337895944/-1/1.48392328">
                Chatrapur, Orissa</option>
              <option value="/1/0.182707672/-1/1.35520679">
                Chattrappatt, TN</option>
              <option value="/1/0.214849206/-1/1.375712963">Chengam, TN</option>
              <option value="/1/0.159713404/-1/1.337712522">
                Chengannur, Kerala</option>
              <option value="/1/0.194900794/-1/1.355070988">
                Chennimalai, TN</option>
              <option value="/1/0.217628307/-1/1.38543254">Chetput, TN</option>
              <option value="/1/0.21560097/-1/1.396878748">Cheyur, TN</option>
              <option value="/1/0.528082451/-1/1.350696208">
                Chhachrauli, Haryana</option>
              <option value="/1/0.484111552/-1/1.353242504">Chhata, UP</option>
              <option value="/1/0.434951058/-1/1.389627866">
                Chhattarpur, MP</option>
              <option value="/1/0.474028219/-1/1.388056437">
                Chhibramau, UP</option>
              <option value="/1/0.385140653/-1/1.378385362">
                Chhindwara, MP</option>
              <option value="/1/0.198994268/-1/1.391436949">
                Chidambaram, TN</option>
              <option value="/1/0.234370811/-1/1.357025573">Chik
                Ballapur, Karnataka</option>
              <option value="/1/0.232435626/-1/1.322982804">
                Chikmagalur, Karnataka</option>
              <option value="/1/0.234191358/-1/1.337727072">
                Chiknayakanhalli , Karnataka</option>
              <option value="/1/0.272055996/-1/1.380844356">Chimakurti
                , AP</option>
              <option value="/1/0.22162963/-1/1.396359788">Chingleput, TN</option>
              <option value="/1/0.259945326/-1/1.391189594">Chinna
                Annaluru , AP</option>
              <option value="/1/0.189429894/-1/1.345933422">Chinna
                Dharapuram , TN</option>
              <option value="/1/0.27388448/-1/1.400763668">Chinna Ganjam
                , AP</option>
              <option value="/1/0.203165344/-1/1.377240741">Chinna Salem
                , TN</option>
              <option value="/1/0.319771164/-1/1.459212081">
                Chipurupalle, AP</option>
              <option value="/1/0.276362875/-1/1.40305291">Chirala, AP</option>
              <option value="/1/0.493011464/-1/1.320742063">
                Chirawa, Rajasthan</option>
              <option value="/1/0.404948413/-1/1.438060847">Chirmiri, MP</option>
              <option value="/1/0.184895062/-1/1.327609788">
                Chittalancheri, Kerala</option>
              <option value="/1/0.4344806/-1/1.292965608">
                Chittaurgarh, Rajasthan</option>
              <option value="/1/0.230675044/-1/1.381018959">Chittoor, AP</option>
              <option value="/1/0.247466049/-1/1.385141534">Chitvel, AP</option>
              <option value="/1/0.17488933/-1/1.361148148">
                Cholavandan, TN</option>
              <option value="/1/0.474246473/-1/1.322158289">
                Chomun, Rajasthan</option>
              <option value="/1/0.370958995/-1/1.314660053">
                Chopda, Maharashtra</option>
              <option value="/1/0.399817019/-1/1.539544092">Chunchura, WB</option>
              <option value="/1/0.174811728/-1/1.330912698">
                Cochin, Kerala</option>
              <option value="/1/0.192150794/-1/1.343930335">
                Coimbatore, TN</option>
              <option value="/1/0.44110582/-1/1.519353175">Colong, Bihar</option>
              <option value="/1/0.459565256/-1/1.562053351">Cooch Behar,
                WB</option>
              <option value="/1/0.237843474/-1/1.30406746">
                Coondapoor, Karnataka</option>
              <option value="/1/0.198145503/-1/1.340840829">Coonoor, TN</option>
              <option value="/1/0.205182981/-1/1.392693122">Cuddalore, TN</option>
              <option value="/1/0.252650794/-1/1.376231922">Cuddappah, AP</option>
              <option value="/1/0.271939594/-1/1.381164462">Cumbum, AP</option>
              <option value="/1/0.357305996/-1/1.499477513">
                Cuttak, Orissa</option>
              <option value="/1/0.386387125/-1/1.275199735">
                Dabhoi, Gujarat</option>
              <option value="/1/0.339574074/-1/1.438977513">Dabugam
                , Orissa</option>
              <option value="/1/0.498492063/-1/1.35412037">Dadri, UP</option>
              <option value="/1/0.451732363/-1/1.533660935">Dalkola, WB</option>
              <option value="/1/0.419755732/-1/1.467796737">
                Daltonganj, Bihar</option>
              <option value="/1/0.447604938/-1/1.484936949">
                Danapur, Bihar</option>
              <option value="/1/0.456572751/-1/1.499676367">
                Darbhanga, Bihar</option>
              <option value="/1/0.472136684/-1/1.541023369">
                Darjeeling, WB</option>
              <option value="/1/0.469420635/-1/1.42397134">Daryabad, UP</option>
              <option value="/1/0.448172399/-1/1.369858907">Datia, MP</option>
              <option value="/1/0.437075397/-1/1.473587743">
                Daudnagar, Bihar</option>
              <option value="/1/0.322361111/-1/1.302137125">
                Daund, Maharashtra</option>
              <option value="/1/0.469478836/-1/1.327609788">
                Dausa, Rajasthan</option>
              <option value="/1/0.252364638/-1/1.311017637">
                Davangere, Karnataka</option>
              <option value="/1/0.52962963/-1/1.362734127">Dehradun, UP</option>
              <option value="/1/0.434577601/-1/1.469634921">Dehri, Bihar</option>
              <option value="/1/0.448094797/-1/1.4987403">Delsingh
                Sarai, Bihar</option>
              <option value="/1/0.449772928/-1/1.31616358">
                Delvi, Rajasthan</option>
              <option value="/1/0.218680776/-1/1.358179894">
                Denkanikota, TN</option>
              <option value="/1/0.518818783/-1/1.345894621">
                Deoband, Haryana</option>
              <option value="/1/0.427583774/-1/1.513664021">
                Deoghar, Jharkhand</option>
              <option value="/1/0.17711067/-1/1.355682099">
                Devadanappatti, TN</option>
              <option value="/1/0.159097443/-1/1.376324074">
                Devakottai, TN</option>
              <option value="/1/0.231203704/-1/1.356797619">
                Devanhalli, Karnataka</option>
              <option value="/1/0.175713845/-1/1.346287478">
                Devikolam, Kerala</option>
              <option value="/1/0.400879189/-1/1.327837743">Dewas, MP</option>
              <option value="/1/0.183444885/-1/1.347698854">Dhali, TN</option>
              <option value="/1/0.511616402/-1/1.370722222">Dhampur, UP</option>
              <option value="/1/0.361520723/-1/1.42397619">Dhamtari, MP</option>
              <option value="/1/0.505587743/-1/1.366425044">Dhanaura, UP</option>
              <option value="/1/0.415327601/-1/1.502198413">
                Dhanbad, Bihar</option>
              <option value="/1/0.187426808/-1/1.353654762">
                Dharampuram, TN</option>
              <option value="/1/0.2117306/-1/1.364771164">Dharampuri, TN</option>
              <option value="/1/0.22602866/-1/1.316110229">
                Dharmastala, Karnataka</option>
              <option value="/1/0.251671076/-1/1.345618166">
                Dharmavaram, AP</option>
              <option value="/1/0.562581129/-1/1.327309083">
                Dharmshala, HP</option>
              <option value="/1/0.269776455/-1/1.309669312">
                Dharwar, Karnataka</option>
              <option value="/1/0.360662257/-1/1.494675926">
                Dhenkanal, Orissa</option>
              <option value="/1/0.426720459/-1/1.347698854">Dhirpur, MP</option>
              <option value="/1/0.396887566/-1/1.264883598">
                Dholka, Gujarat</option>
              <option value="/1/0.466035273/-1/1.359804674">
                Dholpur, Rajasthan</option>
              <option value="/1/0.268719136/-1/1.345933422">Dhone, AP</option>
              <option value="/1/0.37949515/-1/1.229972663">
                Dhoraji, Gujarat</option>
              <option value="/1/0.401373898/-1/1.247733686">
                Dhrangadhra, Gujarat</option>
              <option value="/1/0.454351411/-1/1.571045414">Dhubri, Assam</option>
              <option value="/1/0.364954586/-1/1.305522487">
                Dhule, Maharashtra</option>
              <option value="/1/0.431425044/-1/1.531420194">
                Dhulian, Bihar</option>
              <option value="/1/0.464284392/-1/1.554201058">Dhupgari, WB</option>
              <option value="/1/0.492521605/-1/1.366444444">Dibai, UP</option>
              <option value="/1/0.479790123/-1/1.65714418">
                Dibrugarh, Assam</option>
              <option value="/1/0.479639771/-1/1.350128748">
                Dig, Rajasthan</option>
              <option value="/1/0.561077601/-1/1.310144621">
                Dinanagar, Punjab</option>
              <option value="/1/0.180830688/-1/1.361482804">Dindigul, TN</option>
              <option value="/1/0.456213845/-1/1.562218254">Dinhata, WB</option>
              <option value="/1/0.423606702/-1/1.260305115">Disa, Gujarat</option>
              <option value="/1/0.232018519/-1/1.353732363">Dod
                Ballapur, Karnataka</option>
              <option value="/1/0.335422399/-1/1.276048501">
                Dombivli, Maharashtra</option>
              <option value="/1/0.369964727/-1/1.410109788">
                Dongargarh, MP</option>
              <option value="/1/0.41526455/-1/1.51963448">Dubrajpur, WB</option>
              <option value="/1/0.423645503/-1/1.523349647">
                Dumka, Jharkhand</option>
              <option value="/1/0.44606746/-1/1.467830688">Dumraon, Bihar</option>
              <option value="/1/0.370105379/-1/1.420770282">Durg, MP</option>
              <option value="/1/0.204571869/-1/1.382551587">
                Elavanasur, TN</option>
              <option value="/1/0.291791005/-1/1.416128748">Eluru, AP</option>
              <option value="/1/0.275358907/-1/1.352835097">
                Emmiganuru, AP</option>
              <option value="/1/0.436512787/-1/1.538966931">English
                Bazar, WB</option>
              <option value="/1/0.188421076/-1/1.351200617">
                Erakampatti, TN</option>
              <option value="/1/0.174860229/-1/1.332280423">
                Ernakulam, Kerala</option>
              <option value="/1/0.197907848/-1/1.357205026">Erode, TN</option>
              <option value="/1/0.481186949/-1/1.362802028">Etah, UP</option>
              <option value="/1/0.467533951/-1/1.382018078">Etawah, UP</option>
              <option value="/1/0.158171076/-1/1.361739859">
                Ettaiyapuram, TN</option>
              <option value="/1/0.467563051/-1/1.434049824">Faizabad, UP</option>
              <option value="/1/0.463013668/-1/1.55759127">Falakata, WB</option>
              <option value="/1/0.49555776/-1/1.349735891">
                Faridabad, Haryana</option>
              <option value="/1/0.535420635/-1/1.305255732">
                Faridkot, Punjab</option>
              <option value="/1/0.492647707/-1/1.380257496">Faridpur, UP</option>
              <option value="/1/0.477791887/-1/1.390355379">
                Farrukhabad, UP</option>
              <option value="/1/0.471850529/-1/1.367113757">Fatehabad, UP</option>
              <option value="/1/0.488772487/-1/1.308762346">
                Fatehpur, Rajasthan</option>
              <option value="/1/0.474421076/-1/1.41791843">Fatehpur, UP</option>
              <option value="/1/0.452692681/-1/1.411036155">Fatehpur, UP</option>
              <option value="/1/0.420439594/-1/1.531701499">Fatehpur, WB</option>
              <option value="/1/0.473063051/-1/1.355968254">Fatehpur
                Sikri, UP</option>
              <option value="/1/0.530856702/-1/1.29250485">
                Fazilka, Punjab</option>
              <option value="/1/0.474042769/-1/1.368748236">Firozabad, UP</option>
              <option value="/1/0.539839065/-1/1.302820988">
                Firozpur, Punjab</option>
              <option value="/1/0.485008818/-1/1.343484127">Firozpur
                Jhirka, Haryana</option>
              <option value="/1/0.459119048/-1/1.524271164">
                Forbesganj, Bihar</option>
              <option value="/1/0.269388448/-1/1.32054321">
                Gadag, Karnataka</option>
              <option value="/1/0.400190476/-1/1.375640212">Gadarwara, MP</option>
              <option value="/1/0.445199295/-1/1.463397707">Gahmar, UP</option>
              <option value="/1/0.274684744/-1/1.310983686">
                Gajendragarh, Karnataka</option>
              <option value="/1/0.402828924/-1/1.222251323">Gandhi Dam,
                Gujarat</option>
              <option value="/1/0.522456349/-1/1.289643298">
                Ganganagar, Rajasthan</option>
              <option value="/1/0.462203704/-1/1.339482804">
                Gangapur, Rajasthan</option>
              <option value="/1/0.269402998/-1/1.336058642">
                Gangawati, Karnataka</option>
              <option value="/1/0.520041005/-1/1.348969577">Gangoh, UP</option>
              <option value="/1/0.477175926/-1/1.537380952">
                Gangtok, Sikkim</option>
              <option value="/1/0.320702381/-1/1.457247795">Garbham, AP</option>
              <option value="/1/0.457183862/-1/1.59007716">Gauhati, Assam</option>
              <option value="/1/0.237537919/-1/1.353392857">
                Gauribidanur, Karnataka</option>
              <option value="/1/0.455491182/-1/1.570764109">
                Gauripur, Assam</option>
              <option value="/1/0.43286067/-1/1.484059083">Gaya, Bihar</option>
              <option value="/1/0.395597443/-1/1.532036155">Ghatal, WB</option>
              <option value="/1/0.500432099/-1/1.351855379">Ghaziabad, UP</option>
              <option value="/1/0.446838624/-1/1.449541005">Ghazipur, UP</option>
              <option value="/1/0.268447531/-1/1.378036155">Giddalur, AP</option>
              <option value="/1/0.627140653/-1/1.297122134">Gilgit, Jammu
                &amp; Kashmir</option>
              <option value="/1/0.422185626/-1/1.501917108">Giridih
                , Bihar</option>
              <option value="/1/0.45706261/-1/1.58233157">Goalpara
                , Assam</option>
              <option value="/1/0.397484127/-1/1.285239418">Godhra
                , Gujarat</option>
              <option value="3/1/0.461413139/-1/1.369660053">Gohad, MP</option>
              <option value="/1/0.282275132/-1/1.306395503">
                Gokak, Karnataka</option>
              <option value="/1/0.254033069/-1/1.297548942">
                Gokarn, Karnataka</option>
              <option value="/1/0.490295414/-1/1.397412257">Gola
                Gokarannath, UP</option>
              <option value="/1/0.462853616/-1/1.640736332">
                Golaghat, Assam</option>
              <option value="/1/0.473645062/-1/1.431062169">Gonda, UP</option>
              <option value="/1/0.383447972/-1/1.236248677">
                Gondal, Gujarat</option>
              <option value="/1/0.374693563/-1/1.40028351">
                Gondia, Maharashtra</option>
              <option value="/1/0.263932099/-1/1.345341711">Gooty, AP</option>
              <option value="/1/0.462111552/-1/1.474436508">
                Gopalganj, Bihar</option>
              <option value="/1/0.199983686/-1/1.352097884">
                Gopichettipalaiyam, TN</option>
              <option value="/1/0.441440476/-1/1.439263668">Gopiganj, UP</option>
              <option value="/1/0.467145944/-1/1.455530864">Gorakhpur, UP</option>
              <option value="/1/0.480095679/-1/1.352481041">Govardhan, UP</option>
              <option value="/1/0.15731746/-1/1.348794974">Gudalur, TN</option>
              <option value="/1/0.287008818/-1/1.412753086">Gudivada, AP</option>
              <option value="/1/0.225951058/-1/1.376940035">
                Gudiyattam, TN</option>
              <option value="/1/0.211585097/-1/1.390277778">
                Gudraippattu, TN</option>
              <option value="/1/0.24695194/-1/1.394123898">Gudur, AP</option>
              <option value="/1/0.498986772/-1/1.358238095">Gulaothi, UP</option>
              <option value="/1/0.302635802/-1/1.341481041">
                Gulbarga, Karnataka</option>
              <option value="/1/0.402319665/-1/1.47622619">Gumla, Bihar</option>
              <option value="/1/0.240850529/-1/1.360595238">
                Gummanayakanapalaya , Karnataka</option>
              <option value="/1/0.430304674/-1/1.349857143">Guna , MP</option>
              <option value="/1/0.206157848/-1/1.339046296">Gundalpet
                , Karnataka</option>
              <option value="/1/0.264834215/-1/1.350924162">Guntakal , AP</option>
              <option value="/1/0.284574074/-1/1.397412257">Guntur, AP</option>
              <option value="/1/0.559317019/-1/1.316629189">
                Gurdaspur, Punjab</option>
              <option value="/1/0.496983686/-1/1.346500882">
                Gurgaon, Haryana</option>
              <option value="/1/0.447226631/-1/1.382415785">Gursarai, UP</option>
              <option value="/1/0.458231481/-1/1.364237654">Gwalior, MP</option>
              <option value="/1/0.398555996/-1/1.537419753">Habra, WB</option>
              <option value="/1/0.262249118/-1/1.325694004">
                Hadagalli, Karnataka</option>
              <option value="/1/0.448351852/-1/1.487686949">
                Hajipur, Bihar</option>
              <option value="/1/0.510205026/-1/1.388570547">Haldwani, UP</option>
              <option value="/1/0.267627866/-1/1.305241182">
                Haliyal, Karnataka</option>
              <option value="/1/0.453153439/-1/1.399381393">Hamirpur, UP</option>
              <option value="/1/0.257743386/-1/1.311686949">
                Hangal, Karnataka</option>
              <option value="/1/0.508143739/-1/1.326246914">
                Hansi, Haryana</option>
              <option value="/1/0.394714727/-1/1.540155203">Haora, WB</option>
              <option value="/1/0.50158642/-1/1.358058642">Hapur, UP</option>
              <option value="/1/0.389903439/-1/1.345991623">Harda Khas,
                MP</option>
              <option value="/1/0.478354497/-1/1.399085538">Hardoi, UP</option>
              <option value="/1/0.253402557/-1/1.310712081">
                Harihar, Karnataka</option>
              <option value="/1/0.159097443/-1/1.334821869">
                Haripad, Kerala</option>
              <option value="/1/0.258155644/-1/1.326639771">
                Harpanahalli, Karnataka</option>
              <option value="/1/0.210377425/-1/1.362496473">Hapur, TN</option>
              <option value="/1/0.501450617/-1/1.366929453">Hasanpur, UP</option>
              <option value="/1/0.227105379/-1/1.328633157">
                Hassan, Karnataka</option>
              <option value="/1/0.481890212/-1/1.364261905">Hathras, UP</option>
              <option value="/1/0.258194444/-1/1.310130071">
                Haveri, Karnataka</option>
              <option value="/1/0.418974868/-1/1.490601852">
                Hazaribag, Jharkhand</option>
              <option value="/1/0.419173721/-1/1.490456349">
                Hazaribagh, Jharkhand</option>
              <option value="/1/0.270334215/-1/1.311657848">
                Hebli, Karnataka</option>
              <option value="/1/0.466748236/-1/1.345011905">
                Hindaun, Rajasthan</option>
              <option value="/1/0.241335538/-1/1.345050705">Hindupur, AP</option>
              <option value="/1/0.358916226/-1/1.376537478">
                Hinganghat, Maharashtra</option>
              <option value="/1/0.34429321/-1/1.34693739">
                Hingoli, Maharashtra</option>
              <option value="/1/0.252374339/-1/1.316925044">
                Hirekerur, Karnataka</option>
              <option value="/1/0.509021605/-1/1.310716931">
                Hisar, Haryana</option>
              <option value="/1/0.433534832/-1/1.491319665">Hisua, Bihar</option>
              <option value="/1/0.487074956/-1/1.35079321">Hodal, Haryana</option>
              <option value="/1/0.223196208/-1/1.331130952">Hole
                Narsipur, Karnataka</option>
              <option value="/1/0.24859612/-1/1.320746914">
                Honnali, Karnataka</option>
              <option value="/1/0.39712037/-1/1.345642416">
                Hoshangabad, MP</option>
              <option value="/1/0.550485009/-1/1.32547575">
                Hoshiarpur, Punjab</option>
              <option value="/1/0.228191799/-1/1.358213845">
                Hoskote, Karnataka</option>
              <option value="/1/0.266638448/-1/1.333541446">
                Hospet, Karnataka</option>
              <option value="/1/0.222318342/-1/1.358858907">Hosur, TN</option>
              <option value="/1/0.267918871/-1/1.311007937">
                Hubli, Karnataka</option>
              <option value="/1/0.214747354/-1/1.332052469">
                Hunsur, Karnataka</option>
              <option value="/1/0.303712522/-1/1.362515873">Hyderabad, AP</option>
              <option value="/1/0.291213845/-1/1.300129189">Ichalkaranji
                , Maharashtra</option>
              <option value="/1/0.333656966/-1/1.478791887">Ichchapuram
                , AP</option>
              <option value="/1/0.202205026/-1/1.345923721">Idappadi, TN</option>
              <option value="/1/0.159296296/-1/1.37280776">
                Ilaiyankudi, TN</option>
              <option value="/1/0.278652116/-1/1.328875661">
                Ilkal, Karnataka</option>
              <option value="/1/0.432797619/-1/1.640236772">
                Imphal, Manipur</option>
              <option value="/1/0.396819665/-1/1.324651235">Indore, MP</option>
              <option value="/1/0.276231922/-1/1.400152557">Inkollu, AP</option>
              <option value="/1/0.18040873/-1/1.330742945">
                Irinjalakuda, Kerala</option>
              <option value="/1/0.458362434/-1/1.536808642">Islampur, WB</option>
              <option value="/1/0.394763228/-1/1.345608466">Itarsi, MP</option>
              <option value="/1/0.404444004/-1/1.395850529">Jabalpur, MP</option>
              <option value="/1/0.526797178/-1/1.3447694">
                Jagadhari, Haryana</option>
              <option value="/1/0.333239859/-1/1.43222134">Jagdalpur, MP</option>
              <option value="/1/0.444578483/-1/1.473932099">
                Jagdispur, Bihar</option>
              <option value="/1/0.537462522/-1/1.317856261">
                Jagraoh, Punjab</option>
              <option value="/1/0.440174603/-1/1.483753527">
                Jahanabad, Bihar</option>
              <option value="/1/0.455825838/-1/1.402994709">Jahanabad, UP</option>
              <option value="/1/0.495989418/-1/1.363704145">
                Jahangirabad, UP</option>
              <option value="/1/0.469769841/-1/1.310707231">
                Jaipur, Rajasthan</option>
              <option value="/1/0.458541887/-1/1.423825838">Jais, UP</option>
              <option value="/1/0.469886243/-1/1.238154762">
                Jaisalmer, Rajasthan</option>
              <option value="/1/0.517135802/-1/1.345070106">Jalabad, UP</option>
              <option value="/1/0.484111552/-1/1.353242504">Jalabad, UP</option>
              <option value="/1/0.486648148/-1/1.366240741">Jalai, UP</option>
              <option value="/1/0.204101411/-1/1.359702822">
                Jalakandapuram, TN</option>
              <option value="/1/0.456451499/-1/1.37995679">Jalaun, UP</option>
              <option value="/1/0.479688272/-1/1.362229718">Jalesar, UP</option>
              <option value="/1/0.366894621/-1/1.319369489">
                Jalgaon, Maharashtra</option>
              <option value="/1/0.346393298/-1/1.325010141">
                Jalna, Maharashtra</option>
              <option value="/1/0.442512346/-1/1.267890653">
                Jalor, Rajasthan</option>
              <option value="/1/0.46308157/-1/1.537681658">Jalpaiguri, WB</option>
              <option value="/1/0.441969136/-1/1.510079806">
                Jamalpur, Bihar</option>
              <option value="/1/0.288070988/-1/1.314538801">
                Jamkhandi, Karnataka</option>
              <option value="/1/0.259091711/-1/1.368554233">
                Jammalamadugu, AP</option>
              <option value="/1/0.571093034/-1/1.293562169">Jammu, Jammu
                &amp; Kashmir</option>
              <option value="/1/0.392231481/-1/1.222261023">Jamnagar
                , Gujarat</option>
              <option value="/1/0.397716931/-1/1.505234568">Jamshedpur
                , Jharkhand</option>
              <option value="/1/0.427384921/-1/1.537162698">Jangipur , WB</option>
              <option value="/1/0.51204321/-1/1.359198413">Jansath, UP</option>
              <option value="/1/0.412708554/-1/1.311017637">Jaora, MP</option>
              <option value="/1/0.511194444/-1/1.376333774">Jaspur, UP</option>
              <option value="/1/0.475575397/-1/1.362787478">Jasrana, UP</option>
              <option value="/1/0.469449735/-1/1.377720899">
                Jaswantnagar, UP</option>
              <option value="/1/0.449709877/-1/1.443847002">Jaunpur, UP</option>
              <option value="/1/0.195676808/-1/1.37996164">
                Jayamkondacholapuram, TN</option>
              <option value="/1/0.464148589/-1/1.503376984">
                Jayanagar, Bihar</option>
              <option value="/1/0.387070988/-1/1.543880071">Jaynagar, WB</option>
              <option value="/1/0.401674603/-1/1.223696649">
                Jetpur, Gujarat</option>
              <option value="/1/0.379781305/-1/1.233052469">
                Jetpur, Gujarat</option>
              <option value="/1/0.432462963/-1/1.50811552">Jhajha, Bihar</option>
              <option value="/1/0.499340829/-1/1.338406085">
                Jhajjar, Haryana</option>
              <option value="/1/0.426589506/-1/1.493293651">Jhalpo, Bihar</option>
              <option value="/1/0.444229277/-1/1.371789242">Jhansi, UP</option>
              <option value="/1/0.41467284/-1/1.508925485">
                Jharia, Jharkhand</option>
              <option value="/1/0.423121693/-1/1.541037919">Jiaganj, WB</option>
              <option value="/1/0.511766755/-1/1.332338624">Jind, Haryana</option>
              <option value="/1/0.458881393/-1/1.275005732">
                Jodhpur, Rajasthan</option>
              <option value="/1/0.467165344/-1/1.644926808">Jorhat, Assam</option>
              <option value="/1/0.546832892/-1/1.319631393">
                Jullundur, Punjab</option>
              <option value="/1/0.37566358/-1/1.230190917">
                Junagarh, Gujarat</option>
              <option value="/1/0.178555996/-1/1.345045855">Kadaikan, TN</option>
              <option value="/1/0.157511464/-1/1.350410053">
                Kadiyanallur, TN</option>
              <option value="/1/0.216488536/-1/1.378763668">Kadaladi, TN</option>
              <option value="/1/0.14244224/-1/1.359436067">Kadambur, TN</option>
              <option value="/1/0.24637963/-1/1.364703263">Kadiri, AP</option>
              <option value="/1/0.513260582/-1/1.348009259">Kairana, UP</option>
              <option value="/1/0.520235009/-1/1.333953704">
                Kaithal, Haryana</option>
              <option value="/1/0.486982804/-1/1.382663139">Kakala, UP</option>
              <option value="/1/0.295865079/-1/1.435912257">Kakinada, AP</option>
              <option value="/1/0.469115079/-1/1.398033069">Kakori, UP</option>
              <option value="/1/0.177532628/-1/1.334778219">
                Kaladi, Kerala</option>
              <option value="/1/0.240123016/-1/1.391650353">Kalahasti, AP</option>
              <option value="/1/0.157239859/-1/1.373040564">
                Kalaiyarkovil, TN</option>
              <option value="/1/0.139963845/-1/1.354164021">Kalakkadu, TN</option>
              <option value="/1/0.193426367/-1/1.345113757">
                Kalappatti, TN</option>
              <option value="/1/0.222953704/-1/1.386635362">Kalavai, TN</option>
              <option value="/1/0.264970018/-1/1.308936949">
                Kalghatgi, Karnataka</option>
              <option value="/1/0.194954145/-1/1.327275132">
                Kalikavu, Kerala</option>
              <option value="/1/0.472708995/-1/1.544850088">Kalimpong, WB</option>
              <option value="/1/0.53844224/-1/1.328473104">Kalka, Haryana</option>
              <option value="/1/0.204964727/-1/1.378700617">
                Kallakkurichchi, TN</option>
              <option value="/1/0.139983245/-1/1.352558642">
                Kallidaikurichchi, TN</option>
              <option value="/1/0.405855379/-1/1.265839065">
                Kallol, Gujarat</option>
              <option value="/1/0.456039242/-1/1.392402116">Kalpi, UP</option>
              <option value="/1/0.158534832/-1/1.345652116">
                Kalugumalai, TN</option>
              <option value="/1/0.253315256/-1/1.38653836">Kaluvaya, AP</option>
              <option value="/1/0.253960317/-1/1.346282628">
                Kalyandrug, AP</option>
              <option value="/1/0.254910935/-1/1.373651675">
                Kamalapuram, AP</option>
              <option value="/1/0.482826279/-1/1.34914903">
                Kaman, Rajasthan</option>
              <option value="/1/0.190399912/-1/1.348217813">
                Kamanaayakkanpalaiyam, TN</option>
              <option value="/1/0.370440035/-1/1.379680335">
                Kamthi, Maharashtra</option>
              <option value="/1/0.158263228/-1/1.362496473">Kamudi, TN</option>
              <option value="/1/0.323835538/-1/1.397160053">Kanakanur, AP</option>
              <option value="/1/0.21891358/-1/1.345045855">
                Kanakapura, Karnataka</option>
              <option value="/1/0.271847443/-1/1.334167108">
                Kanakgiri, Karnataka</option>
              <option value="/1/0.224049824/-1/1.391582451">
                Kanchipuram, TN</option>
              <option value="/1/0.399196208/-1/1.543443563">
                Kancgarapara, WB</option>
              <option value="/1/0.511975309/-1/1.34475">Kandhla, UP</option>
              <option value="/1/0.26562963/-1/1.395186067">Kandukur, AP</option>
              <option value="/1/0.2585097/-1/1.345671517">Kanekalla, AP</option>
              <option value="/1/0.192204145/-1/1.354280423">Kangayam, TN</option>
              <option value="/1/0.268917989/-1/1.38821649">Kani Giri, AP</option>
              <option value="/1/0.2254806/-1/1.384244268">Kanippettai, TN</option>
              <option value="/1/0.353886684/-1/1.423011023">Kanker, MP</option>
              <option value="/1/0.472417989/-1/1.380834656">Kannauj, UP</option>
              <option value="/1/0.465045855/-1/1.389031305">Kansua, UP</option>
              <option value="/1/0.507333774/-1/1.372759259">Kanth, UP</option>
              <option value="/1/0.380314815/-1/1.532205908">Kanthi, WB</option>
              <option value="/1/0.401960758/-1/1.275815697">
                Kapadvanj, Gujarat</option>
              <option value="/1/0.54781261/-1/1.31012037">
                Kapurthala, Punjab</option>
              <option value="/0.301796737/-1/1.295216049">
                Karad, Maharashtra</option>
              <option value="/1/0.175718695/-1/1.375397707">
                Karaikkudi, TN</option>
              <option value="/1/0.462640212/-1/1.344924603">
                Karauli, Rajasthan</option>
              <option value="/1/0.444578483/-1/1.364378307">Karera, MP</option>
              <option value="/1/0.471525573/-1/1.378147707">Karhal, UP</option>
              <option value="/1/0.190792769/-1/1.393992945">Karaikal, TN</option>
              <option value="/1/0.214892857/-1/1.365508377">
                Karimanglam, TN</option>
              <option value="/1/0.321866402/-1/1.381790123">
                Karimnagar, AP</option>
              <option value="/1/0.23044224/-1/1.309407407">
                Karkal, Karnataka</option>
              <option value="/1/0.518387125/-1/1.344124339">
                Karnal, Haryana</option>
              <option value="/1/0.157511464/-1/1.327891093">
                Karunagapalli, Kerala</option>
              <option value="/1/0.191292328/-1/1.363325838">Karur, TN</option>
              <option value="/1/0.194095679/-1/1.328967813">
                Karuvambaram, Kerala</option>
              <option value="/1/0.23431746/-1/1.387343474">
                Karvetnagar, AP</option>
              <option value="/1/0.258630952/-1/1.294347884">
                Karwar, Karnataka</option>
              <option value="/1/0.440189153/-1/1.412757937">Karwi, UP</option>
              <option value="/1/0.218118166/-1/1.309358907">
                Kasaragod, Kerala</option>
              <option value="/1/0.451291005/-1/1.528277337">Kasba, Bihar</option>
              <option value="/1/0.485522928/-1/1.373152116">Kasganj, UP</option>
              <option value="/1/0.510098325/-1/1.378642416">Kashipur, UP</option>
              <option value="/1/0.565132275/-1/1.318637125">Kathua, Jammu
                &amp; Kashmir</option>
              <option value="/1/0.446368166/-1/1.503828042">Katihar
                , Bihar</option>
              <option value="/1/0.279030423/-1/1.311900353">Katkol
                , Karnataka</option>
              <option value="/1/0.486522046/-1/1.367409612">Kauriaganj
                , UP</option>
              <option value="/1/0.260372134/-1/1.396723545">Kavali, AP</option>
              <option value="/1/0.199503527/-1/1.354227072">
                Kavundappadi, TN</option>
              <option value="/1/0.384306437/-1/1.41844224">Kawardha, MP</option>
              <option value="/1/0.140701058/-1/1.364164903">
                Kayalapatnam, TN</option>
              <option value="/1/0.158869489/-1/1.327861993">
                Kayankulam, Kerala</option>
              <option value="/1/0.141661376/-1/1.357908289">Kayattar, TN</option>
              <option value="/1/0.445281746/-1/1.509580247">
                Khagaria, Bihar</option>
              <option value="/1/0.44662037/-1/1.484820547">Khagaul, Bihar</option>
              <option value="/1/0.48785582/-1/1.35906261">Khair, UP</option>
              <option value="/1/0.480609788/-1/1.410022487">Khairabad, UP</option>
              <option value="/1/0.389597884/-1/1.267958554">
                Khambhat, Gujarat</option>
              <option value="/1/0.361389771/-1/1.327876543">
                Khamgaon, Maharashtra</option>
              <option value="/1/0.301074074/-1/1.396844797">Khammam, AP</option>
              <option value="/1/0.481962963/-1/1.310430776">
                Khandela, Rajasthan</option>
              <option value="/1/0.381018078/-1/1.333037037">Khandawa, MP</option>
              <option value="/1/0.498225309/-1/1.364257055">Khanpur, UP</option>
              <option value="/1/0.438598325/-1/1.511146825">
                Kharagpur, Bihar</option>
              <option value="/1/0.389893739/-1/1.519362875">Kharagpur, WB</option>
              <option value="/1/0.503996914/-1/1.349367284">Khekra, UP</option>
              <option value="/1/0.487167108/-1/1.41074515">Kheri, UP</option>
              <option value="/1/0.491556437/-1/1.391742504">Khudaganj, UP</option>
              <option value="/1/0.419784832/-1/1.367627866">Khurai, MP</option>
              <option value="/1/0.493268519/-1/1.345899471">Khurja, UP</option>
              <option value="/1/0.158248677/-1/1.363102734">Kilakarai, TN</option>
              <option value="/1/0.185142416/-1/1.339119048">
                Kilakkottara, Kerala</option>
              <option value="/1/0.177750882/-1/1.363068783">
                Kilsevvalapatti, TN</option>
              <option value="/1/0.141447972/-1/1.328463404">
                Kilimanur, Kerala</option>
              <option value="/1/0.202908289/-1/1.382614638">Kiranur, TN</option>
              <option value="/1/0.184550705/-1/1.375640212">Kiranur, TN</option>
              <option value="/1/0.515220018/-1/1.365479277">Kiratpur, UP</option>
              <option value="/1/0.193222663/-1/1.359862875">Kodumudi, TN</option>
              <option value="/1/0.273753527/-1/1.357903439">Kodumuru, AP</option>
              <option value="/1/0.448177249/-1/1.643282628">
                Kohima, Nagaland</option>
              <option value="/1/0.265896384/-1/1.367419312">
                Koilkuntla, AP</option>
              <option value="/1/0.139823192/-1/1.348955026">Kolachel, TN</option>
              <option value="/1/0.22936067/-1/1.364286155">
                Kolar, Karnataka</option>
              <option value="/1/0.225907407/-1/1.366463845">Kolar Gold
                Fields , Karnataka</option>
              <option value="/1/0.440339506/-1/1.355051587">Kolaras , MP</option>
              <option value="/1/0.291543651/-1/1.296016314">Kolhapur
                , Maharashtra</option>
              <option value="/1/0.212259259/-1/1.346243827">Kollegal
                , Karnataka</option>
              <option value="/1/0.296582892/-1/1.362748677">Kolur , AP</option>
              <option value="/1/0.194566138/-1/1.326319665">Kondotti
                , Kerala</option>
              <option value="/1/0.204193563/-1/1.352388889">Kongadak , TN</option>
              <option value="/1/0.45432716/-1/1.459042328">Kopagani, UP</option>
              <option value="/1/0.346960758/-1/1.300264991">
                Kopargaon, Maharashtra</option>
              <option value="/1/0.267802469/-1/1.329549824">
                Koppal, Karnataka</option>
              <option value="/1/0.485348325/-1/1.352005732">Kosi, UP</option>
              <option value="/1/0.276838183/-1/1.366551146">Kosigi, AP</option>
              <option value="/1/0.439558642/-1/1.324137125">
                Kota, Rajasthan</option>
              <option value="/1/0.519560847/-1/1.371197531">Kotdwara, UP</option>
              <option value="/1/0.533965608/-1/1.306604056">
                Kotkapura, Punjab</option>
              <option value="/1/0.30633157/-1/1.408116402">Kottagudem, AP</option>
              <option value="/1/0.192757055/-1/1.325582451">
                Kottakkal, Kerala</option>
              <option value="/1/0.238406085/-1/1.366463845">Kottakota, AP</option>
              <option value="/1/0.200546296/-1/1.346520282">
                Kottamanglam, TN</option>
              <option value="/1/0.269538801/-1/1.399701499">
                Kottapatnam, AP</option>
              <option value="/1/0.142544092/-1/1.340525573">
                Kottarakara, Kerala</option>
              <option value="/1/0.158651235/-1/1.336092593">
                Kottayam, Kerala</option>
              <option value="/1/0.258829806/-1/1.330825397">
                Kotturu, Karnataka</option>
              <option value="/1/0.15728351/-1/1.359664021">
                Kovilapatti, TN</option>
              <option value="/1/0.253324956/-1/1.380829806">Kovur, AP</option>
              <option value="/1/0.196520723/-1/1.323162257">
                Kozhikode, Kerala</option>
              <option value="/1/0.218768078/-1/1.365678131">
                Krishnagiri, TN</option>
              <option value="/1/0.40866843/-1/1.545296296">
                Krishnanagar, WB</option>
              <option value="/1/0.220926367/-1/1.33540873">
                Krishnarajpet, Karnataka</option>
              <option value="/1/0.473965168/-1/1.307001764">
                Kuchaman, Rajasthan</option>
              <option value="/1/0.26017328/-1/1.333580247">
                Kudligi, Karnataka</option>
              <option value="/1/0.270421517/-1/1.326775573">
                Kuknur, Karnataka</option>
              <option value="/1/0.140744709/-1/1.364257055">
                Kulashekharapatnam, TN</option>
              <option value="/1/0.441998236/-1/1.390559083">Kulpahar, UP</option>
              <option value="/1/0.414100529/-1/1.517296737">Kulti, WB</option>
              <option value="/1/0.189289242/-1/1.362782628">Kulumani, TN</option>
              <option value="/1/0.191340829/-1/1.386029101">
                Kumbakonam, TN</option>
              <option value="/1/0.476928571/-1/1.345055556">
                Kumher, Rajasthan</option>
              <option value="/1/0.251811728/-1/1.299037919">
                Kumta, Karnataka</option>
              <option value="/1/0.453944004/-1/1.382061728">Kunch, UP</option>
              <option value="/1/0.189395944/-1/1.352160935">Kundadam, TN</option>
              <option value="/1/0.500776455/-1/1.363088183">Kundarkhi, UP</option>
              <option value="/1/0.265590829/-1/1.312395062">
                Kundgol, Karnataka</option>
              <option value="/1/0.372588624/-1/1.245104938">
                Kundla, Gujarat</option>
              <option value="/1/0.227333333/-1/1.344813051">
                Kunigal, Karnataka</option>
              <option value="/1/0.196695326/-1/1.351617725">Kunnatur, TN</option>
              <option value="/1/0.222551146/-1/1.367909171">Kuppam, AP</option>
              <option value="/1/0.238323633/-1/1.370276014">
                Kurabalakota, AP</option>
              <option value="/1/0.478451499/-1/1.37887037">Kurauli, UP</option>
              <option value="/1/0.20187522/-1/1.389802469">
                Kurinjippadi, TN</option>
              <option value="/1/0.276265873/-1/1.362598325">Kurnool, AP</option>
              <option value="/1/0.469391534/-1/1.541377425">Kurseong, WB</option>
              <option value="/1/0.275106702/-1/1.327313933">
                Kushtagi, Karnataka</option>
              <option value="/1/0.206448854/-1/1.319374339">
                Kuttaparamba, Kerala</option>
              <option value="/1/0.142253086/-1/1.348115961">
                Kuzhittora, TN</option>
              <option value="/1/0.48564903/-1/1.310067019">
                Lachhmangarh, Rajasthan</option>
              <option value="/1/0.477685185/-1/1.341805996">
                Lachhmangarh, Rajasthan</option>
              <option value="/1/0.482520723/-1/1.298843915">
                Ladnun, Rajasthan</option>
              <option value="/1/0.457382716/-1/1.363384039">Lahar, MP</option>
              <option value="/1/0.483835097/-1/1.412626984">Laharpur, UP</option>
              <option value="/1/0.451586861/-1/1.487095238">
                Laiganj, Bihar</option>
              <option value="/1/0.488001323/-1/1.410395944">Lakhimpur, UP</option>
              <option value="/1/0.18973545/-1/1.355706349">
                Lakkamapayakkanpatti, TN</option>
              <option value="/1/0.247684303/-1/1.374097884">
                Lakkireddipalle, AP</option>
              <option value="/1/0.264130952/-1/1.310130071">
                Lakhamoshwar, Karnataka</option>
              <option value="/1/0.431129189/-1/1.362501323">Lalitpur, UP</option>
              <option value="/1/0.457867725/-1/1.364936067">Lashkar, MP</option>
              <option value="/1/0.32122619/-1/1.337125661">
                Latur, Maharashtra</option>
              <option value="/1/0.596798501/-1/1.354571429">Leh, Jammu &amp;
                Kashmir</option>
              <option value="/1/0.39380776/-1/1.253636243">
                Limbdi, Gujarat</option>
              <option value="/1/0.409056437/-1/1.478660935">
                Lohardagga, Bihar</option>
              <option value="/1/0.439505291/-1/1.503401235">
                Lukhisarai, Bihar</option>
              <option value="/1/0.468794974/-1/1.412884039">Lucknow, UP</option>
              <option value="/1/0.539625661/-1/1.324306878">
                Ludhiana, Punjab</option>
              <option value="/1/0.449603175/-1/1.62687963">Lumding, Assam</option>
              <option value="/1/0.287692681/-1/1.38711552">Macherala, AP</option>
              <option value="/1/0.448410053/-1/1.438880511">
                Machhlishahr, UP</option>
              <option value="/1/0.282580688/-1/1.41460097">
                Machilipatnam, AP</option>
              <option value="/1/0.243265873/-1/1.34475485">Madakasira, AP</option>
              <option value="/1/0.236558201/-1/1.370717372">
                Madanappale, AP</option>
              <option value="/1/0.266235891/-1/1.345026455">Maddikera, AP</option>
              <option value="/1/0.266643298/-1/1.291161376">Madgaon, Goa</option>
              <option value="/1/0.456926808/-1/1.508042769">
                Madhepura, Bihar</option>
              <option value="/1/0.499782187/-1/1.396849647">Madho Tanda,
                UP</option>
              <option value="/1/0.565093474/-1/1.31998545">
                Madhopur, Punjab</option>
              <option value="/1/0.460161817/-1/1.503343034">
                Madhubani, Bihar</option>
              <option value="/1/0.238372134/-1/1.348091711">
                Madhugiri, Karnataka</option>
              <option value="/1/0.423689153/-1/1.512601852">
                Madhupur, Jharkhand</option>
              <option value="/1/0.228055996/-1/1.401151675">Madras, TN</option>
              <option value="/1/0.182998677/-1/1.386339506">Madukkur, TN</option>
              <option value="/1/0.158549383/-1/1.364029101">Madurai, TN</option>
              <option value="/1/0.218380071/-1/1.394924162">
                Madurantakam, TN</option>
              <option value="/1/0.226091711/-1/1.3447403">
                Magedi, Karnataka</option>
              <option value="/1/0.220286155/-1/1.400210758">
                Mahabalipuram, TN</option>
              <option value="/1/0.47903836/-1/1.35739418">Mahaban, UP</option>
              <option value="/1/0.455893739/-1/1.467554233">
                Maharajganj, Bihar</option>
              <option value="/1/0.436847443/-1/1.392115961">
                Maharajpur, MP</option>
              <option value="/1/0.292314815/-1/1.345943122">
                Mahbunagar, AP</option>
              <option value="/1/0.412053792/-1/1.263976631">
                Mahesana, Gujarat</option>
              <option value="/1/0.476637566/-1/1.416385802">
                Mahmudabad, UP</option>
              <option value="/1/0.441566578/-1/1.394594356">Mahoba, UP</option>
              <option value="/1/0.368165344/-1/1.253059083">
                Mahuva, Gujarat</option>
              <option value="/1/0.423727954/-1/1.410041887">Maihar, MP</option>
              <option value="/1/0.475425044/-1/1.379840388">Mainpur, UP</option>
              <option value="/1/0.252611993/-1/1.39864418">Maipadu, AP</option>
              <option value="/1/0.472160935/-1/1.304712522">
                Makrana, Rajasthan</option>
              <option value="/1/0.21615873/-1/1.345399912">
                Malavalli, Karnataka</option>
              <option value="/1/0.358809524/-1/1.301351411">
                Malegaon, Maharashtra</option>
              <option value="/1/0.532995591/-1/1.324859788">Maler
                Kotla, Punjab</option>
              <option value="/1/0.43725485/-1/1.391145944">Malhara, MP</option>
              <option value="/1/0.29533157/-1/1.29084612">
                Malkapur, Maharashtra</option>
              <option value="/1/0.472131834/-1/1.399410494">Mallanwan, UP</option>
              <option value="/1/0.226794974/-1/1.360643739">
                Malur, Karnataka</option>
              <option value="/1/0.204984127/-1/1.362705026">Malur, TN</option>
              <option value="/1/0.157579365/-1/1.369892857">
                Manamudarai, TN</option>
              <option value="/1/0.185195767/-1/1.369291446">
                Manapparai, TN</option>
              <option value="/1/0.184720459/-1/1.327517637">
                Manattala, Kerala</option>
              <option value="/1/0.440184303/-1/1.310993386">
                Mandalgarh, Rajasthan</option>
              <option value="/1/0.329350088/-1/1.474790564">Mandasa, AP</option>
              <option value="/1/0.553661817/-1/1.343387125">Mandi, HP</option>
              <option value="/1/0.394447972/-1/1.403387566">Mandla, MP</option>
              <option value="/1/0.398672399/-1/1.210805115">
                Mandvi, Gujarat</option>
              <option value="/1/0.218646825/-1/1.342645062">
                Mandya, Karnataka</option>
              <option value="/1/0.224699735/-1/1.30677381">
                Mangalore, Karnataka</option>
              <option value="/1/0.520133157/-1/1.359799824">Manglaur, UP</option>
              <option value="/1/0.368795855/-1/1.224123457">
                Mangrol, Gujarat</option>
              <option value="/1/0.437579806/-1/1.415852293">Manikpur, UP</option>
              <option value="/1/0.353566578/-1/1.292689153">
                Manmad, Maharashtra</option>
              <option value="/1/0.190438713/-1/1.374107584">
                Mannachchanellur, TN</option>
              <option value="/1/0.196850529/-1/1.380276896">
                Mannargudi, TN</option>
              <option value="/1/0.186287037/-1/1.387309524">
                Mannargudi, TN</option>
              <option value="/1/0.276726631/-1/1.311628748">
                Manoli, Karnataka</option>
              <option value="/1/0.523402116/-1/1.316386684">Mansa, Punjab</option>
              <option value="/1/0.279088624/-1/1.345225309">
                Manvi, Karnataka</option>
              <option value="/1/0.21284612/-1/1.395806878">Marakkanam, TN</option>
              <option value="/1/0.446984127/-1/1.432657848">Mariahu, UP</option>
              <option value="/1/0.465162257/-1/1.646779541">
                Mariana, Assam</option>
              <option value="/1/0.274772046/-1/1.384147266">Markapur, AP</option>
              <option value="/1/0.269029541/-1/1.288372575">Marmagao, Goa</option>
              <option value="/1/0.278564815/-1/1.338430335">
                Maski, Karnataka</option>
              <option value="/1/0.480032628/-1/1.345613316">Mathura, UP</option>
              <option value="/1/0.158850088/-1/1.331460758">
                Mattancheri, Kerala</option>
              <option value="/1/0.452949735/-1/1.459018078">Mau, UP</option>
              <option value="/1/0.448924162/-1/1.415784392">Mau Aimma,
                UP</option>
              <option value="/1/0.440659612/-1/1.381639771">Mau Ranipur,
                UP</option>
              <option value="/1/0.448405203/-1/1.398843034">Maudaha, UP</option>
              <option value="/1/0.46144709/-1/1.412098325">Maurawan, UP</option>
              <option value="/1/0.508056437/-1/1.360435185">Mawana, UP</option>
              <option value="/1/0.193770723/-1/1.380247795">Mayuram, TN</option>
              <option value="/1/0.206550705/-1/1.360968695">Mechcheri, TN</option>
              <option value="/1/0.470919312/-1/1.451054233">Mehndawal, UP</option>
              <option value="/1/0.1402597/-1/1.356783069">Mel Palaiyam,
                TN</option>
              <option value="/1/0.175107584/-1/1.367904321">Melur, TN</option>
              <option value="/1/0.20187037/-1/1.329486772">
                Meppadi, Kerala</option>
              <option value="/1/0.216798942/-1/1.310687831">
                Mercara, Karnataka</option>
              <option value="/1/0.197287037/-1/1.328468254">
                Mettupalaiyam, TN</option>
              <option value="/1/0.205735891/-1/1.345618166">Mettur, TN</option>
              <option value="/1/0.393701058/-1/1.322817901">Mhow, MP</option>
              <option value="/1/0.293900794/-1/1.297398589">
                Miraj, Maharashtra</option>
              <option value="/1/0.511373898/-1/1.361017196">Miranpur, UP</option>
              <option value="/1/0.460477072/-1/1.467287478">
                Mirganj, Bihar</option>
              <option value="/1/0.439093034/-1/1.44157716">Mirzapur, UP</option>
              <option value="/1/0.478970459/-1/1.406132716">Misrikh, UP</option>
              <option value="/1/0.537996032/-1/1.312501764">Moga, Punjab</option>
              <option value="/1/0.19307231/-1/1.364392857">Mohanur, TN</option>
              <option value="/1/0.44331261/-1/1.485620811">Mokameh, Bihar</option>
              <option value="/1/0.443167108/-1/1.509803351">
                Monghyr, Bihar</option>
              <option value="/1/0.503492504/-1/1.363093034">Moradabad, UP</option>
              <option value="/1/0.457945326/-1/1.365799383">Morar, MP</option>
              <option value="/1/0.398337743/-1/1.236675485">
                Morbi, Gujarat</option>
              <option value="/1/0.465254409/-1/1.468126543">
                Motihari, Bihar</option>
              <option value="/1/0.455491182/-1/1.45422134">Mubarakpur, UP</option>
              <option value="/1/0.157254409/-1/1.370877425">
                Mudukulattur, TN</option>
              <option value="/1/0.441285273/-1/1.451219136">Mughai Sarai
                , UP</option>
              <option value="/1/0.2117403/-1/1.343450176">
                Muguru, Karnataka</option>
              <option value="/1/0.488078924/-1/1.400448413">Muhamadi, UP</option>
              <option value="/1/0.454530864/-1/1.45585097">
                Muhammadabad, UP</option>
              <option value="/1/0.447284832/-1/1.462403439">
                Muhammadabad, UP</option>
              <option value="/1/0.531986772/-1/1.292946208">
                Muktsar, Punjab</option>
              <option value="/1/0.140453704/-1/1.345637566">
                Mulaikkaraippatti, TN</option>
              <option value="/1/0.188387125/-1/1.345623016">Mulanur, TN</option>
              <option value="/1/0.229792328/-1/1.368690035">
                Mulbagal, Karnataka</option>
              <option value="/1/0.228526455/-1/1.293227513">
                Mulki, Karnataka</option>
              <option value="/1/0.32388889/-1/1.266571428">
                Mumbai, Maharashtra</option>
              <option value="/1/0.265411376/-1/1.324864638">
                Mundargi, Karnataka</option>
              <option value="/1/0.426148148/-1/1.363558642">Mungaoli, MP</option>
              <option value="/1/0.448075397/-1/1.401263228">Mungra
                Badshahpur, UP</option>
              <option value="/1/0.267409612/-1/1.327570988">
                Munirabad, Karnataka</option>
              <option value="/1/0.502561287/-1/1.353140653">
                Muradnagar, UP</option>
              <option value="/1/0.606935185/-1/1.353441358">Murgi, Jammu
                &amp; Kashmir</option>
              <option value="/1/0.277347443/-1/1.308223986">
                Murgod, Karnataka</option>
              <option value="/1/0.452202822/-1/1.518873016">
                Murliganj, Bihar</option>
              <option value="/1/0.421972222/-1/1.541251323">
                Murshidabad, WB</option>
              <option value="/1/0.416181217/-1/1.403727072">Murwara, MP</option>
              <option value="/1/0.191214727/-1/1.369660053">Musiri, TN</option>
              <option value="/1/0.53187522/-1/1.363141534">Mussorie, UP</option>
              <option value="/1/0.249473986/-1/1.398488977">Muttukuru, AP</option>
              <option value="/1/0.181868607/-1/1.388463845">Muttupet, TN</option>
              <option value="/1/0.185258818/-1/1.34091843">
                Muvatagupudur, Kerala</option>
              <option value="/1/0.159698854/-1/1.336999559">
                Muvattupula, Kerala</option>
              <option value="/1/0.514526455/-1/1.356608466">
                Muzaffarnagar, UP</option>
              <option value="/1/0.456092593/-1/1.490752205">
                Muzaffarpur, Bihar</option>
              <option value="/1/0.214815256/-1/1.338260582">
                Mysore, Karnataka</option>
              <option value="/1/0.530366843/-1/1.329598325">Nabha, Punjab</option>
              <option value="/1/0.475294092/-1/1.347858907">
                Nadbai, Rajasthan</option>
              <option value="/1/0.396164903/-1/1.272110229">
                Nadiad, Gujarat</option>
              <option value="/1/0.233686949/-1/1.393221781">
                Nagalapuram, TN</option>
              <option value="/1/0.188367725/-1/1.394070547">
                Nagappattinam, TN</option>
              <option value="/1/0.232532628/-1/1.380281746">Nagari, AP</option>
              <option value="/1/0.474896384/-1/1.275786596">
                Nagaur, Rajasthan</option>
              <option value="/1/0.139876543/-1/1.345041005">Nagercoil, TN</option>
              <option value="/1/0.514152998/-1/1.362525573">Nagina, UP</option>
              <option value="/1/0.369382716/-1/1.380917108">
                Nagpur, Maharashtra</option>
              <option value="/1/0.513294533/-1/1.38712522">Nainital, UP</option>
              <option value="/1/0.517092152/-1/1.367787919">Najibabad, UP</option>
              <option value="/1/0.424130511/-1/1.533525132">Nalhati, WB</option>
              <option value="/1/0.195914462/-1/1.364785714">Namakkal, TN</option>
              <option value="/1/0.198329806/-1/1.350065697">Nambiyur, TN</option>
              <option value="/1/0.249027778/-1/1.381993827">Nandalur, AP</option>
              <option value="/1/0.33441843/-1/1.349784392">
                Nanded, Maharashtra</option>
              <option value="/1/0.276838183/-1/1.366551146">
                Nandikotkur, AP</option>
              <option value="/1/0.373054233/-1/1.296287919">
                Nandurbar, Maharashtra</option>
              <option value="/1/0.270377866/-1/1.362515873">Nandyal, AP</option>
              <option value="/1/0.211594797/-1/1.338886243">
                Nanjangud, Karnataka</option>
              <option value="/1/0.189924603/-1/1.390030423">Nannilam, TN</option>
              <option value="/1/0.486560847/-1/1.422933422">Nanpara, UP</option>
              <option value="/1/0.287028219/-1/1.426590388">Narasapur, AP</option>
              <option value="/1/0.283492504/-1/1.397776014">
                Narasaropet, AP</option>
              <option value="/1/0.271852293/-1/1.310716931">
                Naregal, Karnataka</option>
              <option value="/1/0.27446164/-1/1.31012037">
                Nargund, Karnataka</option>
              <option value="/1/0.489548501/-1/1.32882716">
                Narnaul, Haryana</option>
              <option value="/1/0.400617284/-1/1.382750441">
                Narsimhapur, MP</option>
              <option value="/1/0.213272928/-1/1.328477954">
                Narsipur, Karnataka</option>
              <option value="/1/0.349216049/-1/1.288236772">
                Nasik, Maharashtra</option>
              <option value="/1/0.459157848/-1/1.304702822">
                Nasirabad, Rajasthan</option>
              <option value="/1/0.178439594/-1/1.365954586">Nattam, TN</option>
              <option value="/1/0.478946208/-1/1.456476631">Nautanwa, UP</option>
              <option value="/1/0.408697531/-1/1.543099206">Navadwip, WB</option>
              <option value="/1/0.271619489/-1/1.31010582">
                Navalgund, Karnataka</option>
              <option value="/1/0.365822751/-1/1.2732306">
                Navsari, Gujarat</option>
              <option value="/1/0.498361111/-1/1.39040388">Nawabganj, UP</option>
              <option value="/1/0.469813492/-1/1.417593474">Nawabganj, UP</option>
              <option value="/1/0.469081129/-1/1.434224427">Nawabganj, UP</option>
              <option value="/1/0.434349647/-1/1.493599206">Nawada, Bihar</option>
              <option value="/1/0.48618254/-1/1.314242945">
                Nawalgarh, Rajasthan</option>
              <option value="/1/0.242819665/-1/1.394982363">Nayudupeta
                , AP</option>
              <option value="/1/0.140739859/-1/1.361453704">Nazareth, TN</option>
              <option value="/1/0.228710758/-1/1.351200617">
                Nelamangala, Karnataka</option>
              <option value="/1/0.205595238/-1/1.391131393">
                Nellikkuppam, TN</option>
              <option value="/1/0.252291887/-1/1.380863757">Nellore, AP</option>
              <option value="/1/0.501707672/-1/1.394904762">Neoria
                Husainpur, UP</option>
              <option value="/1/0.498817019/-1/1.34473545">New
                Delhi, Capital City</option>
              <option value="/1/0.202675485/-1/1.387940035">Neyveli, TN</option>
              <option value="/1/0.295103616/-1/1.42612963">Nidadavole, AP</option>
              <option value="/1/0.188018519/-1/1.38657231">
                Nidamangalam, TN</option>
              <option value="/1/0.196768078/-1/1.330869048">
                Nilambur, Kerala</option>
              <option value="/1/0.484111552/-1/1.310697531">Nim Kathana,
                Rajasthan</option>
              <option value="/1/0.427132716/-1/1.307147266">Nimach, MP</option>
              <option value="/1/0.333598765/-1/1.362511023">Nirmal, AP</option>
              <option value="/1/0.459371252/-1/1.511549383">
                Nirmalj, Bihar</option>
              <option value="/1/0.325925926/-1/1.363592593">Nizamabad, AP</option>
              <option value="/1/0.277657848/-1/1.398008818">
                Nizampatam, AP</option>
              <option value="/1/0.457513668/-1/1.601678571">North
                Guwahati, Assam</option>
              <option value="/1/0.460084215/-1/1.618435626">
                Nowgong, Assam</option>
              <option value="/1/0.437526455/-1/1.386994268">Nowgong, MP</option>
              <option value="/1/0.563905203/-1/1.32492769">Nurpur, HP</option>
              <option value="/1/0.293061728/-1/1.411661817">Nuzvid, AP</option>
              <option value="/1/0.247029541/-1/1.319505291">
                Nyamti, Karnataka</option>
              <option value="/1/0.270518519/-1/1.397674162">Ongole, AP</option>
              <option value="/1/0.199251323/-1/1.339109347">
                Ootacamund, TN</option>
              <option value="/1/0.453682099/-1/1.37997134">Orai, UP</option>
              <option value="/1/0.245559965/-1/1.384098765">Orampadu, AP</option>
              <option value="/1/0.188052469/-1/1.333565697">
                Ottapalam, Kerala</option>
              <option value="/1/0.46972134/-1/1.46630291">Padrauna, UP</option>
              <option value="/1/0.234880071/-1/1.381382716">Pakala, AP</option>
              <option value="/1/0.288439594/-1/1.415459436">Palakollu, AP</option>
              <option value="/1/0.456019841/-1/1.598254409">
                Palashari, Assam</option>
              <option value="/1/0.140623457/-1/1.345627866">
                Palayankottai, TN</option>
              <option value="/1/0.188013668/-1/1.338294533">
                Palghat, Kerala</option>
              <option value="/1/0.375915785/-1/1.240890212">
                Palitana, Gujarat</option>
              <option value="/1/0.324776455/-1/1.46247134">Palkonda, AP</option>
              <option value="/1/0.191874339/-1/1.349473986">Palladam, TN</option>
              <option value="/1/0.214766755/-1/1.363175485">
                Pallakkodu, TN</option>
              <option value="/1/0.225310847/-1/1.378375661">
                Pallikondaj, TN</option>
              <option value="/1/0.230568342/-1/1.374927249">Palmaner, AP</option>
              <option value="/1/0.182465168/-1/1.353354056">Palni, TN</option>
              <option value="/1/0.192800705/-1/1.380601852">Paluvur, TN</option>
              <option value="/1/0.491304233/-1/1.350186949">
                Palwal, Haryana</option>
              <option value="/1/0.159141093/-1/1.383075397">Pamban, TN</option>
              <option value="/1/0.260852293/-1/1.354852734">Pamidi, AP</option>
              <option value="/1/0.142354938/-1/1.354474427">
                Panaikkudi, TN</option>
              <option value="/1/0.270494268/-1/1.275791446">Panaji, Goa</option>
              <option value="/1/0.157676367/-1/1.378584215">
                Panalkkulam, TN</option>
              <option value="/1/0.158190476/-1/1.363767196">
                Pandalkudi, TN</option>
              <option value="/1/0.308591711/-1/1.315203263">
                Pandharpur, Maharashtra</option>
              <option value="/1/0.513076279/-1/1.328448854">
                Panipat, Haryana</option>
              <option value="/1/0.431551146/-1/1.397140653">Panna, MP</option>
              <option value="/1/0.388467813/-1/1.481585538">
                Panposh, Orissa</option>
              <option value="/1/0.205580688/-1/1.389036155">Panruti, TN</option>
              <option value="/1/0.331605379/-1/1.276465608">
                Panvel, Maharashtra</option>
              <option value="/1/0.190695767/-1/1.384084215">Papansam, TN</option>
              <option value="/1/0.140482804/-1/1.351263668">Papansam, TN</option>
              <option value="/1/0.202864638/-1/1.361667108">
                Pappambadi, TN</option>
              <option value="/1/0.182397266/-1/1.345065256">
                Pappampatti, TN</option>
              <option value="/1/0.179603616/-1/1.329559524">
                Pappinivatti, Kerala</option>
              <option value="/1/0.15784127/-1/1.3722597">Paramagudi, TN</option>
              <option value="/1/0.19471649/-1/1.362268519">Paramati, TN</option>
              <option value="/1/0.387415344/-1/1.374898148">Parasia, MP</option>
              <option value="/1/0.336246914/-1/1.340491623">
                Parbhani, Maharashtra</option>
              <option value="/1/0.327880511/-1/1.467869489">
                Parklakimidi, Orissa</option>
              <option value="/1/0.17710582/-1/1.330762346">Parur, Kerala</option>
              <option value="/1/0.327909612/-1/1.456762787">
                Parvatipuram, AP</option>
              <option value="/1/0.416438272/-1/1.259145944">
                Patan, Gujarat</option>
              <option value="/1/0.563328042/-1/1.320858466">
                Pathankot, Punjab</option>
              <option value="/1/0.561106702/-1/1.334181658">Pathiar, HP</option>
              <option value="/1/0.529406526/-1/1.333880952">
                Patiala, Punjab</option>
              <option value="/1/0.447226631/-1/1.485615961">Patna, Bihar</option>
              <option value="/1/0.157438713/-1/1.34143739">
                Pattanapuram, Kerala</option>
              <option value="/1/0.268728836/-1/1.353232804">
                Pattikonda, AP</option>
              <option value="/1/0.181965608/-1/1.384942681">
                Pattukkottai, TN</option>
              <option value="/1/0.489994709/-1/1.398678131">Pawayan, UP</option>
              <option value="/1/0.36297575/-1/1.390549383">
                Pawni, Maharashtra</option>
              <option value="/1/0.203378748/-1/1.370785273">Pedda
                Nayakkanpalaiyam , TN</option>
              <option value="/1/0.324926808/-1/1.385970899">
                Peddapalli, AP</option>
              <option value="/1/0.298222222/-1/1.434209877">
                Peddepuram, AP</option>
              <option value="/1/0.19910582/-1/1.383560406">Pennadam, TN</option>
              <option value="/1/0.211808201/-1/1.360085979">
                Pennagaram, TN</option>
              <option value="/1/0.24583642/-1/1.345351411">Penukonda, AP</option>
              <option value="/1/0.196263668/-1/1.376944885">Peramblur, TN</option>
              <option value="/1/0.225766755/-1/1.363112434">
                Peranambattu, TN</option>
              <option value="/1/0.191544533/-1/1.330878748">
                Perintalmanna, Kerala</option>
              <option value="/1/0.176654762/-1/1.353887566">
                Periyakulam, TN</option>
              <option value="/1/0.208723545/-1/1.36072619">Perumbalai, TN</option>
              <option value="/1/0.392444885/-1/1.271096561">
                Petlad, Gujarat</option>
              <option value="/1/0.546279982/-1/1.320451058">
                Phagwara, Punjab</option>
              <option value="/1/0.473776014/-1/1.263414021">
                Phaliodi, Rajasthan</option>
              <option value="/1/0.31405291/-1/1.299576279">
                Phaltan, Maharashtra</option>
              <option value="/1/0.464497795/-1/1.387362875">Phaphund, UP</option>
              <option value="/1/0.529324074/-1/1.313651235">Phul, Punjab</option>
              <option value="/1/0.446111111/-1/1.43332716">Phulpur, UP</option>
              <option value="/1/0.48233157/-1/1.400244709">Pihani, UP</option>
              <option value="/1/0.495237654/-1/1.310430776">
                Pilani, Rajasthan</option>
              <option value="/1/0.49994709/-1/1.380553351">Pilibhit, UP</option>
              <option value="/1/0.501324515/-1/1.355900353">Pilkhua, UP</option>
              <option value="/1/0.298852734/-1/1.436276014">
                Pithampuram, AP</option>
              <option value="/1/0.272473104/-1/1.380262346">Podile, AP</option>
              <option value="/1/0.186025132/-1/1.344497795">Pollachi, TN</option>
              <option value="/1/0.218428571/-1/1.381571869">Polur, TN</option>
              <option value="/1/0.208350088/-1/1.393978395">
                Pondicherry, Pondicherry City</option>
              <option value="/1/0.179361111/-1/1.371435185">
                Ponnamaravatti, TN</option>
              <option value="/1/0.188003968/-1/1.325708554">
                Ponnani, Kerala</option>
              <option value="/1/0.232828483/-1/1.400186508">Ponneri, TN</option>
              <option value="/1/0.22772619/-1/1.396835097">
                Poonamallee, TN</option>
              <option value="/1/0.377899471/-1/1.215441799">
                Porbandar, Gujarat</option>
              <option value="/1/0.203732804/-1/1.619667549">Port
                Blair, Andaman and Nicobar Island</option>
              <option value="/1/0.200623898/-1/1.392644621">Porto Novo
                , TN</option>
              <option value="/1/0.257471781/-1/1.371464286">Proddatur, AP</option>
              <option value="/1/0.182896825/-1/1.345627866">Pudu
                Chattram, TN</option>
              <option value="/1/0.181228395/-1/1.376382275">
                Pudukkottai, TN</option>
              <option value="/1/0.194352734/-1/1.339992063">Pudur, TN</option>
              <option value="/1/0.199246473/-1/1.363088183">Pulambadi, TN</option>
              <option value="/1/0.36189903/-1/1.362239418">
                Pulgaon, Maharashtra</option>
              <option value="/1/0.251748677/-1/1.365843034">
                Pulivendla, AP</option>
              <option value="/1/0.157254409/-1/1.351394621">
                Puliyangudi, TN</option>
              <option value="/1/0.191268078/-1/1.341238536">
                Puluvappatti, TN</option>
              <option value="/1/0.157734568/-1/1.328458554">
                Punalur, Kerala</option>
              <option value="/1/0.589727072/-1/1.293673721">Punch, Jammu
                &amp; Kashmir</option>
              <option value="/1/0.323636684/-1/1.276097002">Pune
                , Maharashtra</option>
              <option value="/1/0.233274691/-1/1.371857143">Punganuru
                , AP</option>
              <option value="/1/0.198160053/-1/1.34477425">Punjai
                Puliyampatti , TN</option>
              <option value="/1/0.497856702/-1/1.399420194">Puranpur, UP</option>
              <option value="/1/0.345612434/-1/1.498594797">Puri, Orissa</option>
              <option value="/1/0.328937831/-1/1.336388448">
                Purli, Maharashtra</option>
              <option value="/1/0.450059083/-1/1.527181217">Purnea, Bihar</option>
              <option value="/1/0.407329806/-1/1.508037919">Purulia, WB</option>
              <option value="/1/0.461941799/-1/1.397999118">Purwa, UP</option>
              <option value="/1/0.34759612/-1/1.354401675">
                Pusad, Maharashtra</option>
              <option value="/1/0.234361111/-1/1.389681217">Puttur, AP</option>
              <option value="/1/0.222706349/-1/1.312986772">
                Puttur, Karnataka</option>
              <option value="/1/0.142282187/-1/1.345671517">Puvar, Kerala</option>
              <option value="/1/0.19964903/-1/1.321585979">
                Quilandi, Kerala</option>
              <option value="/1/0.140497354/-1/1.337256614">
                Quilon, Kerala</option>
              <option value="/1/0.524304233/-1/1.347121693">
                Radaur, Haryana</option>
              <option value="/1/0.14132672/-1/1.356356261">Radhapuram, TN</option>
              <option value="/1/0.457940476/-1/1.41458157">Rae Bareli,
                UP</option>
              <option value="/1/0.282978395/-1/1.350628307">
                Raichur, Karnataka</option>
              <option value="/1/0.44714418/-1/1.538671076">Raiganj, WB</option>
              <option value="/1/0.381973545/-1/1.453789683">Raigarh, MP</option>
              <option value="/1/0.535047178/-1/1.320130952">
                Raikot, Punjab</option>
              <option value="/1/0.370891093/-1/1.425474868">Raipur, MP</option>
              <option value="/1/0.368369048/-1/1.414993827">Raj
                Nandgaon, MP</option>
              <option value="/1/0.29701455/-1/1.427832011">
                Rajahmundry, AP</option>
              <option value="/1/0.469667989/-1/1.364863316">
                Rajakhera, Rajasthan</option>
              <option value="/1/0.247854056/-1/1.38215873">Rajampet, AP</option>
              <option value="/1/0.159223545/-1/1.35411552">
                Rajapalaiyam, TN</option>
              <option value="/1/0.443079806/-1/1.416754409">Rajapur, UP</option>
              <option value="/1/0.430261023/-1/1.492769841">
                Rajauli, Bihar</option>
              <option value="/1/0.277381393/-1/1.358810406">Rajavolu, AP</option>
              <option value="/1/0.389306878/-1/1.236161376">
                Rajkot, Gujarat</option>
              <option value="/1/0.437628307/-1/1.533520282">
                Rajmahal, Bihar</option>
              <option value="/1/0.178890653/-1/1.376052469">
                Ramachandrapuram, TN</option>
              <option value="/1/0.221998236/-1/1.349275132">Ramanagaram
                , Karnataka</option>
              <option value="/1/0.157618166/-1/1.376547178">
                Ramanathapuram, TN</option>
              <option value="/1/0.159194444/-1/1.38487963">
                Rameshwaram, TN</option>
              <option value="/1/0.41252425/-1/1.493119048">
                Ramgarh, Jharkhand</option>
              <option value="/1/0.475793651/-1/1.312627866">
                Ramgarh, Rajasthan</option>
              <option value="/1/0.441178571/-1/1.449730159">Ramnagar, UP</option>
              <option value="/1/0.520390212/-1/1.352253086">Rampur, UP</option>
              <option value="/1/0.502958995/-1/1.382013228">Rampur, UP</option>
              <option value="/1/0.422083774/-1/1.532661817">RampurHat,
                WB</option>
              <option value="/1/0.505839947/-1/1.386305556">Rampura, UP</option>
              <option value="/1/0.373645944/-1/1.385078483">
                Ramtek, Maharashtra</option>
              <option value="/1/0.404584656/-1/1.546567019">Ranaghat, WB</option>
              <option value="/1/0.407819665/-1/1.498793651">
                Ranchi, Jharkhand</option>
              <option value="/1/0.25517769/-1/1.320344356">
                Ranibennur, Karnataka</option>
              <option value="/1/0.412208995/-1/1.521201058">Raniganj, WB</option>
              <option value="/1/0.440785714/-1/1.380335097">Ranipur, UP</option>
              <option value="/1/0.45541358/-1/1.523068342">
                Ranjganj, Bihar</option>
              <option value="/1/0.200070988/-1/1.365159171">Rasipuram, TN</option>
              <option value="/1/0.451470459/-1/1.464042769">Rasra, UP</option>
              <option value="/1/0.446867725/-1/1.38930776">Rath, UP</option>
              <option value="/1/0.407373457/-1/1.310256173">Ratlam, MP</option>
              <option value="/1/0.296684744/-1/1.279705467">
                Ratnairi, Maharashtra</option>
              <option value="/1/0.388492063/-1/1.468136243">
                Raurkela, Orissa</option>
              <option value="/1/0.471215168/-1/1.468150794">Raxaul, Bihar</option>
              <option value="/1/0.245526014/-1/1.363102734">Rayachoti, AP</option>
              <option value="/1/0.256608466/-1/1.341742945">Rayadrug, AP</option>
              <option value="/1/0.445868607/-1/1.461656526">Reotipur, UP</option>
              <option value="/1/0.450320988/-1/1.467554233">
                Revelganj, Bihar</option>
              <option value="/1/0.428199735/-1/1.419324956">Rewa, MP</option>
              <option value="/1/0.492172399/-1/1.337659171">
                Rewari, Haryana</option>
              <option value="/1/0.430964286/-1/1.45035097">
                Robertsganj, UP</option>
              <option value="/1/0.504467372/-1/1.337305115">
                Rohtak, Haryana</option>
              <option value="/1/0.274015432/-1/1.322284392">
                Ron, Karnataka</option>
              <option value="/1/0.521350529/-1/1.360061728">Roorkee, UP</option>
              <option value="/1/0.467034392/-1/1.427351852">Rudauli, UP</option>
              <option value="/1/0.449685626/-1/1.502009259">Rusera, Bihar</option>
              <option value="/1/0.458246032/-1/1.351414021">Sabalgarh, MP</option>
              <option value="/1/0.479135362/-1/1.364242504">Sadabad, UP</option>
              <option value="/1/0.530580247/-1/1.348179012">
                Sadhaura, Haryana</option>
              <option value="/1/0.466854938/-1/1.402810406">Safipur, UP</option>
              <option value="/1/0.247204145/-1/1.310003968">
                Sagar, Karnataka</option>
              <option value="/1/0.41636552/-1/1.375048501">Sagar, MP</option>
              <option value="/1/0.467267196/-1/1.479606702">
                Sagauli, Bihar</option>
              <option value="/1/0.523232363/-1/1.353950617">
                Saharanpur, UP</option>
              <option value="/1/0.490086861/-1/1.37498545">Sahaswan, UP</option>
              <option value="/1/0.451072751/-1/1.47181746">Sahatwar, UP</option>
              <option value="/1/0.485387125/-1/1.363364638">Sahawar, UP</option>
              <option value="/1/0.440693563/-1/1.530023369">
                Sahibganj, Bihar</option>
              <option value="/1/0.445985009/-1/1.45308642">Saidpur, UP</option>
              <option value="/1/0.225897707/-1/1.310716931">
                Sakleshpur, Karnataka</option>
              <option value="/1/0.2035/-1/1.364669312">Salem, TN</option>
              <option value="/1/0.323311728/-1/1.452863316">Salur, AP</option>
              <option value="/1/0.297756614/-1/1.434840388">Samalkot, AP</option>
              <option value="/1/0.526559524/-1/1.330272487">
                Samana, Punjab</option>
              <option value="/1/0.451412257/-1/1.497726631">
                Samastipur, Bihar</option>
              <option value="/1/0.374780864/-1/1.466259259">
                Sambalpur, Orissa</option>
              <option value="/1/0.499078924/-1/1.37171164">Sambhal, UP</option>
              <option value="/1/0.469798942/-1/1.309834215">
                Sambahr, Rajasthan</option>
              <option value="/1/0.451286155/-1/1.363384039">Samthar, UP</option>
              <option value="/1/0.476467813/-1/1.396000882">Sand, UP</option>
              <option value="/1/0.472607143/-1/1.405754409">Sandila, UP</option>
              <option value="/1/0.34155291/-1/1.295701058">
                Sangamner, Maharashtra</option>
              <option value="/1/0.307728395/-1/1.36344224">Sangareddi, AP</option>
              <option value="/1/0.294279101/-1/1.301914021">
                Sangli, Maharashtra</option>
              <option value="/1/0.528/-1/1.324238977">Sangrur, Punjab</option>
              <option value="/1/0.158534832/-1/1.353882716">
                Sankaranayinarkovil, TN</option>
              <option value="/1/0.405816578/-1/1.544287478">Santipur, WB</option>
              <option value="/1/0.497473545/-1/1.382018078">Saraul, UP</option>
              <option value="/1/0.496576279/-1/1.300619048">
                Sardarshahr, Rajasthan</option>
              <option value="/1/0.508885802/-1/1.355032187">Sardhana, UP</option>
              <option value="/1/0.435547619/-1/1.466933422">
                Sasaram, Bihar</option>
              <option value="/1/0.308897266/-1/1.293242063">
                Satara, Maharashtra</option>
              <option value="/1/0.428839947/-1/1.411239859">Satna, MP</option>
              <option value="/1/0.141472222/-1/1.360362434">
                Sattankulam, TN</option>
              <option value="/1/0.157472663/-1/1.360512787">Sattur, TN</option>
              <option value="/1/0.200895503/-1/1.348673721">
                Satyamangalam, TN</option>
              <option value="/1/0.275218254/-1/1.311551146">
                Saundatti, Karnataka</option>
              <option value="/1/0.277570547/-1/1.288852734">
                Savantvadi, Maharashtra</option>
              <option value="/1/0.261337302/-1/1.315266314">
                Savanpur, Karnataka</option>
              <option value="/1/0.139857143/-1/1.362404321">
                Sayarpuram, TN</option>
              <option value="/1/0.405084215/-1/1.346481481">Sehore, MP</option>
              <option value="/1/0.196942681/-1/1.362215168">
                Sendamangalam, TN</option>
              <option value="/1/0.509928571/-1/1.3722403">Seohara, UP</option>
              <option value="/1/0.456698854/-1/1.375514109">Seondha, MP</option>
              <option value="/1/0.385698413/-1/1.389031305">Seoni, MP</option>
              <option value="/1/0.498676367/-1/1.379476631">Shahabad, UP</option>
              <option value="/1/0.482617725/-1/1.395738977">Shahabad, UP</option>
              <option value="/1/0.500582451/-1/1.34474515">Shahdara, New
                Delhi</option>
              <option value="/1/0.454817019/-1/1.443662698">Shahganj, UP</option>
              <option value="/1/0.48674515/-1/1.395234568">
                Shahjahanpur, UP</option>
              <option value="/1/0.438947531/-1/1.498958554">
                Shaikhpura, Bihar</option>
              <option value="/1/0.5142403/-1/1.349871693">Shamli, UP</option>
              <option value="/1/0.471680776/-1/1.363927249">
                Shamshabad, UP</option>
              <option value="/1/0.428772046/-1/1.480436067">
                Sherghati, Bihar</option>
              <option value="/1/0.51205291/-1/1.371925044">Sherkot, UP</option>
              <option value="/1/0.446411817/-1/1.463058201">Sherpur, UP</option>
              <option value="/1/0.157438713/-1/1.327600088">
                Shertallai, Kerala</option>
              <option value="/1/0.473320106/-1/1.474722663">
                Shikarpur, Bihar</option>
              <option value="/1/0.24915388/-1/1.310134921">
                Shikarpur, Karnataka</option>
              <option value="/1/0.493845679/-1/1.362030864">Shikarpur, UP</option>
              <option value="/1/0.473295855/-1/1.362816578">
                Shikhnabad, UP</option>
              <option value="/1/0.446455467/-1/1.604176367">
                Shillong, Meghalaya</option>
              <option value="/1/0.24308642/-1/1.319481041">
                Shimoga, Karnataka</option>
              <option value="/1/0.443894621/-1/1.355856702">Shivpuri, MP</option>
              <option value="/1/0.308436508/-1/1.325208995">
                Sholapur, Maharashtra</option>
              <option value="/1/0.229006614/-1/1.386742063">
                Sholinghur, AP</option>
              <option value="/1/0.187834215/-1/1.327289683">
                Shoranur, Kerala</option>
              <option value="/1/0.479508818/-1/1.319864198">Shri
                Madhopur, Rajasthan</option>
              <option value="/1/0.471137566/-1/1.652269841">
                Sibsagar, Assam</option>
              <option value="/1/0.250361552/-1/1.307598325">
                Siddapur, Karnataka</option>
              <option value="/1/0.417544092/-1/1.263627425">
                Siddhapur, Gujarat</option>
              <option value="/1/0.252602293/-1/1.378894621">
                Siddhavattam, AP</option>
              <option value="/1/0.316070547/-1/1.363374339">Siddipet, AP</option>
              <option value="/1/0.425920194/-1/1.429597443">Sidhi, MP</option>
              <option value="/1/0.233711199/-1/1.359450617">
                Sidlaghatta, Karnataka</option>
              <option value="/1/0.496794533/-1/1.356574515">
                Sikandarabad, UP</option>
              <option value="/1/0.48345679/-1/1.362491623">Sikandra Rao,
                UP</option>
              <option value="/1/0.482021164/-1/1.311934303">
                Sikar, Rajasthan</option>
              <option value="/1/0.43342328/-1/1.619948854">Silchar, Assam</option>
              <option value="/1/0.466539683/-1/1.543918871">Siliguri, WB</option>
              <option value="/1/0.543093474/-1/1.347344797">Shimla, HP</option>
              <option value="/1/0.275368607/-1/1.340186067">
                Sindhnur, Karnataka</option>
              <option value="/1/0.494408289/-1/1.412505732">Singahi
                Kalan, UP</option>
              <option value="/1/0.251045414/-1/1.313859788">
                Siralkoppa, Karnataka</option>
              <option value="/1/0.196181217/-1/1.380563051">Sirkazhi, TN</option>
              <option value="/1/0.434529101/-1/1.271940476">
                Sirohi, Rajasthan</option>
              <option value="/1/0.420716049/-1/1.356516314">Sironj, MP</option>
              <option value="/1/0.515481922/-1/1.310033069">
                Sirsa, Haryana</option>
              <option value="/1/0.321104938/-1/1.376144621">Sirsilla, AP</option>
              <option value="/1/0.272958113/-1/1.34255291">
                Siruguppa, Karnataka</option>
              <option value="/1/0.267472663/-1/1.37116358">Sirvel, AP</option>
              <option value="/1/0.474033069/-1/1.462374339">Siswa Bazar,
                UP</option>
              <option value="/1/0.464410494/-1/1.492774691">
                Sitamarhi, Bihar</option>
              <option value="/1/0.481477954/-1/1.408615961">Sitapur, UP</option>
              <option value="/1/0.417306437/-1/1.52844709">Siuri, WB</option>
              <option value="/1/0.157336861/-1/1.370324515">Sivaganga, TN</option>
              <option value="/1/0.15732716/-1/1.351937831">Sivagiri, TN</option>
              <option value="/1/0.159150794/-1/1.358378748">Sivakasi, TN</option>
              <option value="/1/0.457804674/-1/1.47291358">Siwan, Bihar</option>
              <option value="/1/0.396286155/-1/1.362239418">Sohagpur, MP</option>
              <option value="/1/0.493244268/-1/1.346510582">
                Sohna, Haryana</option>
              <option value="/1/0.452610229/-1/1.286185185">
                Sojat, Rajasthan</option>
              <option value="/1/0.330649912/-1/1.476968254">Sompeta, AP</option>
              <option value="/1/0.219839947/-1/1.324306878">
                Somvarpet, Karnataka</option>
              <option value="/1/0.406907848/-1/1.51963448">Sonamukhi, WB</option>
              <option value="/1/0.506169753/-1/1.344716049">
                Sonepat, Haryana</option>
              <option value="/1/0.598670635/-1/1.300134039">Sopor, Jammu
                &amp; Kashmir</option>
              <option value="/1/0.486982804/-1/1.37499515">Soron, UP</option>
              <option value="/1/0.319373457/-1/1.464983686">
                Srikakulam, AP</option>
              <option value="/1/0.199028219/-1/1.386485009">
                Srimushnam, TN</option>
              <option value="/1/0.595062169/-1/1.306094797">
                Srinagar, Jammu &amp; Kashmir</option>
              <option value="/1/0.226518519/-1/1.399502646">
                Sriperumbudur, TN</option>
              <option value="/1/0.141928131/-1/1.360381834">
                Srivaikuntam, TN</option>
              <option value="/1/0.15732231/-1/1.345346561">
                Srivilliputtur, TN</option>
              <option value="/1/0.483495591/-1/1.300260141">
                Sujangarh, Rajasthan</option>
              <option value="/1/0.440664462/-1/1.517689594">
                Sultanganj, Bihar</option>
              <option value="/1/0.458430335/-1/1.433002205">Sultanpur, UP</option>
              <option value="/1/0.192456349/-1/1.346636684">Sulur, TN</option>
              <option value="/1/0.526006614/-1/1.32327381">Sunam, Punjab</option>
              <option value="/1/0.550586861/-1/1.342785714">
                Sundarnagar, HP</option>
              <option value="/1/0.494287037/-1/1.322313492">
                Surajgarh, Rajasthan</option>
              <option value="/1/0.370071429/-1/1.271450617">
                Surat, Gujarat</option>
              <option value="/1/0.396805115/-1/1.250604938">
                Surendranagar, Gujarat</option>
              <option value="/1/0.299260141/-1/1.390253527">Suriapet, AP</option>
              <option value="/1/0.293522487/-1/1.423510582">
                Tadepallegudem, AP</option>
              <option value="/1/0.260226631/-1/1.362035714">Tadpatri, AP</option>
              <option value="/1/0.394317019/-1/1.538006614">Taki, WB</option>
              <option value="/1/0.227241182/-1/1.380563051">Takkolam, TN</option>
              <option value="/1/0.19798545/-1/1.322395944">
                Talakkolattur, Kerala</option>
              <option value="/1/0.182431217/-1/1.328410053">
                Talikullam, Kerala</option>
              <option value="/1/0.199741182/-1/1.370440917">
                Tammampatti, TN</option>
              <option value="/1/0.242950617/-1/1.365290123">Tnakallu, AP</option>
              <option value="/1/0.505883598/-1/1.378336861">Tanda, UP</option>
              <option value="/1/0.463503527/-1/1.44321164">Tanda, UP</option>
              <option value="/1/0.191690035/-1/1.324723986">Tanur, Kerala</option>
              <option value="/1/0.239303351/-1/1.323589065">
                Tarikere, Karnataka</option>
              <option value="/1/0.194207231/-1/1.362525573">
                Tattayyangarpettai, TN</option>
              <option value="/1/0.275203704/-1/1.333919753">
                Tawargeri, Karnataka</option>
              <option value="/1/0.445044092/-1/1.50040388">Teghra, Bihar</option>
              <option value="/1/0.435377866/-1/1.468121693">Tekari, Bihar</option>
              <option value="/1/0.205003527/-1/1.318137566">
                Tellicherry, Kerala</option>
              <option value="/1/0.283497354/-1/1.408126102">Tenali, AP</option>
              <option value="/1/0.141758377/-1/1.349929894">Tenkasi, TN</option>
              <option value="/1/0.158229277/-1/1.349246032">Tevaram, TN</option>
              <option value="/1/0.464866402/-1/1.620380511">Tezpur, Assam</option>
              <option value="/1/0.335791005/-1/1.258602734">
                Thana, Maharashtra</option>
              <option value="/1/0.516597443/-1/1.351695326">Thana
                Bhawan, UP</option>
              <option value="/1/0.523324515/-1/1.34147134">
                Thanesar, Haryana</option>
              <option value="/1/0.188304674/-1/1.381843474">Thanjavur, TN</option>
              <option value="/1/0.204891975/-1/1.32658642">
                Tharuvana, Kerala</option>
              <option value="/1/0.188130071/-1/1.390602734">
                Thiruvarur, TN</option>
              <option value="/1/0.488229277/-1/1.392217813">Tihar, UP</option>
              <option value="/1/0.487647266/-1/1.341796296">
                Tijara, Rajasthan</option>
              <option value="/1/0.431910053/-1/1.376367725">Tikamgarh, MP</option>
              <option value="/1/0.213583333/-1/1.380242945">
                Tindivanam, TN</option>
              <option value="/1/0.479988977/-1/1.664977072">
                Tinsukia, Assam</option>
              <option value="/1/0.142417989/-1/1.364126102">
                Tiruchchendur, TN</option>
              <option value="/1/0.188619929/-1/1.374102734">
                Tiruchchirapalli, TN</option>
              <option value="/1/0.198664462/-1/1.360100529">
                Tiruchengodu, TN</option>
              <option value="/1/0.220092152/-1/1.397824515">
                Tirukkalukkunram, TN</option>
              <option value="/1/0.208873898/-1/1.382920194">
                Tirukkoyilur, TN</option>
              <option value="/1/0.159805556/-1/1.361589506">
                Tirumangalam, TN</option>
              <option value="/1/0.204984127/-1/1.386">Tirunamanallur, TN</option>
              <option value="/1/0.140691358/-1/1.345627866">
                Tirunelveli, TN</option>
              <option value="/1/0.237969577/-1/1.386630511">Tirupati, AP</option>
              <option value="/1/0.218016314/-1/1.371939594">
                Tiruppattur, TN</option>
              <option value="/1/0.176485009/-1/1.372468254">
                Tiruppattur, TN</option>
              <option value="/1/0.193775573/-1/1.350502205">Tiruppur, TN</option>
              <option value="/1/0.159854056/-1/1.366366843">
                Tiruppuvanam, TN</option>
              <option value="/1/0.192892857/-1/1.32441358">
                Tirurangadi, Kerala</option>
              <option value="/1/0.230005732/-1/1.380238095">Tiruttani, AP</option>
              <option value="/1/0.183857143/-1/1.390597884">
                Tiruttaraippundi, TN</option>
              <option value="/1/0.159131393/-1/1.377992504">
                Tiruvadanai, TN</option>
              <option value="/1/0.229467372/-1/1.395220018">
                Tiruvallur, TN</option>
              <option value="/1/0.213510582/-1/1.380684303">
                Tiruvannamalai, TN</option>
              <option value="/1/0.220955467/-1/1.388847002">
                Tiruvettipuram, TN</option>
              <option value="/1/0.298731481/-1/1.397727513">Tiruvuru, AP</option>
              <option value="/1/0.13968254/-1/1.359673721">
                Tisaiyanvilai, TN</option>
              <option value="/1/0.199018519/-1/1.382003527">Tittagudi, TN</option>
              <option value="/1/0.518799383/-1/1.325228395">
                Tohana, Haryana</option>
              <option value="/1/0.158369929/-1/1.379636684">Tondi, TN</option>
              <option value="/1/0.456844356/-1/1.32328351">
                Tonk, Rajasthan</option>
              <option value="/1/0.181014991/-1/1.362530423">
                Tovarankurichchi, TN</option>
              <option value="/1/0.192529101/-1/1.394279101">
                Tranquebar, TN</option>
              <option value="/1/0.183469136/-1/1.330636243">
                Trichur, Kerala</option>
              <option value="/1/0.158981041/-1/1.340244268">Tripunittura
                , Kerala</option>
              <option value="/1/0.139745591/-1/1.328473104">
                Trivandram, Kerala</option>
              <option value="/1/0.244003086/-1/1.377808201">
                Tsundupalle, AP</option>
              <option value="/1/0.48072134/-1/1.432342593">Tulsipur, UP</option>
              <option value="/1/0.251608025/-1/1.310411376">
                Tuminkatti, Karnataka</option>
              <option value="/1/0.232891534/-1/1.346103175">
                Tumkur, Karnataka</option>
              <option value="/1/0.475066138/-1/1.365959436">Tundla, UP</option>
              <option value="/1/0.445558201/-1/1.575454145">
                Tura, Meghalaya</option>
              <option value="/1/0.194634039/-1/1.372361552">Turaiyur, TN</option>
              <option value="/1/0.142039683/-1/1.364436508">Tuticorin, TN</option>
              <option value="/1/0.484029101/-1/1.310144621">
                Udaipur, Rajasthan</option>
              <option value="/1/0.4290194/-1/1.286675044">
                Udaipur, Rajasthan</option>
              <option value="/1/0.195308201/-1/1.384477072">
                Udaiyarpalaiyam, TN</option>
              <option value="/1/0.184744709/-1/1.348707672">Udamalpet, TN</option>
              <option value="/1/0.259722222/-1/1.384564374">Udayagiri, AP</option>
              <option value="/1/0.57477425/-1/1.311813051">
                Udhampur, Jammu &amp; Kashmir</option>
              <option value="/1/0.232784832/-1/1.305085979">Udipi
                , Karnataka</option>
              <option value="/1/0.488913139/-1/1.382008377">Ujhani , UP</option>
              <option value="/1/0.404652557/-1/1.310697531">Ujjain, MP</option>
              <option value="/1/0.248203263/-1/1.305915344">
                Ulanahalli, Karnataka</option>
              <option value="/1/0.335529101/-1/1.277348325">
                Ulhasnagar, Maharashtra</option>
              <option value="/1/0.266095238/-1/1.396849647">Ullapalem, AP</option>
              <option value="/1/0.410768519/-1/1.411501764">Umaria, MP</option>
              <option value="/1/0.425590388/-1/1.410376543">Unchahra, MP</option>
              <option value="/1/0.463503527/-1/1.397446208">Unnao, UP</option>
              <option value="/1/0.379601852/-1/1.22707716">
                Upleta, Gujarat</option>
              <option value="/1/0.196646825/-1/1.370901675">
                Uppiliyapuram, TN</option>
              <option value="/1/0.260958995/-1/1.348984127">
                Uravakonda, AP</option>
              <option value="/1/0.297785714/-1/1.296656526">Urun
                Islampur , Maharashtra</option>
              <option value="/1/0.1595097/-1/1.358194444">Usilampatti
                , TN</option>
              <option value="/1/0.477030423/-1/1.438972663">Utraula, UP</option>
              <option value="/1/0.159567901/-1/1.350167549">
                Uttamapalaiyam, TN</option>
              <option value="/1/0.220237654/-1/1.39258642">
                Uttiramerur, TN</option>
              <option value="/1/0.175961199/-1/1.345943122">
                Vadippatti, TN</option>
              <option value="/1/0.389476631/-1/1.277876984">
                Vadodra, Gujarat</option>
              <option value="/1/0.158534832/-1/1.333837302">
                Vaikam, Kerala</option>
              <option value="/1/0.189929453/-1/1.328759259">
                Valancher, Kerala</option>
              <option value="/1/0.208054233/-1/1.389448413">Valvanur, TN</option>
              <option value="/1/0.187101852/-1/1.380446649">Vallam, TN</option>
              <option value="/1/0.359857143/-1/1.273308201">
                Valsad, Gujarat</option>
              <option value="/1/0.218302469/-1/1.389938272">Vandavasi, TN</option>
              <option value="/1/0.258213845/-1/1.375455908">Vanipenta, AP</option>
              <option value="/1/0.221406526/-1/1.372574956">
                Vaniyambadi, TN</option>
              <option value="/1/0.140861111/-1/1.339317901">Varkkallai
                , Kerala</option>
              <option value="/1/0.337939594/-1/1.271169312">
                Vasei, Maharashtra</option>
              <option value="/1/0.177353175/-1/1.357709436">
                Vattalkundu, TN</option>
              <option value="/1/0.181092593/-1/1.380839506">
                Vedaranniyam, TN</option>
              <option value="/1/0.183842593/-1/1.345933422">
                Vedasandur, TN</option>
              <option value="/1/0.191044974/-1/1.356918871">
                Vellakkovil, TN</option>
              <option value="/1/0.225441799/-1/1.381746473">Vellore, TN</option>
              <option value="/1/0.27442769/-1/1.371963845">Velugodu, AP</option>
              <option value="/1/0.250851411/-1/1.369931658">Vempalle, AP</option>
              <option value="/1/0.277051587/-1/1.285574074">
                Vengurla, Maharashtra</option>
              <option value="/1/0.24367328/-1/1.389511464">
                Venkatagiri, AP</option>
              <option value="/1/0.246064374/-1/1.366623898">Veparala, AP</option>
              <option value="/1/0.175796296/-1/1.33212037">
                Verapoli, Kerala</option>
              <option value="/1/0.27557716/-1/1.402204145">Vetapalem, AP</option>
              <option value="/1/0.211357143/-1/1.383671958">
                Vettavalam, TN</option>
              <option value="/1/0.410734568/-1/1.35851455">Vidisha, MP</option>
              <option value="/1/0.141113316/-1/1.358223545">Vajaya
                Narayanam, TN</option>
              <option value="/1/0.288289242/-1/1.407849647">
                Vijayawada, AP</option>
              <option value="/1/0.158515432/-1/1.362205467">
                Vilattikulam, TN</option>
              <option value="/1/0.20847134/-1/1.388061287">Villupuram, TN</option>
              <option value="/1/0.258951058/-1/1.389487213">Vinjamur, AP</option>
              <option value="/1/0.185132716/-1/1.362787478">
                Viralimalai, TN</option>
              <option value="/1/0.159267196/-1/1.351554674">Virapandi, TN</option>
              <option value="/1/0.212904321/-1/1.323458113">
                Virarajendrapet , Karnataka</option>
              <option value="/1/0.158602734/-1/1.361177249">Virudunager
                , TN</option>
              <option value="/1/0.309294974/-1/1.454361993">
                Vishakhapatnam , AP</option>
              <option value="/1/0.413838624/-1/1.266707231">Visanagar
                , Gujarat</option>
              <option value="/1/0.3162694/-1/1.456350529">Vizianagaram
                , AP</option>
              <option value="/1/0.201036155/-1/1.385088183">
                Vriddhachalam , TN</option>
              <option value="/1/0.481565256/-1/1.345618166">Vrindavan, UP</option>
              <option value="/1/0.22330291/-1/1.39372134">Walajabad, TN</option>
              <option value="/1/0.225635802/-1/1.385742945">Walajapet, TN</option>
              <option value="/1/0.394845679/-1/1.238644621">
                Wankaner, Gujarat</option>
              <option value="/1/0.314159612/-1/1.389618166">Warangal, AP</option>
              <option value="/1/0.362199735/-1/1.362821429">
                Wardha, Maharashtra</option>
              <option value="/1/0.43672619/-1/1.48501455">Waris Allganj,
                Bihar</option>
              <option value="/1/0.353149471/-1/1.379500882">
                Warora, Maharashtra</option>
              <option value="/1/0.350932981/-1/1.346830688">
                Washim, Maharashtra</option>
              <option value="/1/0.159456349/-1/1.355551146">Watrap, TN</option>
              <option value="/1/0.471743827/-1/1.347417549">
                Wer, Rajasthan</option>
              <option value="/1/0.292693122/-1/1.346884039">
                Yadgir, Karnataka</option>
              <option value="/1/0.262690476/-1/1.359634921">Yadiki, AP</option>
              <option value="/1/0.526132716/-1/1.349469136">
                Yamunanagar, Haryana</option>
              <option value="/1/0.356146825/-1/1.364354056">
                Yavatmal, Maharashtra</option>
              <option value="/1/0.307141534/-1/1.402640653">Yellandu, AP</option>
              <option value="/1/0.468353616/-1/1.420018519">Zaidpur, UP</option>
              <option value="/1/0.443763668/-1/1.458945326">Zamania, UP</option>
            </select>
          </div>
          <div className="col-lg-12 col-md-9 col-sm-9">
            <div style={{textAlign: 'center'}}><br /><br />
              <input name="button"  type="submit"  defaultValue="CHECK" className="btn btn-primary w-100 py-3" style={{textAlign: 'center', float: 'none'}} />
            </div>
          </div>
          <div className="col-lg-12 col-md-9 col-sm-9"><br /><br />
            <h4 className="result" id="result" style={{textAlign: 'center'}} />
          </div>
        </form>
        {distance && <p>Distance measured is: {distance}</p>}

      </div>
        </div>
        {/*===============spacing==============*/}
        <div className="pd_bottom_70" />
        {/*===============spacing==============*/}
      </section>

      <Footer />
    </>
  );
}
