import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
          Sea Freight
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/">
                  Home
                </Link>
              </li>

              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Sea Freight
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container-fluid py-6 px-5">
        <div className="row g-5">
          <div className="col-lg-4">
            <div className="mb-5">
              <h2 className="mb-4">Other Services</h2>
              <div className="d-flex flex-column justify-content-start bg-secondary p-4">
                <Link className="h5 mb-3" to="/Sea">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Sea Freight
                </Link>
                <Link className="h5 mb-3" to="/Air">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Air Freight
                </Link>
                <Link className="h5 mb-3" to="/LCL">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  LCL Consolidation
                </Link>
                <Link className="h5 mb-3" to="/Custom">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Custom Clearance
                </Link>
                <Link className="h5 mb-3" to="/Local">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Local Transportation
                </Link>
                <Link className="h5 mb-3" to="/Special">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Special Equipments
                </Link>
                <Link className="h5 mb-3" to="/Hazardous">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Hazardous Materials
                </Link>
                <Link className="h5 mb-3" to="/Warehousing">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Warehousing and Distribution
                </Link>
                <Link className="h5 mb-3" to="/Bonded">
                  <i className="bi bi-arrow-right text-primary me-2" />
                  Bonded & Warehouse Storage
                </Link>
              </div>
            </div>

            {/* Plain Text End */}
          </div>
          <div className="col-lg-8">
            {/* Blog Detail Start */}
            <div className="mb-5">
              <img
                className="img-fluid w-100 mb-5"
                src="img/Sea Freight.jpg"
                alt=""
              />
              <h1 className="mb-4">Sea Freight</h1>
              <p>
                Whether you are moving places or trading with international
                companies, you would require the services of a freight forwarder
                in Singapore. Freight forwarding helps you in importing and
                exporting goods worldwide. There are various modes of
                transportation, and sea freight is one of them.
              </p>

              <p>
                What Is Sea Freight?
                <br />
                People need to ship their cargo and goods to any other country
                for various reasons. Sea freight offers a mode of transportation
                and solution to transfer large and massive products through
                cargo ships. Containers are used to pack the shipment products,
                and then they are loaded over the vessel. These vessels move
                from seaport to another country’s seaport.
              </p>

              <p>
                A ship can carry hundreds of containers at a time, making it an
                efficient method to shift heavy items over long distances. It
                also offers a large capacity. However, sea freight forwarding
                takes time, and you cannot expect fast delivery. If the shipping
                schedule gets delayed due to any reason, it will set you back
                more than a week.
              </p>

              <p>
                You can transport sea freight as:
                <br />
                • FCL or Full container load
                <br />
                • LCL or Less than container load
                <br />
                • Dry shipping of bulk items
                <br />• RORO or Roll-on roll-off
              </p>

              <p>
                How Does Sea Freight Work?
                <br />
                Whenever you have to import or export any items to or from
                Singapore, you can contact sea freight companies. At the first
                step, the goods will be examined, and proper documentation will
                be done for their forwarding. After relevant documents are
                submitted to customs officials, the goods will be packed and
                loaded in the containers. These containers are further loaded in
                the ships and leave to the port of their destination.
              </p>

              <p>
                Moreover, the ship requires clearance from the country’s
                government, where it will unload. After approval, it can offload
                the containers once it reaches the destination. Custom officials
                check and examine containers and release them. These containers
                will be forwarded to warehouses and transported to the owner.
              </p>

              <p>
                Why Should You Use Sea Freight?
                <br />
                Sea freight is one of those methods with which you can ship all
                heavy and large goods without any hassle. It can take massive
                products across the globe and cover long distances with ease.
                Moreover, it offers an affordable and cost-effective way to
                forward large shipments internationally. It is safe and secure
                and causes less damage to the goods. Sea freight is also an
                eco-friendly mode of transportation as compared to air freight.
                It ships your cargo as the most carbon-efficient solution among
                others.
              </p>

              <p>
                What Do Freight Forwarding Companies Do?
                <br />
                Sea freight companies are the ones who help you in international
                trading and forward your shipment to various countries. The
                freight forwarding companies facilitate you by finding the best
                and quick routes to deliver your goods as soon as possible.
                Further, they take care of the transport and identify carriers
                that can carry the consignment of goods.
              </p>

              <p>
                Sea freight companies help you out by saving your time and
                getting the most affordable container shipping rates. These
                companies build relationships with various carriers to ship the
                goods, and they can use them to bid economical prices and ensure
                a safe and timely shipment. Moreover, they provide safe and
                timely delivery of your products and supervise overseas
                transportation.
              </p>

              <p>
                Choose Walkers Logistics for Sea freight forwarding
                <br />
                Walkers Logistics Pte Ltd is one of the leading and experienced
                freight forwarders in Singapore. We support our clients with
                reliable, secure, and timely delivery of their goods. With years
                of experience, we have advanced our methods and modernised the
                way shipping works. Our network has gotten stronger, and we can
                support our customers through our established connections. Due
                to this, you can entrust us to be one of the best freight
                forwarding companies in Singapore. We ship your products with
                responsibility and offer the most cost-effective container
                shipping rates.
              </p>
            </div>
          </div>
          {/* Sidebar Start */}

          {/* Sidebar End */}
        </div>
      </div>

      <Footer />
    </>
  );
}
